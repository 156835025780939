@import './common';

.cs-billers {
margin: 70px 0px;
    .head {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        /* or 48px */
        color: $orangeColor;
        margin-bottom: 55px;
    }

    .cs-billers-html {
        border-radius: 32px;
        background: var(--form-gradient, linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100%));
        box-shadow: 0px 0px 10px 0px rgba(33, 51, 220, 0.35);
        backdrop-filter: blur(4px);
        padding: 1px 55px;
    }
}

@media (max-width: 640px) {
    .cs-billers {
        width: 100%;
        margin: 80px 0 30px;

        .biller-wrapper {
            width: 100%;
        }

        img {
            max-width: 100%;
        }
        
        .head {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            color: $whiteColor;
            line-height: 150%;
            margin-bottom: 30px;
        }

        .cs-billers-html {
            border-radius: 8px;
            padding: 32px 24px;

            .dynamic-html {
                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media (min-width:1201px) and (max-width: 1500px) {
    .cs-billers {
        .head {
            font-size: $subContentLg;
        }
    
        .cs-billers-html {
            border-radius: 32px;
            padding: 1px 55px;
        }
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    .cs-billers {
            .head {
                font-size: $mainHeaderLgs;
            }
            
            .cs-billers-html {
                border-radius: 28px;
                padding: 1px 55px;
            }
        }
}