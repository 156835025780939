@import '../css/common';

.cs-actions {
    margin: 70px 0px;

    .action-container {
        text-align: center;
    }

    .action-container button+button {
        margin-left: 40px;
    }

    .cs-btn-action {
        border: 2px solid $orangeColor;
        color: $whiteColor;
        padding: 12px 36px;
        border-radius: 8px;
        font-size: 24px;
    }
}
@media (max-width: 640px) {
    .cs-actions {
        margin: 50px 0 10px;

        .cs-btn-action {
            font-size: 16px;
            margin-left: 6px !important;
            margin-bottom: 20px;
        }
    }
}