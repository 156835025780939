@import './common.scss';

.career-jobs {
    margin-top: 15%;
    margin-bottom: 15%;
}

.lbl-career-jobs {
    font-weight: 700;
    font-size: 70px;
    line-height: 90px;
    height: 135px;
    color: #FFFFFF;
}

.lbl-category-filter {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 70px;
}

.lbl-category-active {
    color: #FF7900 !important;
}

.category-main {
    height: 115px;
}

#location-menu {
    .MuiPaper-root {
        // padding: 0px 20px;
        background: linear-gradient(to right, rgb(22, 46, 84), rgb(16, 58, 112), rgb(20, 34, 63));
    }

    .location-menu-item {
        font-size: 20px !important;
        color: #FFFFFF !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-weight: 500 !important;
        text-decoration: none !important;
    }
}

.no-data-found {
    font-size: 40px;
    text-align: center;
    color: #FFFFFF;
}

.btn-view-job-position {
    border: 2px solid #FF7900 !important;
    border-radius: 8px !important;
    //font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 26px !important;
    /* identical to box height, or 108% */

    text-align: center;
    text-transform: uppercase !important;

    color: #FFFFFF !important;
    width: 261px;
height: 58px;
}

.btn-view-job-position:hover {
    border-color: #D9D9D9 !important;
    background-color: #FF7900 !important;

}

.view-job-position {
    text-align: center;
    margin-top: 6%;
}

@media (max-width: 640px) {
    .lbl-career-jobs {
        height: auto;
        font-size: $mainHeaderMobileMd;
    }
}

@media (max-width: 900px) {
    .category-main {
        &.desktop-menu {
            display: none; 
        }
        &.mobile-menu {
            display: block;
            height: auto;
        }
        .all-jobs-tags-stack {
            margin-bottom: 32px;
        }
    }
    .career-jobs {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .lbl-career-jobs {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        height: auto;
        margin-bottom: 24px;
    }

}

@media (min-width:641px) and (max-width:899px) {
    .lbl-category-filter {
        font-size: 16px;
        margin-right: 40px;
    }
    .btn-view-job-position {
        width: 190px;
        height: 40px;
        font-size: 16px !important;
    }
}
@media (min-width:900px) and (max-width: 1200px) {
    .lbl-career-jobs {
        font-size: 40px;
    }
    .lbl-category-filter {
        font-size: 24px;
        margin-right: 40px;
    }

}


@media (min-width:1200px) and (max-width: 1500px) {
    .lbl-career-jobs {
        font-size: 45px;
    }

}