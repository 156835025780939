@import './common.scss';
.tac-hcwh-heading {
    color: var(--white, #FFF);
    font-size: $mainHeaderLg;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    padding-bottom: 24px;
}

.tac-hcwh-content {
    color: #FFF;
    font-size: $subContentLg;
    font-style: normal;
    line-height: 150%;
    padding-bottom: 48px;
    // width: 65%;
}

.tac-hcwh-btn {
    background:#FF7900 !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    height: 66px !important;
    min-width: 300px !important;
    max-width: 360px !important;
    padding: 16px 46px !important;
    text-transform: uppercase !important;
}
.tac-hcwh-btn:hover {
    box-shadow: 0 0 2px 2px #BE9663 !important;
}

@media (min-width:1200px) and (max-width: 1500px) {
    .tac-hcwh-heading {
        font-size: $mainHeaderLgs;
        padding-bottom: 24px - (0.375*24);
    }

    .tac-hcwh-content {
        // width: 70%;
        font-size: $subHeaderMd;
        padding-bottom: 48px - (0.375*48);
    }

    .tac-hcwh-btn {
        border-radius: 8px - (0.375*8) !important;
        font-size: 24px - (0.375*24) !important;
        height: 66px - (0.375*66) !important;
        min-width: 300px - (0.375*300) !important;
        max-width: 360px - (0.375*300) !important;
        padding: (16px - (0.375*16)) (46px - (0.375*46)) !important;
    }
}
@media (min-width:900px) and (max-width: 1200px) {
    .tac-hcwh-heading {
        font-size: $mainHeaderMd;
        padding-bottom: 24px - (0.53125*24);
    }

    .tac-hcwh-content {
        font-size: $subContentMd;
        padding-bottom: 48px - (0.53125*48);
    }

    .tac-hcwh-btn {
        border-radius: 8px - (0.53125*8) !important;
        font-size: 24px - (0.53125*24) !important;
        height: 66px - (0.53125*66) !important;
        min-width: 300px - (0.53125*300) !important;
        max-width: 360px - (0.53125*300) !important;
        padding: (16px - (0.53125*16)) (46px - (0.53125*46)) !important;
    }
}
@media (min-width:641px) and (max-width: 899px) {
    .tac-hcwh-heading {
        font-size: $mainHeaderSm;
        padding-bottom: 24px - (0.66*24);
    }

    .tac-hcwh-content {
        font-size: $subContentSm;
        padding-bottom: 48px - (0.66*48);
    }

    .tac-hcwh-btn {
        border-radius: 8px - (0.66*8) !important;
        font-size: 24px - (0.66*24) !important;
        height: 66px - (0.66*66) !important;
        min-width: 300px - (0.66*300) !important;
        max-width: 360px - (0.66*300) !important;
        padding: (16px - (0.66*16)) (46px - (0.66*46)) !important;
    }
}
@media(max-width: 640px) {
    .tac-hcwh {
        padding-top: 30px;
        padding-bottom: 80px;

        .tac-hcwh-heading {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            padding-bottom: 30px;
        }
        
        .tac-hcwh-content {
            // width: 90%;
            font-size: $subContentMobile;
            padding-bottom: 30px;
        }
        
        .tac-hcwh-btn {
            font-weight: 600 !important;
            border-radius: 8px !important;
            font-size: 16px !important;
            height: 44px !important;
            width: auto !important;
            padding: 0 20px !important;
        }
    }
}

