#header {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .open-nav-menu {
        svg {
            opacity: 0.75;
            &:hover {
                opacity: 0.95;
            }
        }
    }
}

.header-container {
    max-width: 85% !important;
    padding: 0px !important;
}

.header-drpdwn-menu {
    left: 8% !important;
}

.header-title {
    margin-left: 10px !important;
    margin-right: 35px !important;
    background-color: transparent !important;
    font-weight: normal !important;
}

.header-title:hover {
    text-shadow: 0 0 2px #fff, 0 0 0.65px #fff;
}

.header-selected {
    color: #FF7900 !important;
    text-shadow: 0 0 3px #FF7900, 0 0 1px #FF7900 !important;
}

.go-up {
    position: fixed;
    right: 60px;
    bottom: 20px;
    cursor: pointer;
    z-index: 1;
    width: 60px;
    height: 80px;
}

.hide-scroll-to-top {
    display: none;
}

header {
    z-index: 1;
}

.btn-lang {
    font-size: 24px !important;
}

.btn-talk {
    font-size: 18px !important;
    border-width: 2px !important;
}

.btn-talk:hover {
    border-color: #D9D9D9 !important;
    background-color: #FF7900 !important;

}


.d-none {
    display: none !important;
}

.appbar-hide {
    display: none !important;
    position: relative !important;
    transition: 0.2s linear !important;
}

.small {
    background: linear-gradient(103.34deg, #18162B 32.46%, #2F416F 145.17%);
    mix-blend-mode: inherit;
    padding: 5px 0px !important;
    transition: 0.2s linear !important;
}

.navlist-main {
    display: block;
    margin: 0 auto;
}

.headerLogo img {
    height: 90px;
}

.drp-menu-text {
    font-size: 20px;
    color: #FFFFFF !important;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    text-decoration: none !important;
}

.mobile-menu-wrapper {
    .lang-mobile-menu {
        display: none;
    }
    .lets-talk-mobile-menu {
        display: none;
    }
    &.css-1bf9kbz-MuiButtonBase-root-MuiMenuItem-root.Mui-focusVisible {
        background-color: transparent !important;
    }
    &.css-1bf9kbz-MuiButtonBase-root-MuiMenuItem-root:hover {
        background-color: transparent !important;
    }
    span.MuiTouchRipple-root {
        display: none !important;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .navlist-main {
        width: 90% !important;
    }

    .headerLogo img, .headerLogoSmall img {
        width: 50px !important;
    }

    .header-title {
        margin-left: 0px !important;
        margin-right: 0px !important;
        font-size: 12px !important;
        min-width: auto !important;

        img {
            height: 15px;
            padding-left: 2px;
        }
    }

    .btn-lang {
        font-size: 12px !important;
        padding: 0px !important;
        min-width: 50px !important;
    }

    .btn-talk {
        font-size: 8px !important;
    }

    #header {
        padding: 0px;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    #header {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .headerLogo img {
        height: 50px !important;
    }

    .header-title {
        margin-left: 5px !important;
        margin-right: 5px !important;
        font-size: 14px !important;
    }

    .btn-lang {
        font-size: 18px !important;
    }

    .btn-talk {
        font-size: 14px !important;
    }

    .drp-menu-text {
        font-size: 12px;
    }
}

@media (min-width:1201px) and (max-width: 1500px) {

    .header-title {
        font-size: 20px !important;
        margin-right: 5px !important;
    }

    .drp-menu-text {
        font-size: 14px;
    }
}

@media (min-width:1501px) and (max-width: 1600px) {

    .header-title {
        font-size: 20px !important;
        margin-right: 5px !important;
    }

    .drp-menu-text {
        font-size: 14px;
    }
}


@media (min-width:1201px) and (max-width: 1366px) {
    .header-title {
        margin-left: 5px !important;
        margin-right: 5px !important;
        font-size: 16px !important;
    }
    .btn-talk {
        font-size: 16px !important;
    }
    .btn-lang {
        font-size: 22px !important;
    }
}

@media (min-width:1500px) and (max-width: 1670px) {
    .header-container {
        max-width: 98% !important;
    }
}

@media (max-width: 1100px) {
    .header-container {
        max-width: 98% !important;
    }
}

@media (max-width: 1024px) {
    .header-link-menu .css-1ddx9nd-MuiButtonBase-root-MuiButton-root {
        min-width: 54px !important;
    }
    .navlist-main .css-hbrltn-MuiButtonBase-root-MuiButton-root {
        padding: 6px 6px !important;
    }
} 
 
@media (max-width: 900px) {
    .header-container {
        max-width: 100% !important;
        padding: 0 16px !important;
        .MuiToolbar-root {
            justify-content: space-between;
        }
    }
    .header-link-menu {
        // .lets-talk-menu {
        //     display: none;
        // }
        display: inline-flex;
        align-items: center;
        .lets-talk-menu {
            background: #FF7900;
            margin-right: 15px;
            font-size: 16px!important;
            font-style: normal;
            font-weight: 700;
            line-height: 14px !important;
            padding: 6px 18px 4px 18px !important;
            text-transform: uppercase;
            height: 40px;
        }
        .lang-menu {
            display: none;
        }
    }
    .hide-lets-talk {
        .lets-talk-menu {
            display: none;
        }
    }
    .mobile-menu-wrapper {
        display: flex;
        justify-content: center; 
        .lang-mobile-menu {
            display: flex;
            font-size: 20px !important;
            font-style: normal;
            font-weight: 450;
            line-height: normal;
            &.locale-active {
                font-weight: 500;
            }
            &:last-child {
                margin-left: 3px;
            }
        }
        .lets-talk-mobile-menu { 
            display: flex;
            margin-top: 50px;
            padding: 14px 28px !important;
            background: #FF7900;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
        }
    }
    #menu-appbar {
        .mobile-header {
            display: flex;
            justify-content: space-between !important;
            align-items: center;
            padding-top: 24px;
            padding-left: 16px !important;
            padding-right: 16px !important;
            margin-bottom: 72px;
            .mobile-logo {
                display: inline-block;
                width: 57px;
                height: 40px;
                cursor: pointer;
            }
            .close-icon {
                display: inline-block;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                img {
                    display: inline-block;
                }
            }
        }

        .toggle-services-list {
            position: relative;
            .back-button {
                position: absolute;
                top: 6px;
                left: 24px;
                right: unset;
                display: inline-flex;
                vertical-align: middle;
                height: 24px;
                width: 24px;
                z-index: 999;
                img {
                    display: inline-block;
                }
            }
        }

        .MuiPaper-elevation {
            position: fixed;
            top: 0 !important;
            left: 0 !important;
            right: 0;
            bottom: 0;
            background: var(--Bg, linear-gradient(103deg, #18162B 32.46%, #2F416F 145.17%));
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            z-index: 999;
            ul {
                 li {
                     justify-content: center;
                     margin-bottom: 32px;
                     .custom-menu-mob-title {
                         color: #FFF;
                         font-size: 20px !important;
                         font-style: normal;
                         font-weight: 450;
                         line-height: normal;
                         &.dropdown-title {
                            margin-top: 0;
                            margin-bottom: 0;
                            img {
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: 2px;
                                width: 24px;
                                height: 24px;
                                margin-bottom: 2px;
                                transform: rotate(-90deg);
                            }
                         }
                     }
                 }
            }
        }
    }
    @media (max-width: 640px) {
      .go-up { 
          display: none;
      }
  
      .header-link-menu {
        // .lets-talk-menu {
        //     display: none;
        // }
        display: inline-flex;
        align-items: center;
        .lets-talk-menu {
            background: #FF7900;
            margin-right: 1px;
            font-size: 12px!important;
            font-style: normal;
            font-weight: 700;
            line-height: 14px !important;
            padding: 6px 10px 4px 10px !important;
            text-transform: uppercase;
            height: 40px;
        }
        .lang-menu {
            display: none;
        }
    }
  
      .btn-talk {
          font-size: 8px !important;
          border-width: 1px !important;
          padding: 3px 8px !important;
      }
  
      .btn-lang {
          padding: 0px !important;
          min-width: 45px !important;
          font-size: 12px !important;
      }
      .headerLogo img {
          height: 35px;
      }
  
      .headerLogoSmall {
          height: 30px;
          width: 74px;
          display: inline-flex;
          align-items: center;
          img {
              width: 100%;
          }
      }
  
      #header {
          padding-top: 15px;
          padding-bottom: 0px;
      }
  
      .header-link-menu {
          right: 0;
          position: absolute;
          display: flex;
      }
  
      .custom-menu-mob-cont {
          padding: 0 10px !important;
          min-height: 35px !important;
      }
  
      .custom-menu-mob-title {
          font-size: 12px !important;
      }
      .go-up {
          right: 20px;
          height: 40px;
      }
  
  }
}
