@import './common.scss';

.career-employees-say {
    .employee-say-root-container {
        display: flex;
        align-items: center;
    }
    .employee-say-lcontent {
        margin-top: 70px;

        .client-quotes {
            margin-bottom: 24px;
        }
    }
    .lbl-employee-say {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 90px;
        color: #FFFFFF;
    }

    .lbl-employee-say-desc {
        font-size: $subHeaderLgs;
        line-height: 150%;
        color: #FFFFFF;
        font-style: normal
    }

    .client-container {
        position: relative;
        padding: 30px;
        padding-left: 80px;
        padding-top: 10px;
    }

    .client-profile-img {
        width: 350px;
        z-index: 1;
        position: relative;
        display: block;
    }

    .client-profile-effect {
        height: 350px;
        margin-bottom: 27px;
        margin-left: -3px;
        position: absolute;
        bottom: 0%;
        width: 356px;
        background: linear-gradient(90deg, #42466C 0%, rgba(49, 56, 113, 0) 100.83%);
        border: 3px solid #2F416F;
        filter: drop-shadow(0px 0px 15px rgba(0, 59, 232, 0.75));
        backdrop-filter: blur(4px);
        border-radius: 32px;
    }

    .employee-arrow {
        margin-top: 100px;
        .left-arrow {
            position: absolute;
            width: 40px;
            height: 40px;
            border: 6px solid #FF7900;
            border-bottom: 0px;
            border-left: 0px;
            border-radius: 4px;
            transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        }

        .right-arrow {
            margin-left: 150px;
            width: 40px;
            height: 40px;
            border: 6px solid #FF7900;
            border-top: 0px;
            border-right: 0px;
            border-radius: 4px;
            transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        }
    }

    .employee-profile-main-view {
        .client-container {
            position: relative;
            padding: 30px;
            padding-left: 80px;
            padding-top: 10px;
        }
        .client-container-wrapper {
            width: 250px;
        }
        .client-top-container {
            position: relative;
            padding: 0 0 14px 0;
            padding-top: 0px;
            width: 122px;
            box-sizing: border-box;
            margin: auto;
        }
        .client-bottom-container {
            position: relative;
            padding: 0 0 14px 0;
            padding-top: 0;
            width: 122px;
            box-sizing: border-box;
            margin: 22px auto 0;
            // .client-profile-short-img {
            //     width: 150px;
            //     height: auto;
            // }
            // .client-profile-short-effect {
            //     height: 140px;
            //     width: 150px; 
            // }
        }
        .client-profile-short-img {
            width: 100%;
            height: auto;
            z-index: 1;
            position: relative;
            display: block;
            opacity: 0.5;
        }
        .client-profile-img {
            width: 350px;
            z-index: 1;
            position: relative;
            display: block;
        }
        .client-profile-pos {
            font-weight: 600;
            font-size: $subHeaderSm;
            line-height: 90.24%;
            text-align: center;
            color: #FFFFFF;
            opacity: 0.6;
        }

        .client-profile-name {
            font-weight: 700;
            font-size: $sub2HeaderLg;
            line-height: 150%;
            text-align: center;
            color: #FF7900;
        }

        .client-profile-cur-pos {
            font-weight: 600;
            font-size: 24px;
            line-height: 90.24%;
            text-align: center;
            color: #FFFFFF;
        }

        .client-profile-effect {
            height: 350px;
            margin-bottom: 27px;
            margin-left: -3px;
            position: absolute;
            bottom: 0%;
            width: 356px;
            background: linear-gradient(90deg, #42466C 0%, rgba(49, 56, 113, 0) 100.83%);
            border: 3px solid #2F416F;
            filter: drop-shadow(0px 0px 15px rgba(0, 59, 232, 0.75));
            backdrop-filter: blur(4px);
            border-radius: 32px;
        }

        .client-profile-short-effect {
            height: 120px;
            margin-bottom: 27px;
            margin-left: -3px;
            position: absolute;
            bottom: 0%;
            width: 115px;
            background: linear-gradient(90deg, #42466C 0%, rgba(49, 56, 113, 0) 100.83%);
            border: 3px solid #2F416F;
            filter: drop-shadow(0px 0px 10px rgba(33, 51, 220, 0.35));
            backdrop-filter: blur(4px);
            border-radius: 14px;
            filter: blur(1.7238272428512573px);
        }
    }

    .disable-action {
        cursor: none;
        pointer-events: none;
        border-color: #6B6E80 !important;
    }

    .emp-content {
        padding-top: 70px;
    }
}

@media (max-width: 900px) {
    .career-employees-say {
        .lbl-employee-say {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin-bottom: 24px;
        }
    }    
    .no-data-found {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: block;
        width: 100%;
    }
}
@media (max-width: 640px) {
    .career-employees-say {
        .employee-say-lcontent {
            margin-top: 30px;
            & > div {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .employee-arrow {
                    order: 0;
                    width: 100%;
                }
                .client-quotes {
                    order: 1;
                }
                .lbl-employee-say-desc {
                    order: 2;
                }
            }

            .client-quotes {
                margin-bottom: 0;
            }
        }
        .lbl-employee-say-desc {
            font-size: 16px;
        }

        .employee-arrow {
            margin-top: 40px;
            .left-arrow, .right-arrow {
                width: 20px;
                height: 20px;
                border-width: 4px;
            }
            .left-arrow {
                left: 5%;
            }
            .right-arrow {
                margin-left: 90%;
            }
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .career-employees-say {
        .emp-content {
            padding-top: 20px;
        }

        .client-quotes {
            height: 45px;
        }

        .employee-say-lcontent {
            margin-top: 140px;
        }
        .lbl-employee-say-desc {
            font-size: 24px;
        }

        .employee-arrow {
            margin-top: 100px;

            .left-arrow {
                width: 30px;
                height: 30px;
            }

            .right-arrow {
                width: 30px;
                height: 30px;
            }
        }

        .employee-profile-main-view {

            .client-profile-name {
                font-size: 30px;
                line-height: 150%;
            }

            .client-profile-pos {
                font-size: 18px;
            }

            .client-profile-cur-pos {
                font-size: 18px;
            }

            .client-profile-img {
                width: 200px;
            }

            .client-profile-effect {
                height: 200px;
                width: 200px;
            }

            .client-profile-short-img {
                width: 90px;
                height: 135px;
            }

            .client-profile-short-effect {
                height: 95px;
                width: 90px;
            }

            .client-bottom-container {
                padding-top: 20px;
                width: auto;
                .client-profile-short-img {
                    width: 125px;
                    height: auto;
                }
                .client-profile-short-effect {
                    height: 115px;
                    width: 125px;
                }
            }
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .career-employees-say {
        .emp-content {
            padding-top: 35px;
        }

        .client-quotes {
            height: 60px;
        }

        .employee-say-lcontent {
            margin-top: 110px;
        }

        .lbl-employee-say {
            font-size: 40px;
        }

        .lbl-employee-say-desc {
            font-size: 28px;
        }

        .employee-arrow {
            margin-top: 100px;

            .left-arrow {
                width: 30px;
                height: 30px;
            }

            .right-arrow {
                width: 30px;
                height: 30px;
            }
        }

        .employee-profile-main-view {

            .client-profile-name {
                font-size: 30px;
                line-height: 150%;
            }

            .client-profile-pos {
                font-size: 18px;
            }

            .client-profile-cur-pos {
                font-size: 18px;
            }

            .client-profile-img {
                width: 200px;
            }

            .client-profile-effect {
                height: 200px;
                width: 200px;
            }

            .client-profile-short-img {
                width: 90px;
                height: 110px;
            }

            .client-profile-short-effect {
                height: 80px;
                width: 90px;
            }

            .client-bottom-container {
                padding-top: 20px;
                width: auto;

                .client-profile-short-img {
                    width: 125px;
                    height: auto;
                }

                .client-profile-short-effect {
                    height: 115px;
                    width: 125px;
                }
            }
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .career-employees-say {

        .lbl-employee-say {
            font-size: $mainHeaderLgs;
        }

        .lbl-employee-say-desc {
            font-size: $subContentLgss;
        }

        .employee-profile-main-view {
            .client-profile-img {
                width: 250px;
            }

            .client-profile-effect {
                height: 250px;
                width: 250px;
            }
            .client-profile-name {
                font-size: 26px;
            }
            .client-profile-cur-pos {
                font-size: 18px;
            }
        }
    }

    .employee-profile-main-view {

        .client-container {
            padding-left: 15px;
        }

        .client-bottom-container {
            padding-top: 18px;
        }

        .client-profile-name {
            font-size: $subContentLgs;
            line-height: 150%;
        }

        .client-profile-pos {
            font-size: $subContentMd ;
        }

        .client-profile-cur-pos {
            font-size: 22px;
        }

    }

}

@media (min-width:1501px) and (max-width: 1800px) {
    .career-employees-say {

        .lbl-employee-say-desc {
            font-size: $subContentLgss;
        }

        .employee-profile-main-view {
            .client-profile-img {
                width: 300px;
            }

            .client-profile-effect {
                height: 300px;
                width: 300px;
            }
            .client-profile-name {
                font-size: 30px;
            }
            .client-profile-cur-pos {
                font-size: 20px;
            }
        }
    }

    .employee-profile-main-view {

        .client-container {
            padding-left: 28px;
        }

        .client-bottom-container {
            padding-top: 36px !important;
        }

        .client-profile-img {
            width: 300px;
        }

        .client-profile-effect {
            height: 300px;
            width: 300px;
        }
    }
}