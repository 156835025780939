#all-works {
    margin-top: 64px;
}
.all-work-tags-stack{
    margin: 0 auto;
    width: 85%;
    margin-bottom: 76px;
    row-gap: 10px !important;
    column-gap: 10px !important;
    &.mobile-menu {
        display: none;
    }
}
.all-jobs-tags-stack {
    &.mobile-menu {
        display: none;
    }
    #custom-job-select {
        margin-bottom: 16px;
    }
}

.all-work-selected-button {
    border: 2px solid transparent !important;
    border-radius: 8px !important;
    background: #FF7900 !important;
    color: #FFFFFF !important;
    font-size: 22px !important;
    padding: 16px 36px !important;
}

.custom-select {
    position: relative;
    width: 100%;
    display: block;
    border: 1px solid transparent !important;
    border-radius: 8px;
    background: #FF7900;
    text-align: center;
    box-sizing: border-box;
    summary {
        padding: 13px 36px;
        cursor: pointer;
        border-radius: 8px;
        color: #FFFFFF;
        box-sizing: border-box;
        
    }
    
    summary::-webkit-details-marker {
        display: none;
    }
    
    summary:before {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
    }
    
    summary:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-left: 8px;
        width: 6px;
        height: 6px;
        border-bottom: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;
        border-top: 1px solid transparent;
        transform: rotate(45deg) translate(50%, 0%);
        transform-origin: center center;
        transition: transform ease-in-out 100ms
    }
    &.open {
        summary:after {
            content: '';
            display: inline-block;
            margin-top: 4px;
            width: 6px;
            height: 6px;
            border-bottom: 1px solid transparent;
            border-left: 1px solid transparent;
            border-top: 1px solid #FFFFFF;
            border-right: 1px solid #FFFFFF;
        }
    }
    
    summary:focus {
        outline: none;
    }
    
    summary:after {
        transform: rotate(-45deg) translate(0%, 0%);
    }
    
    ul.work-list {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        border-radius: 0 0 8px 8px;
        z-index: 99;
        display: none;
        background: linear-gradient(to right, rgba(24, 22, 43, 1) 50%, rgb(28, 43, 79));
        &.open {
            display: block;
        }
        li {
            margin: 8px 0;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
                margin: 8px 0 0 0;
            } 
        }
    }
    

    
    /* FAKE SELECT */
    summary.radios {
        counter-reset: radios;
    }
    
    summary.radios:before {
        content: var(--selection);
    }
    
    input[type=radio] {
        counter-increment: radios;
        appearance: none;
        display: none;
        margin: 0;
        background: transparent;
    }
    
    input[type=radio]:checked {
        display: inline;
        --display: block;
    }
    
    input[type=radio]:after {
        content: attr(title);
        display: inline;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }

    .locations-default {
        display: inline;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        font-family: Arial;
    }
    
    ul.list {
        counter-reset: labels;
    }
    
    label {
        width: 100%;
        display: flex;
        cursor: pointer;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 16px 36px;
        box-sizing: border-box;
        color: #FFFFFF;
        border: 1px solid #313759; 
        background: #313759;
        border-radius: 8px;
        &:hover {
            background: #FF7900;
        }
    }
    
}



@media (min-width:1501px) and (max-width: 1600px) {
    .all-work-tags-stack{
        column-gap: 30px !important;
        row-gap: 30px !important;
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    #all-works {
        margin-top: 64px - (0.375*64);
    }
    .all-work-tags-stack{
        margin-bottom: 65px;
        column-gap: 15px !important;
    }
    
    .all-work-selected-button {
        font-size: 19px !important;
        padding: (16px - (0.375*16)) (36px - (0.375*36)) !important;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    #all-works {
        margin-top: 64px - (0.375*64);
    }
    .all-work-tags-stack{
        margin-bottom: 65px;
        column-gap: 15px !important;
    }
    
    .all-work-selected-button {
        font-size: 16px !important;
        padding: (16px - (0.375*16)) (36px - (0.375*36)) !important;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    #all-works {
        margin-top: 64px - (0.53125*64);
    }
    .all-work-tags-stack{
        margin-bottom: 55px
    }
    
    .all-work-selected-button {
        font-size: 12px !important;
        padding: (16px - (0.53125*36)) (16px - (0.53125*36)) !important;
    }
}

@media (min-width:641px) and (max-width: 899px) {
    #all-works {
        margin-top: 64px - (0.66*64);
    }
    .all-work-tags-stack{
        margin-bottom: 45px
    }
    
    .all-work-selected-button {
        font-size: 10px !important;
        padding: 5px !important;
        border: 1px solid transparent !important;
    }
}

@media (max-width:900px) {

    #all-works {
        margin-top: 64px;
        padding-left: 16px;
        padding-right: 16px;
        .our-work-button {
            background: #313759 !important;
            border: 1px solid #313759 !important;
        }
        .all-work-selected-button {
            background: #FF7900 !important;
            background: #FF7900 !important;
        }
        .all-work-selected-button, .our-work-button {
            color: #FFFFFF !important;
            font-size: 14px !important;
            line-height: 14px !important;
            padding: 15px 36px !important;
            &:hover {
                border: 1px solid #FF7900 !important;
                background: #FF7900 !important;
            }
        }
    }
    #career-jobs, #all-works {
        .all-jobs-tags-stack,
        .all-work-tags-stack {
            width: 100%;
            flex-flow: column;
            row-gap: 8px !important;
            column-gap: unset !important;
            &.desktop-menu {
                display: none;
            }
            &.mobile-menu {
                display: block;
            }
        }
    }

    
}

@media (max-width:640px) {
    #all-works {
        margin-top: 64px - (0.66*64);
    }
    .all-work-tags-stack{
        margin-bottom: 35px;
    }
    .all-work-selected-button {
        font-size: 8px !important;
        padding: 4px !important;
        border: 1px solid transparent !important;
        border-radius: 4px !important;
    }
}