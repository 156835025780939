.work-bg1 {
    background-image: url('../assets/About/about-banner-bg1.png');
    position: relative;
}

.work-bg2 {
    // background-image: url('../assets/About/mixkit-3d-animation-of-lines-and-luminous-points-rotating-31510-medium\ 2.png');
    // mix-blend-mode: hard-light;
}


.root-work {
    #tsparticles {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .top-bg {
        width: 100%;
        top: 0;
        padding-top: 185px;
        padding-bottom: 0%;
        position: relative;
        /* background: linear-gradient(90deg, rgba(22, 26, 66, 0) 0%, rgba(22, 26, 66, 0.8) 11.46%) */
    }
}