@import 'common';

.root-home {
  .our-work-container {
    padding-top: 0px;
  }

  .our-work-list-heading {
    margin-top: 0px;
  }
}

.our-work-container {
  margin-bottom: 9.167%;
}

.root-services {
  .our-work-container {
    margin-bottom: 6%;
  }
}

div.our-work-list div:nth-child(4n) {
  margin-bottom: 224px;
}

div.our-work-list div:last-child {
  margin-bottom: 0px !important;
}

.our-work-list .our-work-block:not(:first-child) {
  margin-top: -18%;
}

.our-work-block::after {
  content: "";
  display: block;
  clear: both;
}

.our-work-list:after {
  content: '';
  display: block;
  clear: both;
}

.our-work-root {
  position: relative;
  // cursor: none;
}

.our-work {
  background-image: linear-gradient(to right, rgb(28, 40, 152, 1), rgb(28, 40, 152, 0.5));
  border-radius: 32px;
  box-shadow: 2px -2px 13px 2px #142ba4;
  left: 50px;
  height: 100%;
  cursor: none;
}

.our-work-list-heading {
  font-size: $mainHeaderLg;
  font-weight: 700;
  color: #FFFFFF;
  margin: 0px auto;
  width: 85%;
  //margin-top: 72px;
  margin-bottom: 72px
}

.our-work-logo {
  // height: 100%;
  max-width: 100%;
}

.our-work .our-work-logo-container {
  height: 15%;
  width: 100%;
  padding-bottom: 30px;
}

.our-work-main-container {
  height: 100%;
  padding: 50px;
}

.our-work-main-container-right {
  justify-content: flex-end;
}

.our-work-main-container-center {
  justify-content: center;
}

.our-work .our-work-tags {
  width: 100%;
  height: 30%;
  font-size: 20px;
  font-weight: 500;
  padding-top: 3%;
  flex-direction: column !important;
  align-content: space-between !important;
  // justify-content: space-around !important;
  justify-content: end !important;
  row-gap: 20px;
}

.our-work-content {
  font-size: $subHeaderLg;
  font-weight: 500;
  color: #FFFFFF;
  margin-top: 32px;
}

.our-work-image-container {
  height: 55%;
}

.our-work-nologo-image-container {
  max-height: 70%;
}

.our-work-image {
  width: 100%;
  height: 100%;
}

//container
.our-work-card-template-zero-root-container {
  width: 38.542%;
  float: left;
  margin-left: 150px;
}

.our-work-card-template-one-root-container {
  width: 27.292%;
  float: right;
  margin-top: 231px;
  margin-right: 286px;
}

.our-work-card-template-two-root-container {
  width: 38%;
  float: left;
  margin-top: 490px;
  margin-left: 150px;
}

.our-work-card-template-three-root-container {
  width: 27%;
  float: right;
  margin-top: 380px;
  margin-right: 286px;
}

.our-work-block:first-child>.our-work-card-template-one-root-container {
  margin-top: 531px;
}

//dimension
.our-work-card-template-zero-dimension {
  width: 100%;
  height: 584px;
}

.our-work-card-template-one-dimension {
  width: 100%;
  height: 676px;
}

.our-work-card-template-two-dimension {
  width: 100%;
  height: 584px;
}

.our-work-card-template-three-dimension {
  width: 100%;
  height: 676px;
}

//description
.our-work-card-template-zero-description {
  width: 100%;
}

.our-work-card-template-one-description {
  width: 100%;
}

.our-work-card-template-two-description {
  width: 100%;
}

.our-work-card-template-three-description {
  width: 100%;
}


.our-work .click-to-view {
  z-index: 1;
}

.our-work-tags-stack {
  flex-wrap: wrap;
  //width: 500px;
  grid-gap: 20px;
}

.our-work-tags-chip {
  color: #040404 !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  margin: 0px !important;
  height: 50px !important;
  border-radius: 50px !important;
}

.our-work-button-container {
  margin: 72px auto 0px auto;
  text-align: center;
}

.our-work-button {
  border: 2px solid #FF7900 !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, .25) !important;
  color: #FFFFFF !important;
  font-size: 22px !important;
  padding: 16px 36px !important;
  font-weight: 600 !important;
  line-height: 108% !important;
}

.our-work-button:hover {
  border: 2px solid transparent !important;
  background: #FF7900 !important;
}

.our-work-no-data {
  color: #ffffff;
  margin: auto 0px;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

@media (min-width:1501px) and (max-width: 1600px) {

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.375*200);
  }

  .our-work {
    border-radius: 32px - (0.375*32);
    box-shadow: 2px -2px (13px - (0.375*13)) 2px #142ba4;
    left: 50px - (0.375*50);
  }

  .our-work-list-heading {
    font-size: $mainHeaderLgs;
    //margin-top: 72px - (0.375*72);
    margin-bottom: 72px - (0.375*72);
    //margin: (100px - (0.375*100)) (150px - (0.375*150));
  }

  .our-work .our-work-logo-container {
    // margin-bottom: 30px - (0.375*30);
  }

  .our-work-main-container {
    padding: 50px - (0.375*50);
  }

  .our-work .our-work-tags {
    font-size: 18px;
    row-gap: 10px;
  }

  .our-work-content {
    font-size: $subHeaderLgs;
    margin-top: 15px;
  }

  //container
  .our-work-card-template-zero-root-container {
    margin-left: 150px - (0.375*150);
  }

  .our-work-card-template-one-root-container {
    margin-top: 231px - (0.375*231);
    margin-right: 286px - (0.375*286);
  }

  .our-work-card-template-two-root-container {
    margin-top: 490px - (0.375*490);
    margin-left: 150px - (0.375*150);
  }

  .our-work-card-template-three-root-container {
    margin-top: 380px - (0.375*380);
    margin-right: 286px - (0.375*286);
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 531px - (0.375*531);
  }

  //dimension
  .our-work-card-template-zero-dimension {
    height: 584px - (0.375*584);
  }

  .our-work-card-template-one-dimension {
    height: 475px;
  }

  .our-work-card-template-two-dimension {
    height: 584px - (0.375*584);
  }

  .our-work-card-template-three-dimension {
    height: 475px;
  }

  .our-work-tags-stack {
    flex-wrap: wrap;
    //width: 500px - (0.375*500);
    grid-gap: 20px - (0.375*20);
  }

  .our-work-tags-chip {
    font-size: 17px !important;
    margin: 0px !important;
    height: 50px - (0.375*50) !important;
  }

  .our-work-button-container {
    margin: (72px - (0.375*72)) auto 0px auto;
  }

  .our-work-button {
    font-size: 19px !important;
    padding: (16px - (0.375*16)) (36px - (0.375*36)) !important;
  }

  .our-work-no-data {
    font-size: 25px;
  }
}

@media (min-width:1200px) and (max-width: 1500px) {

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.375*200);
  }

  .our-work {
    border-radius: 32px - (0.375*32);
    box-shadow: 2px -2px (13px - (0.375*13)) 2px #142ba4;
    left: 50px - (0.375*50);
  }

  .our-work-list-heading {
    font-size: $mainHeaderLgs;
    margin-bottom: 72px - (0.375*72);
  }

  .our-work-main-container {
    padding: 50px - (0.375*50);
  }

  .our-work .our-work-tags {
    font-size: 18px;
    row-gap: 10px;
  }

  .our-work-content {
    font-size: $subHeaderLgs;
    margin-top: 15px;
  }

  //container
  .our-work-card-template-zero-root-container {
    margin-left: 150px - (0.375*150);
  }

  .our-work-card-template-one-root-container {
    margin-top: 231px - (0.375*231);
    margin-right: 286px - (0.375*286);
  }

  .our-work-card-template-two-root-container {
    margin-top: 490px - (0.375*490);
    margin-left: 150px - (0.375*150);
  }

  .our-work-card-template-three-root-container {
    margin-top: 380px - (0.375*380);
    margin-right: 286px - (0.375*286);
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 531px - (0.375*531);
  }

  //dimension
  .our-work-card-template-zero-dimension {
    height: 584px - (0.375*584);
  }

  .our-work-card-template-one-dimension {
    height: 475px;

    .our-work-tags {
      height: 22%;
    }

    .our-work-image-container {
      height: 60%;
    }
  }

  .our-work-card-template-two-dimension {
    height: 584px - (0.375*584);
  }

  .our-work-card-template-three-dimension {
    height: 475px;

    .our-work-tags {
      height: 22%;
    }

    .our-work-image-container {
      height: 60%;
    }
  }

  .our-work-tags-stack {
    flex-wrap: wrap;
    //width: 500px - (0.375*500);
    grid-gap: 20px - (0.375*20);
  }

  .our-work-tags-chip {
    font-size: 14px !important;
    margin: 0px !important;
    height: 50px - (0.375*50) !important;
  }

  .our-work-button-container {
    margin: (72px - (0.375*72)) auto 0px auto;
  }

  .our-work-button {
    font-size: 16px !important;
    padding: (16px - (0.375*16)) (36px - (0.375*36)) !important;
  }

  .our-work-no-data {
    font-size: 25px;
  }

  .our-work-image {
    max-height: 100%;
    height: auto !important;
  }
}

@media (min-width:900px) and (max-width: 1200px) {

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.53125*200);
  }

  .our-work {
    border-radius: 32px - (0.53125*32);
    box-shadow: 2px -2px (13px - (0.53125*13)) 2px #142ba4;
    left: 50px - (0.53125*50);
  }

  .our-work-list-heading {
    font-size: $mainHeaderMd;
    //margin-top: 72px - (0.53125*72);
    margin-bottom: 72px - (0.53125*72);
    //margin: (100px - (0.53125*100)) (150px - (0.53125*150));
  }

  .our-work .our-work-logo-container {
    margin-bottom: 30px - (0.53125*30);
  }

  .our-work-main-container {
    padding: 18px;
  }

  .our-work .our-work-tags {
    font-size: 16px;
  }

  .our-work-content {
    font-size: $subHeaderMd;
    margin-top: 12.5px;
  }

  //container
  .our-work-card-template-zero-root-container {
    margin-left: 150px - (0.53125*150);
  }

  .our-work-card-template-one-root-container {
    margin-top: 231px - (0.53125*231);
    margin-right: 286px - (0.53125*286);
  }

  .our-work-card-template-two-root-container {
    margin-top: 490px - (0.53125*490);
    margin-left: 150px - (0.53125*150);
  }

  .our-work-card-template-three-root-container {
    margin-top: 380px - (0.53125*380);
    margin-right: 286px - (0.53125*286);
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 531px - (0.53125*531);
  }

  //dimension
  .our-work-card-template-zero-dimension {
    height: 584px - (0.53125*584);
  }

  .our-work-card-template-one-dimension {
    height: 350px;
  }

  .our-work-card-template-two-dimension {
    height: 584px - (0.53125*584);
  }

  .our-work-card-template-three-dimension {
    height: 350px;
  }

  .our-work-tags-stack {
    flex-wrap: wrap;
    //width: 500px - (0.53125*500);
    grid-gap: 20px - (0.53125*20);
  }

  .our-work-tags-chip {
    font-size: 13px !important;
    margin: 0px !important;
    height: 25px !important;
  }

  .our-work-button-container {
    margin: (72px - (0.53125*72)) auto 0px auto;
  }

  .our-work-button {
    font-size: 12px !important;
    padding: (16px - (0.53125*16)) (36px - (0.53125*36)) !important;
  }

  .our-work-no-data {
    font-size: 20px;
  }
}

@media (min-width:641px) and (max-width: 899px) {

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.66*200);
  }

  .our-work {
    border-radius: 32px - (0.66*32);
    box-shadow: 2px -2px (13px - (0.66*13)) 2px #142ba4;
    left: 50px - (0.66*50);
  }

  .our-work-list-heading {
    font-size: $mainHeaderSm;
    //margin-top: 72px - (0.66*72);
    margin-bottom: 72px - (0.66*72);
    //margin: (100px - (0.66*100)) (150px - (0.66*150));
  }

  .our-work .our-work-logo-container {
    margin-bottom: 30px - (0.66*30);
  }

  .our-work-main-container {
    padding: 13px;
  }

  .our-work .our-work-tags {
    font-size: 14px;
  }

  .our-work-content {
    font-size: $subHeaderSm;
    margin-top: 12px;
  }

  //container
  .our-work-card-template-zero-root-container {
    width: 42%;
    margin-left: 150px - (0.66*150);
  }

  .our-work-card-template-one-root-container {
    width: 30%;
    margin-top: 231px - (0.66*231);
    margin-right: 61px;
  }

  .our-work-card-template-two-root-container {
    width: 42%;
    margin-top: 490px - (0.66*490);
    margin-left: 150px - (0.66*150);
  }

  .our-work-card-template-three-root-container {
    width: 30%;
    margin-top: 380px - (0.66*380);
    margin-right: 61px;
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 531px - (0.66*531);
  }

  //dimension
  .our-work-card-template-zero-dimension {
    height: 584px - (0.66*584);
  }

  .our-work-card-template-one-dimension {
    height: 300px;
  }

  .our-work-card-template-two-dimension {
    height: 584px - (0.66*584);
  }

  .our-work-card-template-three-dimension {
    height: 300px;
  }

  .our-work-tags-stack {
    grid-gap: 8px;
  }

  .our-work-tags-chip {
    font-size: 14px !important;
    margin: 0px !important;
    height: 16px !important;
  }

  .our-work-button-container {
    margin: (72px - (0.66*72)) auto 0px auto;
  }

  .our-work-button {
    font-size: 10px !important;
    padding: 5px !important;
    border: 1px solid #FF7900 !important;
  }

  .our-work-no-data {
    font-size: 18px;
  }

  .css-6od3lo-MuiChip-label {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

@media(max-width: 424px) {
  .our-work-main-container {
    flex-direction: column !important;
    justify-content: space-around;
    flex-wrap: nowrap !important;
  }

  .our-work-container {
    margin-bottom: 20%;

    .our-work-list-heading {
      width: 90%;
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 30px;
      font-size: $mainHeaderMobileMd;
    }
  }

  .our-work-list .our-work-block:not(:first-child) {
    margin-top: 50px;
  }

  .our-work-root {
    margin-bottom: 50px !important;
  }

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.875*200);
  }

  .our-work {
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(30, 41, 148, 0.2);
    left: 50px - (0.875*50);
  }

  .our-work .our-work-logo-container {
    height: 24px;
    width: auto;
    margin-bottom: 10px;
  }

  .our-work-logo {
    max-width: none;
    height: 30px;
  }

  .our-work-main-container {
    padding: 20px;
  }

  .our-work-nologo-image-container {
    padding: 0 20px;
    text-align: right;
  }

  .our-work-image-container {
    text-align: right;
    padding: 0 20px;
    max-height: 55%;
  }

  .our-work-image {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 140px;
  }

  .our-work .our-work-tags {
    row-gap: 0;
    font-size: 14px;
  }

  .our-work-content {
    font-size: $sub2HeaderMd;
    line-height: 1.4;
    margin-top: 20px;
  }

  //container
  .our-work-card-template-zero-root-container,
  .our-work-card-template-one-root-container,
  .our-work-card-template-two-root-container,
  .our-work-card-template-three-root-container {
    float: none;
    width: 90%;
    margin: 0px auto;
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 0px;
  }


  //dimension
  .our-work-card-template-zero-dimension,
  .our-work-card-template-one-dimension,
  .our-work-card-template-two-dimension,
  .our-work-card-template-three-dimension {
    height: 300px;
  }

  .our-work-tags-stack {
    flex-wrap: none;
    width: 100%;
    grid-gap: 0;
    padding-bottom: 10px;

    .chip-tags {
      font-size: 8px !important;
    }
  }

  .cs-banner {
    .our-work-tags-stack {
      padding-bottom: 0;

      .chip-tags {
        margin-bottom: 12px !important;
        height: 28px !important;
        padding: 6px 12px !important;
        font-size: 14px !important;
      }
    }
  }

  .our-work-tags-chip {
    font-size: 14px !important;
    margin: 0px 0px 3px !important;
    margin-right: 15px !important;
    height: 20px !important;
  }

  .our-work-no-data {
    font-size: 16px;
  }
}

@media (min-width:425px) and (max-width: 640px) {
  .our-work-main-container {
    flex-direction: column !important;
    justify-content: space-around;
    flex-wrap: nowrap !important;
  }

  .our-work-container {
    margin-bottom: 20%;

    .our-work-list-heading {
      width: 90%;
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 30px;
      font-size: $mainHeaderMobileMd;
    }
  }

  .our-work-list .our-work-block:not(:first-child) {
    margin-top: 50px;
  }

  .our-work-root {
    margin-bottom: 50px !important;
  }

  div.our-work-list div:nth-child(4n) {
    margin-bottom: 200px - (0.875*200);
  }

  .our-work {
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(30, 41, 148, 0.2);
    left: 50px - (0.875*50);
  }

  .our-work .our-work-logo-container {
    height: 24px;
    width: auto;
    margin-bottom: 10px;
  }

  .our-work-logo {
    max-width: none;
    height: 30px;
  }

  .our-work-main-container {
    padding: 20px;
  }

  .our-work-nologo-image-container {
    padding: 0 20px;
    text-align: right;
  }

  .our-work-image-container {
    text-align: right;
    padding: 0 20px;
    max-height: 55%;
  }

  .our-work-image {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 140px;
  }

  .our-work .our-work-tags {
    row-gap: 0;
    font-size: 14px;
  }

  .our-work-content {
    font-size: $sub2HeaderMd;
    line-height: 1.4;
    margin-top: 20px;
  }

  //container
  .our-work-card-template-zero-root-container,
  .our-work-card-template-one-root-container,
  .our-work-card-template-two-root-container,
  .our-work-card-template-three-root-container {
    float: none;
    width: 90%;
    margin: 0px auto;
  }

  .our-work-block:first-child>.our-work-card-template-one-root-container {
    margin-top: 0px;
  }


  //dimension
  .our-work-card-template-zero-dimension,
  .our-work-card-template-one-dimension,
  .our-work-card-template-two-dimension,
  .our-work-card-template-three-dimension {
    height: 300px;
  }

  .our-work-tags-stack {
    flex-wrap: none;
    width: 100%;
    grid-gap: 0;
    padding-bottom: 10px;

    .chip-tags {
      font-size: 10px !important;
    }
  }

  .cs-banner {
    .our-work-tags-stack {
      padding-bottom: 0;

      .chip-tags {
        margin-bottom: 12px !important;
        height: 28px !important;
        padding: 6px 12px !important;
        font-size: 14px !important;
      }
    }
  }

  .our-work-tags-chip {
    font-size: 14px !important;
    margin: 0px !important;
    margin-right: 15px !important;
    height: 20px !important;
  }

  .our-work-no-data {
    font-size: 16px;
  }
}

@media(max-width: 900px) {

  .our-work-button-container {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-top: 60px;

    .our-work-button {
      font-size: 16px !important;
      height: 44px !important;
      padding: 5px 24px !important;
    }
  }
}