$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

@import './common.scss';

.about-connect {
    min-height: 1000px
}

.career-contact {
    .about-connect {
        min-height: auto
    }

    .about-connect-center {

        width: 37%;
        margin-left: 7%;
    }
}

.about-connect-center {

    width: 85%;
    margin: 0 auto;
}

.about-connect-title {
    font-weight: 700;
    font-size: $mainHeaderLg;
    line-height: 115%;
    /* or 129% */


    color: #FFFFFF;
}

.about-connect-main {
    //font-weight: 500;
    font-size: $subContentLg;
    line-height: 150%;
    /* or 60px */


    /* white */

    color: #FFFFFF;
    p {
        margin: 0;
    }
}

.about-connect {
    position: relative;

    .button {
        margin-top: 90px !important;
        background: $orangeColor !important;
        color: $whiteColor !important;
        border-radius: 8px !important;
        //font-weight: 600 !important;
        height: 66px;
        width: 249px;
        z-index: 1;
        // position: absolute;
        font-size: 24px;
    }

    .button:hover {
        background: $orangeColor !important;
    }
}

.button-radiant {
    background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    height: 450px;
    width: 450px;
    position: absolute;
    top: -100px;
    left: -60px;
}

.about-connect-title-bg {
    // background-image: url('../assets//About/connect-title-bg.png');
    // background: linear-gradient(180deg, rgba(29, 32, 59, 0) 0%, rgba(29, 32, 59, 0.1) 6.13%, rgba(29, 32, 59, 0.8) 20.64%);
    // backdrop-filter: blur(4px);
    padding-top: 200px;
    padding-bottom: 30px;

}

.about-connect-main-bg {
    // background-image: url('../assets/About/connect-desc-bg.png');
    // padding-top: 70px;
    padding-bottom: 80px;
    // background: linear-gradient(0deg, rgba(29, 32, 59, 0) 0%, rgba(29, 32, 59, 0.1) 6.13%, rgba(29, 32, 59, 0.8) 20.64%);
    // backdrop-filter: blur(4px);

}

.bg-main-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: hidden;
}

.bg-radiant {
    background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    left: 75%;
    top: -10%;
}

.contact-img-container {
    position: absolute;
    right: 2%;
    top: -3%;
}

.cta-title-align {
    width: 60%;
}

@media (max-width:640px) {

    .about-connect {
        padding-bottom: 30px;
    }

    .about-connect-title {
        font-size: $mainHeaderXs;
    }

    .about-connect-title-bg {
        padding: 0 0 20px;
    }
    
    .about-connect-main-bg {
        padding: 0;
    }

    .about-connect-main {
        font-size: $subContentXs;
    }

    .career-contact {
        .about-connect-center {
            width: 100%;
            margin: 0;
        }
    }

    .about-connect-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
    }

    .about-connect-main {
        font-size: 16px;
        line-height: 150%;
        p {
            margin: 0;
        }
    }

    .contact-img-container {
        position: absolute;
        right: 0%;
        top: 0%;
        width: 40%;
    }

    .about-connect {
        .button {
            font-size: x-small !important;
            height: 35px;
            width: 100px;
            margin-top: 55px !important;
        }

        .contact-image {
            width: 100%;
            z-index: 1;
            position: relative;
            right: 4%;
        }
    }

    .button-radiant {
        height: 250px;
        width: 250px;
        top: -60px;
        left: -20px;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .about-connect-title {
        font-size: $mainHeaderSm;
    }

    .about-connect-title-bg {
        padding-bottom: 45px;
    }

    .about-connect-main {
        font-size: $subContentSm;
    }

    .contact-img-container {
        position: absolute;
        right: 0%;
        top: 0%;
        width: 40%;
    }

    .about-connect {
        .button {
            font-size: small !important;
            height: 50px;
            width: 200px;
            margin-top: 55px !important;
        }

        .contact-image {
            width: 100%;
            z-index: 1;
            position: relative;
            right: 4%;
        }
    }

    .button-radiant {
        height: 250px;
        width: 250px;
        top: -60px;
        left: -20px;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .about-connect-title {
        font-size: $mainHeaderMd;
    }

    .about-connect-main {
        font-size: $subContentMd;
    }

    .about-connect {
        .button {
            font-size: medium !important;
            height: 50px;
            width: 200px;
        }

        .contact-img-container {
            position: absolute;
            right: 0%;
            top: -3%;
            width: 45%;
        }

        .bg-radiant {
            width: 85%;
        }

        .cotact-image {
            width: 85%;
            right: -8%;
        }
    }
    .button-radiant {
        height: 300px;
        width: 300px;
        top: -50px;
        left: -40px;
    }

}

@media (min-width:1201px) and (max-width: 1500px) {
    .about-connect-title {
        font-size: $mainHeaderLgs;
    }

    .about-connect-main {
        font-size: $subContentLgs;
    }

    .contact-img-container {
        width: 35%;
    }
    .about-connect-main-bg {
        padding-bottom: 335px;
    }
    .about-connect {
        .button {
            margin-top: 50px !important;
            font-size: medium !important;
            height: 50px;
            width: 200px;
        }

        .cotact-image {
            width: 80%;
            right: -15%;
        }
    }

    .css-2qziid-MuiGrid-root {
        max-width: 44.833333% !important;
    }
}

@media (min-width:1501px) and (max-width: 1800px) {
    .about-connect-title {
        font-size: $mainHeaderLgs;
    }

    .about-connect-main {
        font-size: $subContentLgs;
    }

    .contact-img-container {
        width: 35%;
    }
    .about-connect-main-bg {
        padding-bottom: 335px;
    }
    .about-connect {
        .button {
            margin-top: 50px !important;
            font-size: medium !important;
            height: 50px;
            width: 200px;
        }

        .cotact-image {
            width: 80%;
            right: -15%;
        }
    }

    .css-2qziid-MuiGrid-root {
        max-width: 44.833333% !important;
    }
}