@import 'common.scss';

.service-tt-used {
    margin: 0px auto;
    width: 85%;
    padding-bottom: 10%;
}


.service-tt-used-title {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    color: #FFFFFF;
    margin: 64px 0px;
}

.service-tt-used-logo-main-container {
    text-align: center;
    width: 45%;
    // max-width: 0% !important;
}

.service-tt-used-outer-container {
    flex-direction: row !important;
    justify-content: space-between;
    //column-gap: 40px;
    row-gap: 64px;
}

.service-tt-used-outer-container-mob-view {
    display: none !important;
}

.service-tt-used-logo-inner-container {
    justify-content: space-around;
    gap: 26px;
}

.service-tt-used-logo-text {
    font-style: normal;
    font-size: 18px;
    padding-top: 20px;
    text-transform: uppercase;
    color: #D7DBDC;
}
.service-tt-used-logo-texts {
    font-style: normal;
    font-size: 14px;
    padding-top: 12px;
    text-transform: uppercase;
    color: #D7DBDC;
}

.service-tt-used-logo-list-heading {
    font-style: normal;
    font-weight: 700;
    font-size: $subContentLg;
    text-align: center;
    color: #FF7900;
    margin-bottom: 38px;
    .dropdown-icon {
        display: none;
        width: 12px;
        height: 16px;
        line-height: 14px;
        margin-left: 8px;
        transition: transform 800ms ease 0ms;
    }
}

.service-tt-used-logo {
    width: 80px;
    height: 80px;
}


.service-tt-used-cue {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: right;
}
@media (min-width:1900px) {
    .service-tt-used-s-row {
        .service-tt-used-logo {
            width: auto;
            height: auto;
        }
        .service-tt-used-logo-inner-container {
            column-gap: 150px;
        }
        .service-tt-used-logo-text {
            padding-top: 20px;
        }
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    .service-tt-used-title {
        font-size: 54px - (0.375*54);
        margin: 64px 0px;
    }
    
    .service-tt-used-outer-container {
        row-gap: 64px - (0.375*64);
    }
    
    .service-tt-used-outer-container-mob-view {
        display: none !important;
    }

    .service-tt-used-logo-inner-container {
        gap: 24px;
    }
    
    .service-tt-used-logo-text {
        font-size: 15px;
    }
    
    .service-tt-used-logo-list-heading {
        font-size: $subContentLgs - 2px;
        margin-bottom: 38px - (0.375*38);
    }
    
    .service-tt-used-logo {
        width: 40px - (0.375*40);
        height: 40px - (0.375*40);
    }
    .service-tt-used-logos {
        max-height: 80px;
    }
    
    .service-tt-used-cue {
        font-size: 32px - (0.375*32);
    }

    .service-tt-used-logo-single {
        height: 75px;
        width: 75px;
    }
    .service-tt-used-s-row {
        .service-tt-used-logo {
            width: 70px;
            height: auto;
        }
        .service-tt-used-logo-inner-container {
            column-gap: 100px;
        }
        .service-tt-used-logo-text {
            padding-top: 20px;
        }
    }
}
@media (min-width:1200px) and (max-width: 1500px) {
    .service-tt-used-title {
        font-size: 54px - (0.375*54);
        margin: 64px 0px;
    }
    
    .service-tt-used-outer-container {
        //column-gap: 40px - (0.375*40);
        row-gap: 64px - (0.375*64);
    }
    
    .service-tt-used-outer-container-mob-view {
        display: none !important;
    }

    .service-tt-used-logo-inner-container {
        gap: 24px;
    }
    
    .service-tt-used-logo-text {
        font-size: 15px;
    }
    
    .service-tt-used-logo-list-heading {
        font-size: $subContentLgs - 2px;
        margin-bottom: 38px - (0.375*38);
    }
    
    .service-tt-used-logo {
        width: 40px - (0.375*40);
        height: 40px - (0.375*40);
    }
    .service-tt-used-logos {
        max-height:  60px;
    }
    
    .service-tt-used-cue {
        font-size: 32px - (0.375*32);
    }

    .service-tt-used-logo-single {
        height: 75px;
        width: 75px;
    }
    .service-tt-used-s-row {
        .service-tt-used-logo {
            width: 70px;
            height: auto;
        }
        .service-tt-used-logo-inner-container {
            column-gap: 100px;
        }
        .service-tt-used-logo-text {
            padding-top: 20px;
        }
    }
}
@media (min-width:900px) and (max-width: 1200px) {
    .service-tt-used-title {
        font-size: 54px - (0.53125*54);
        margin: 64px 0px;
    }
    
    .service-tt-used-outer-container {
        //column-gap: 40px - (0.53125*40);
        row-gap: 64px - (0.53125*64);
    }

    .service-tt-used-outer-container-mob-view {
        display: none !important;
    }
    
    .service-tt-used-logo-inner-container {
        gap: 13px;
    }
    
    .service-tt-used-logo-text {
        font-size: 11px;
    }
    
    .service-tt-used-logo-list-heading {
        font-size: $subContentMd;
        margin-bottom: 38px - (0.53125*38);
    }
    
    .service-tt-used-logo {
        width: 25px;
        height: 25px;
    }
    
    .service-tt-used-cue {
        font-size: 32px - (0.53125*32);
    }
}
@media (min-width:641px) and (max-width: 899px) {
    .service-tt-used-title {
        font-size: 54px - (0.66*54);
        margin: 64px 0px;
    }
    
    .service-tt-used-outer-container {
        //column-gap: 40px - (0.66*40);
        row-gap: 64px - (0.66*64);
    }
    
    .service-tt-used-outer-container-mob-view {
        display: none !important;
    }

    .service-tt-used-logo-inner-container {
        gap: 10px;
    }
    
    .service-tt-used-logo-text {
        font-size: 10px;
    }
    
    .service-tt-used-logo-list-heading {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 38px - (0.66*38);
    }
    
    .service-tt-used-logo {
        width: 25px;
        height: 25px;
    }
    
    .service-tt-used-cue {
        font-size: 32px - (0.66*32);
    }
}
@media (max-width:640px){
    #service-tt-used  {
        padding-bottom: 80px;
    }

    .service-tt-used-logo-texts {
        padding-top: 5px;
    }

    .service-tt-used {
        .service-tt-used-col-container {
            margin-top: 8px;
        }
    }
    .service-tt-used-outer-container {
        display: none !important;
    }
    
    .service-tt-used-outer-container-mob-view {
        display: block !important;
    }

    .service-tt-used-logo-inner-container {
        gap: 0 30px;
        padding: 40px 30px 10px;
        border-radius: 8px;
        text-align: center;
        justify-content: space-around !important;
        background-color: #353B5F;
    }
    
    .service-tt-used-logo-main-container {
        width: 100px;
        min-width: 100px;
        margin: 0 auto;
        padding-bottom: 30px;
        max-width: 50% !important;
        text-align: center;
        
        .service-tt-used-logo, .service-tt-used-logos {
            width: 40px;
            height: 40px;
        }
        
        .service-tt-used-logo-text {
            font-size: 11px;
            line-height: 1.4;
            padding-top: 5px;
            font-weight: 400;
        }
    }

    
    .service-tt-used-logo-list-heading {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    
    .service-tt-used-logo {
        width: 25px;
        height: 25px;
    }
    
    .service-tt-used-cue {
        display: none !important;
    }

}

@media (max-width:900px) {
    .service-tt-used {
        margin: 0px auto;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        .desktop-view {
            .service-tt-used-logo-inner-container {
                display: none; 
            }
        }
        .service-tt-used-outer-container-mob-view {
            .service-tt-used-logo-inner-container {
                display: none; 
            }
        }
    }
    .service-tt-used-title {
        font-size: 24px;
        margin: 40px 0px 20px;
    }
    .service-tt-used-logo-list-heading {
        color: #FFFFFF;
        margin-bottom: 0;
        padding: 16px;
        border-radius: 8px;
        background: #313759;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
    }
    .service-tt-used-outer-container-mob-view {
        .service-tt-used-logo-list-heading {
            text-align: center;
        }
    }
    .service-tt-used-logo-inner-container {
        margin-top: 24px;
    }
    .service-tt-used-col-container {
        width: 100% !important;
        .dropdown-icon {
            display: none;
            transform: rotate(-180deg);
            vertical-align: middle;
        }
        &.active {
            .service-tt-used-logo-list-heading {
                background: var(--Orange-Fill, #FF7900);
                border-radius: 8px;
                .dropdown-icon {
                    display: inline-block;
                }
            }
            .service-tt-used-logo-inner-container {
                display: flex !important; 
                padding: 24px 38px;
            }
            
            .service-tt-used-col-container {
                margin-top: 30px;
            }
    
        }
    }

    .service-tt-used-outer-container {
        flex-direction: column !important;
        align-items: center;
        row-gap: 8px;
        .MuiGrid-root {
            max-width: 100%;
        }
    }
}

