@import 'common';

.service-page-workflow {

    width: 85%;
    margin: 0 auto;
    padding-bottom: 224px;

    // .container-num {
    //     padding-top: 15px;
    // }

    .service-page-workflow-main-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 130%;
        color: #FFFFFF;
        margin-bottom: 80px;
    }

    .workflow-title {
        font-weight: 500;
        font-size: $subHeaderLg;
        line-height: 120%;
        /* identical to box height, or 65px */


        color: #FF7900;
        // margin-bottom: 25px;
    }

    .workflow-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 60px */
        padding-top: 20px;

        color: #FFFFFF;

        p {
            margin: 0px !important;
        }
    }

    .number-align {
        font-weight: 700;
        font-size: 50px;
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        border-radius: 50%;
        width: 84px;
        height: 84px;
        border: 3px solid #FF7900;
        position: relative;
    }

    .inner-number-align {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 10%;
    }

    .workflow-odd {
        justify-content: flex-end;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-page-workflow {

        padding-bottom: 100px;

        .service-page-workflow-main-title {
            font-size: $mainHeaderSm;
            margin-bottom: 70px;
        }

        .workflow-title {
            font-size: $subHeaderSm;
        }

        .workflow-desc {
            font-size: $subContentSm;
        }

        .number-align {
            font-size: 24px;
            line-height: 120%
        }
    }

}

@media (min-width:900px) and (max-width: 1200px) {
    .service-page-workflow {

        padding-bottom: 150px;

        .service-page-workflow-main-title {
            font-size: $mainHeaderMd;
            margin-bottom: 70px;
        }

        .workflow-title {
            font-size: $subHeaderMd;
        }

        .workflow-desc {
            font-size: $subContentMd;
        }

        .number-align {
            font-size: 30px;
            line-height: 120%
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {

    .service-page-workflow {

        padding-bottom: 200px;

        .service-page-workflow-main-title {
            font-size: $mainHeaderLgs;
            margin-bottom: 70px;
        }

        .workflow-title {
            font-size: $subHeaderLgs;
        }

        .workflow-desc {
            font-size: $subContentLgs;
        }

        .number-align {
            font-size: 40px;
            line-height: 130%;
        }

        .inner-number-align {
            top: 20%
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {

    .service-page-workflow {

        padding-bottom: 200px;

        .service-page-workflow-main-title {
            font-size: $mainHeaderLgs;
            margin-bottom: 70px;
        }

        .workflow-title {
            font-size: $subHeaderLgs;
        }

        .workflow-desc {
            font-size: $subContentLgs;
        }

        .number-align {
            font-size: 40px;
            line-height: 130%;
        }

        .inner-number-align {
            top: 15%
        }
    }
}

@media (max-width: 640px) {
    .service-page-workflow {
        width: 90%;
        padding-bottom: 40px;

        .service-page-workflow-main-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.6;
            margin-bottom: 40px;
        }

        .workflow {
            flex-wrap: nowrap;
            padding-bottom: 40px;

            .container-num {
                min-width: 45px;
                padding-top: 0;
                height: 45px;
                border-radius: 50%;
                border: 1px solid #FF7900;
                margin-right: 20px;

                .number-align {
                    width: 45px;
                    height: 45px;
                    font-size: $mainHeaderMobileMd;
                    font-weight: 600;
                    line-height: 45px;
                    display: block;
                    border: none;

                    .inner-number-align {
                        top: 0;
                    }
                }
            }

            .workflow-content {
                order: 2;


                .workflow-title {
                    font-weight: 600;
                    line-height: 1.6;
                    font-size: $mainHeaderSm;
                }

                .workflow-desc {
                    padding-top: 10px;
                    font-size: $subContentMobile;
                    line-height: 1.8;
                }
            }
        }
    }
}