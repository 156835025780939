@import './common';

.cs-dev {
    padding-bottom: 170px;
    .title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: $whiteColor;
        margin-bottom: 60px; // bug: cs-lightpong - 14(13) 
    }

    .sub-desc {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;

        p {
            margin: 0 !important;
        }
    }

    .lt-number {
        font-weight: 700;
        font-size: 50px;
        line-height: 85px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 90px;
        border: 3.13889px solid #FF7900;
        border-radius: 50%;
        cursor: pointer;
    }
    .lt-number:hover {
        background-color: $orangeColor;
    }
    .cs-hl-rt {
        padding-left: 15%;
    }
    .cs-hl-ni {
        width: 54%;
    }
    .flt-rt {
        float: right;
    }

    .cs-highlight-header {
        margin-bottom: 64px;
    }
    .lt-gradient-number {
        color: #FFF;
        text-align: center;
        font-size: 70px;
        font-family: Neue Haas Grotesk Display Pro;
        font-weight: 700;
        line-height: 150%;
        width: 166px;
        height: 166px;
        border-radius: 166px;
        background: radial-gradient(50% 50.00% at 50% 50.00%, #1C2898 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0.00) 100%);
        margin-left: -40px;
        div {
            padding-top: 26px;
        }
    }

    .ni-title {
        font-weight: 700;
        font-size: $subHeaderLg;
        line-height: 133%;
        color: $whiteColor;
        margin-bottom: 20px;
        h3 {
            font-size: $subHeaderLg !important;
        }
    }
    .ni-desc {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
    }
}
#talis-desktop-slides {
    justify-content: space-between;
    .desktop-slide-items {
        .desktop-slide-image {
            min-height: 200px;
            max-height: 200px;
        }
        .desktop-slide-label {
            margin: 32px 0 0 0;
        }
    }
}
 
#talis-desktop-slides.tals-mb {
    margin-bottom: 176px;
}
#talis-desktop-large-slides {
    justify-content: space-between;
    .tals-mb {
        margin-bottom: 88px;
    }
}
#myfitmix-desktop-slides {
    justify-content: center;
    align-items: center;
    column-gap: 12%;
    margin-bottom: 75px;
    .desktop-slide-items { 
        .desktop-slide-image {
			height:100%
		}
    }
}
.mybuddy-image-container {
    img {
        width: 100%;
    }
}

#spotsize-desktop-slides {
    .desktop-slide-items {
        text-align: center;
        .spotsize-title {
            margin-top:35%;
            color:White;
            text-align: left;
            padding-left: 20px;
        }
        .spotsize-image {
            width: 362px;
        }
    }
}

#spotsize-mobile-slides {
    .desktop-slide-items {
        text-align: center;
        .spotsize-title {
            margin-top:31px;
            margin-bottom: 30px;
            color:White
        }
        .desktop-slide-image {
            width: 100%;
            max-width: 362px;
        }
    }
}

#hausarztpraxis-desktop-slides {
    justify-content: space-around;
}

#riso-desktop-slides {
    justify-content: space-between;
    .desktop-slide-items {
        padding: 0 20px;
    }
}

#automotive-desktop {
    align-items: center;
    justify-content: space-between;
}

#valve-slider {
    justify-content: space-between;
}

#togomed {
    justify-content: space-around;
}

#service-highlights {
    margin-bottom: 110px;
}

.fundflow-image-container {
    img {
        width: 100%;
    }
}

.desktop-slide-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    width: 100%;
	&.cg-5 {
		column-gap: 5%;
	}
    &.cg-120 {
		column-gap: 120px;
	}
    .desktop-slide-half-items {
        -webkit-box-flex: 0;
		flex-grow: 0;
		box-sizing: border-box;
        display: flex;
        flex-basis:49%; 
        max-width:49%;
    }
	.desktop-slide-items { 
		-webkit-box-flex: 0;
		flex-grow: 0;
		box-sizing: border-box;
        text-align: center;
        &.w-20 {
			flex-basis:20%; 
			max-width:20%; 
		}
		&.w-25 {
			flex-basis:25%; 
			max-width:25%; 
		}
        &.w-32 {
			flex-basis:32%; 
			max-width:32%; 
		}
		&.w-33 {
			flex-basis:33.333333%; 
			max-width:33.333333%; 
		}		
		&.w-35 {
			flex-basis:35%; 
			max-width:35%; 
		}		
		&.w-40 {
			flex-basis:40%; 
			max-width:40%; 
		}
        &.w-45 {
			flex-basis:45%; 
			max-width:45%; 
		} 
		&.w-50 {
			flex-basis:50%; 
			max-width:50%; 
		}	
        &.w-65 {
			flex-basis:64%; 
			max-width:64%; 
		}	
		&.w-66 {
			flex-basis:65%; 
			max-width:65%; 
		}
		.desktop-slide-image {
			width:100%
		}
		p,
		.desktop-slide-label {
			text-align:center;
			&.strong {
				font-weight: 700;
			}
		}
	}
}


@media (max-width: 640px) {
  #service-highlights {
    margin-bottom: 20px;
  }
    .cs-dev {
        .title {
            color: #FF7900;
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 30px;   
        }
        img {
            max-width: 100%;
            margin-top: 20px;
        }
        .cs-hl-ni {
            width: 100%;
            margin-bottom: 50px;

            img {
                max-width: 100%;
                margin-top: 10px;
            }

            .cs-hl-ni-title {
                position: relative;
                align-items: flex-start;

                .lt-gradient-number {
                    font-size: 20px;
                    line-height: 40px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-left: 0;
                    display: block;
                    margin-right: 20px;
                    background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(28, 40, 152, 0.91) 20.08%, rgba(28, 38, 128, 0.00) 100%);

                    div {
                        padding-top: 0;
                    }

                }

                .ni-title h3 {
                    margin: 5px 0 0;
                }
            }
        }

        .lt-gradient-number {
            font-size: 20px;
            line-height: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: radial-gradient(50% 50.00% at 50% 50.00%, #FF7900 0%, rgba(28, 40, 152, 0.91) 40.08%, rgba(28, 38, 128, 0.00) 100%);
        }

        .lt-number {
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
            margin-right: 15px;
        }

        .n-title {
            margin: 0;
            font-size: 16px;
        }

        .ni-title {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            margin-bottom: 20px;

            h3 {
                font-size: 20px !important;
            }
        }

        .ni-desc {
            font-size: 16px;
        }
        
        
        .lt-number {
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
            margin-right: 15px;
        }

        .sub-desc {
            font-size: 16px !important;
            p {
                margin: 0 !important;
                padding-bottom: 10px;
            }
        }

        .dev-wrapper-parent {
            row-gap: 34px !important;
        }

        .dev-wrapper {
            align-items: flex-start !important;
            flex-wrap: nowrap !important;
        }
    }
}

.desktop-e-commerce-image {
    width:100%
}


@media (max-width: 900px) {
    .desktop-slide-container {
        align-items: center;
        flex-direction: column;
        text-align: center;
        .desktop-slide-half-items {
            -webkit-box-flex: 0;
            flex-grow: 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex-basis:90%; 
            max-width:90%;
        }
        .desktop-slide-items {
            flex-basis: auto;
            width: 100%;	
            &.w-45 {
                flex-basis:100%; 
                max-width:100%; 
            }	
            &.w-50 {
                flex-basis:100%; 
                max-width:100%; 
            }		
            &.w-66 {
                flex-basis:100%; 
                max-width:100%; 
            }
        }
    }

    #riso-desktop-slides {
        .desktop-slide-items {
            padding: 5px;
            &.w-25 {
                flex-basis:80%; 
                max-width:80%; 
            }
        }
    }
    #myfitmix-desktop-slides {
        column-gap: 0%;
        .desktop-slide-items { 
            &:first-child {
                margin-bottom: 25px;
            }
            &.w-40
            {
                flex-basis:80%; 
                max-width:80%; 
            }
            &.w-20 {
                flex-basis:50%; 
                max-width:50%; 
            }
            .desktop-slide-image {
                height: auto;
            }
        }
    }
    #talis-desktop-slides {
        .desktop-slide-items { 
            margin-bottom: 25px;
            &.w-25 {
                flex-basis:90%; 
                max-width:90%; 
            }
            &.w-32 {
                flex-basis:90%; 
                max-width:90%; 
            }	
            &.w-35 {
                flex-basis:90%; 
                max-width:90%; 
            }
        }

    }
    #spotsize-desktop-slides {
        .desktop-slide-items {
            .spotsize-title {
                margin-top:31px;
                margin-bottom: 30px;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                padding-left: 0;
            }
        }
    }

    .cs-dev {
        padding: 40px 0;
    }    
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-dev {
        .title {
            font-size: $subHeaderLgs;
        }

        .sub-desc {
            font-size: $subContentLgss;
        }

        .lt-number {
            font-size: 30px;
            line-height: 50px;
            width: 55px;
        }

        .lt-gradient-number {
            font-size: 55px;
            width: 136px;
            height: 136px;
            border-radius: 166px;

            div {
                padding-top: 26px;
            }
        }

        .ni-title {
            font-size: $subHeaderLgs;
        }

        .ni-desc {
            font-size: $subContentLgs;
        }
        .cs-hl-ni {
            img {
                max-width: 100%;
            }
        }
    }
    
    #riso-desktop-slides {
        .desktop-slide-items {
            padding: 0 5px;
        }
    }

    #talis-desktop-slides {
        .desktop-slide-items {
            .desktop-slide-image {
                min-height: 180px;
                max-height: 180px;
            }
            .desktop-slide-label {
                margin: 24px 0 0 0;
            }
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
   
    #riso-desktop-slides {
        .desktop-slide-items {
            padding: 0 5px;
        }
    }
    #talis-desktop-slides {
        .desktop-slide-items {
            .desktop-slide-image {
                min-height: 125px;
                max-height: 125px;
            }
            .desktop-slide-label {
                margin: 24px 0 0 0;
                font-size: 24px;
            }
        }
    }

    #spotsize-desktop-slides {
        .desktop-slide-items {
            &:last-child {
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            .spotsize-title {
                margin-top:0;
                padding-left: 0;
                font-size: 32px;
            }
        }
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    .cs-dev {
        .title {
            font-size: $mainHeaderLgs;
        }

        .sub-desc {
            font-size: $subContentLgs;
        }

        .lt-number {
            font-size: 35px;
            line-height: 60px;
            width: 65px;
        }

        .lt-gradient-number {
            font-size: 60px;
            width: 146px;
            height: 146px;
            border-radius: 166px;

            div {
                padding-top: 26px;
            }
        }

        .ni-title {
            font-size: $subHeaderLgs;
        }

        .ni-desc {
            font-size: $subContentLgs;
        }
    }
}