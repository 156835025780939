@import 'common';

.service-page-content {
    width: 85%;
    margin: 0 auto;

    margin-top: 100px;
    margin-bottom: 100px;

    .service-page-content-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 130%;
        width: 90%;
        color: #FFFFFF;
    }

    .service-page-content-desc {
        margin-top: 50px;
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        width: 90%;
        color: #FFFFFF;
        p {
            width: 100% !important;
        }
    }
}

@media (max-width:640px) {
    .service-page-content {
        margin-top: 50px;
        margin-bottom: 30px;
        .service-page-content-title {
            width: 100%;
            font-size: $mainHeaderXs;
            // line-height: 50px;
        }
    
        .service-page-content-desc {
            font-size: $subContentXs;
            margin-top: 15px;
            width: 100%;
        }
    }
}


@media (max-width:640px)  {
    .service-page-content {
        width: 90%;
        
        .service-page-content-title {
            line-height: 1.5;
            font-weight: 500;
            font-size: $mainHeaderMobileMd;
        }

        .service-page-content-desc {
            line-height: 2;
            font-size: $subContentMobile;
        }
}
}

@media (min-width:641px) and (max-width:899px) {
    .service-page-content {
        margin-top: 100px;
        margin-bottom: 30px;
        .service-page-content-title {
            font-size: $mainHeaderSm;
            // line-height: 50px;
        }
    
        .service-page-content-desc {
            font-size: $subContentSm;
            margin-top: 15px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .service-page-content {
        margin-bottom: 50px;
        .service-page-content-title {
            font-size: $mainHeaderMd;
            // line-height: 60px;
        }
    
        .service-page-content-desc {
            margin-top: 25px;
            font-size: $subContentMd;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .service-page-content {
    
        margin-top: 80px;
        margin-bottom: 80px;
    
        .service-page-content-title {
            font-size: $mainHeaderLgs;
            // line-height: 130%;
        }
    
        .service-page-content-desc {
            margin-top: 35px;
            font-size: $subContentLgs;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .service-page-content {
    
        margin-top: 80px;
        margin-bottom: 80px;
    
        .service-page-content-title {
            font-size: $mainHeaderLgs;
            // line-height: 130%;
        }
    
        .service-page-content-desc {
            margin-top: 35px;
            font-size: $subContentLgs;
        }
    }
}