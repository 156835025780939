@import './common.scss';

$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

.root-contact {
    .career-contact-form {
        .custom-form-row-file {
            height: auto !important;
            padding-bottom: 15px;
        }
    }
}

.career-contact-form {
    background: linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100.83%);
    /* New glass */
    box-shadow: 0px 0px 10px rgba(33, 51, 220, 0.35);
    backdrop-filter: blur(4px);
    border-radius: 32px;
    padding: 40px;
    width: 100%;
    .MuiFormLabel-asterisk {
        color: #FF7900 !important;
    }

    .button {
        margin-top: 30px !important;
        background: $orangeColor !important;
        color: $whiteColor !important;
        border-radius: 8px !important;
        font-size: 24px !important;
        height: 66px;
        width: 249px;
        z-index: 1;
    }
    .button:hover {
        background: $orangeColor !important;
    }
    .ipt-field-common {
        width: 100%;
        color: #FFFFFF;
        height: 30px;
    }
    .custom-form-row {
        height: 80px;

        label {
            padding-left: 0px !important;
        }
    }
    .custom-form-row-file {
        height: auto !important;
        padding-bottom: 5px;

        .error-msg {
            margin-top: 0px !important;
        }
    }
    .attachment-icon {
        background: #FFFFFF;
        border-radius: 50%;
        width: 40px;
    }
    .lbl-attachment {
        font-size: 18px;
        line-height: 26px;
        /* White */
        color: #FFFFFF;
        padding-left: 15px;
    }

    .MuiInputBase-input {
        color: #FFFFFF !important;
    }
    #standard-basic-label {
        color: #FFFFFF !important;
    }
    #standard-basic-helper-text {
        font-size: 13px;
        font-weight: 500;
    }
    .MuiInputBase-root:before,
    .MuiInputBase-root:after {
        border-bottom: 1px solid #FFFFFF !important;
    }
    .o-color {
        color: #FF7900 !important
    }
    .w-color {
        color: #FFFFFF !important;
    }
    .contact-error-cont {
        width: 85%;
    }


}

.message-style {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2%;
    margin-left: 0px;
}


@media (min-width:300px) and (max-width:640px) {
    .contact-us {
        .contact-us-form {
            width: 100%;
            max-width: 440px;
            margin: 0 auto !important;
            .attachment-icon {
                background: $whiteColor !important;
                border-radius: 50% !important;
                width: 25px !important;
            }

        }
    }

    .career-contact-form {
        border-radius: 8px;
        padding: 24px 6%;
        box-sizing: border-box;
        width: 100%; 
        .custom-form-row {
            height: auto;
            display: block;
        }

        .ipt-field-common {
            height: 48px;
            margin-bottom: 30px;
        }

        .lbl-attachment {
            padding-left: 0;
            font-size: 13px;
            margin-top: 50px;
            margin-bottom: 30px;
        }

        .terms-content,
        .notify-content {
            font-size: 12px;
        }

        .button {
            height: 44px;
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 16px !important;
        }
    }

}

@media (min-width:641px) and (max-width:899px) {
    .career-contact-form {
        padding: 24px 6%;
        .custom-form-row {
            height: auto;
        }
        .lbl-attachment {
            font-size: 14px;
        }
        .terms-content,
        .notify-content {
            font-size: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .error-msg {
            font-size: 10px;
        }
        .ipt-field-common {
            height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .career-contact-form {
        .terms-content,
        .notify-content {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .career-contact-form {
        .button {
            font-size: $buttonTextMd !important;
            width: 125px;
            height: 40px;
        }

        .lbl-attachment {
            font-size: $subContentSm;
            padding-left: 5px;
        }

        .attchment-icon {
            width: 35px;
        }
        #standard-basic-helper-text {
            font-size: 10px;
        }
    }

    .w-color {
        font-size: $subContentSm;
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .career-contact-form {
        #standard-basic-helper-text {
            font-size: 10px;
        }

    }
}

@media (max-width: 900px) {
    .career-contact-form {
        #standard-basic-helper-text {
            font-size: 13px;
            font-weight: 500;
            margin-top: 0;
        }
    }
}