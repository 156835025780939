@import './common';

.cs-tech {

    margin-top: 64px;

    .cs-tech-title {
        font-weight: 700;
        font-size: $subContentLgss;
        line-height: 133%;
        color: $whiteColor;
    }

    .tech-logo-container {
        text-align: center;
        margin-bottom: 12px;
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-tech {
        .cs-tech-grid {
            column-gap: 60px !important;
        }

        .cs-tech-title {
            //case-study
            font-size: 16px;
        }
        .tech-logo-container {
            img {
                height: 26px;
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-tech {

        .cs-tech-title {
            font-size: $font-18;
        }
    }
}

@media (max-width: 640px) {
    .cs-tech {
        margin-top: 24px;

        .cs-tech-wrapper {
            column-gap: 0 !important;
        }
        
        .cs-tech-item {
            margin: 16px;


            .tech-logo-container {
                width: 30px;
                height: 30px;
                margin: 0 auto 8px;

                img {
                    width: 30px;
                    height: 30px;
                    max-width: 100%;
                }
            }
            .cs-tech-title {
                font-weight: 500;
                font-size: 13px !important;
            }
        }
    }
}