.about-offices {
    position: relative;
    left: 7%;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 93%;
    overflow: hidden;

    .office-desc {
        font-weight: 500;
        font-size: 42px;
        line-height: 120%;
        color: #FFFFFF;
        top: 40%;
        position: relative;
    }
    .office-desc1 {
        margin-bottom: 50px;
    }
    .world-style {
        width: 110%;
    }
}

@media (max-width: 900px) {
    .about-offices {
        left: 0;
        width: 100%;
        margin-bottom: 100px;
        margin-top: 80px;
    }
}