@import './common.scss';
.career-quotes {
    margin-top: 10%;
    margin-bottom: 10%;

    .career-quotes-title {
        font-weight: 700;
        font-size:$mainHeaderLg;
        // font-size: 70px;
        line-height: 130%;
        /* identical to box height, or 129% */


        color: #FFFFFF;
        margin-bottom: 6%;
    }

    .item-desc {
        font-size: $subHeaderLgs;
        // font-size: 40px;
        line-height: 150%;
        /* or 60px */


        color: #FFFFFF;
        // text-align: justify;

        .sub-title {
            font-weight: 600;
        }
    }

}

@media (min-width:300px) and (max-width:640px) {
    .career-quotes {
        .career-quotes-title {
            font-size: 28px;
        }

        .video-wrapper {
            border-radius: 32px;
            border: 4px solid #FFFFFF;
            background-color: transparent;

            video {
                border-radius: 32px;
            }

            .rh5v-DefaultPlayer_component  {
                background-color: transparent;
            }

            .rh5v-DefaultPlayer_controls {
                padding: 0 20px !important;
                border-radius: 0 0 32px 32px;
            }
        }

        .client-quotes {
            height: 50px;
        }

        .item-desc {
            font-size: 16px;

            br {
                display: none;
            }
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .career-quotes {
        .career-quotes-title {
            font-size: 32px;
        }

        .client-quotes {
            height: 50px;
        }

        .item-desc {
            font-size: 18px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .career-quotes {
        .career-quotes-title {
            font-size: 45px;
        }

        .client-quotes {
            height: 50px;
        }

        .item-desc {
            font-size: 22px;
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .career-quotes {
        .career-quotes-title {
            font-size: $mainHeaderLgs;
        }

        .item-desc {
            font-size: $subContentLgs;
        }
    }
}