.address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.dot {
  width: 100px;
  height: 100px;
  background: radial-gradient(50% 50% at 50% 50%,
      #ff7900 0%,
      rgba(28, 40, 152, 0.91) 27.08%,
      rgba(28, 38, 128, 0) 100%);
  border-radius: 50%;
  z-index: 1;
}

.dot.selected-location {
  position: absolute;
  top: -20px;
  left: 4%;
  transition: all 0.5s ease-in-out;
}

.dot.map-location {
  position: absolute;
  transition: all 1s ease-in-out;
  top: 22%;
  left: 17%;
}

.location-name {
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: #ff7900;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.location-flag {
  padding-left: 1%;
}

.location-address {
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-left: 10%;
  max-width: 40%;
}

.map {
  width: 500px;
  height: 500px;
  position: relative;
}

.tooltip-text {
  width: 197px;
  height: 32px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  text-align: center;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}


@media (min-width:300px) and (max-width:640px) {
  .location-wrapper {
    margin-top: 0 !important;
  }

  .location-name {
    font-size: 20px !important;
    margin-top: 0;
    display: block;
  }

  .location-flag {
    padding-left: 4%;
    width: 30px;
  }

  .dot.dot.selected-location {
    left: 0%;
    width: 70px;
    height: 70px;
  }

  .map {
    width: 90%;
    max-width: 300px;
    height: auto;
    margin: 40px auto 0;
  }

  .dot.map-location {
    position: absolute;
    transition: all 1s ease-in-out;
    top: 15%;
    left: 10%;
  }
}

/* Added by Kalai 05-08-23 */
@media (min-width:400px) and (max-width:640px) {

  /* Author Kalai-05-08-23 */
  .location-address {
    font-size: 13px;
    max-width: 66%;
    margin-top: 16px;
    margin-left: 0px;
  }


  .location-name {
    font-size: 16px;
    margin-top: 5px;
  }

  .dot.map-location {
    position: absolute;
    transition: all 1s ease-in-out;
    top: 15%;
    left: 10%;
  }
}

@media (min-width:641px) and (max-width:899px) {
  .location-name {
    font-size: 20px;
    margin-top: 10px;
  }

  .location-flag {
    padding-left: 4%;
    width: 30px;
  }

  .dot.dot.selected-location {
    left: 0%;
    width: 70px;
    height: 70px;
  }

  .map {
    width: 250px;
    height: 250px;
  }

  .dot.map-location {
    position: absolute;
    transition: all 1s ease-in-out;
    top: 16%;
    left: 8%;
  }

}

@media (min-width:900px) and (max-width: 1200px) {
  .location-name {
    font-size: 26px;
    margin-top: 10px;
  }

  .location-address {
    font-size: 22px;
    max-width: 70%;
  }

  .location-flag {
    padding-left: 4%;
    width: 30px;
  }

  .map {
    width: 280px;
    height: 280px;
  }

  .dot.map-location {
    position: absolute;
    transition: all 1s ease-in-out;
    top: 16%;
    left: 8%;
  }

}

@media (min-width:1201px) and (max-width: 1500px) {
  .location-name {
    margin-top: 10px;
    font-size: 26px;
    margin-bottom: 20px;
  }

  .location-address {
    max-width: 70%;
    font-size: 22px;
    line-height: 135%;
  }

  .dot.dot.selected-location {
    left: 2%;
  }
}

@media (min-width:1501px) and (max-width: 1600px) {
  .location-name {
    margin-top: 10px;
    font-size: 32px !important;
    margin-bottom: 20px;
  }

  .location-address {
    max-width: 50%;
    font-size: 27px !important;
    line-height: 135%;
  }

  .dot.dot.selected-location {
    left: 2%;
  }
}

@media (min-width:1900px) {
  .map {
    width: 730px;
    height: 730px;
  }

  .location-name {
    margin-bottom: 30px;
  }

  .location-address {
    max-width: 50%;
    line-height: 135%;
  }

  .dot.map-location {
    position: absolute;
    transition: all 1s ease-in-out;
    top: 27%;
    left: 20%;
  }
}

@media (max-width:900px) {

  .location-address {
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    max-width: 100%;
    margin-left: 0;
    overflow: hidden;
    text-align: left;
    display: block;
    white-space: normal !important;
  }
} 