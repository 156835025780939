$circleLeft: 370;
$circleTop: 20px;
$circleTextLeft: 375px;
$circleTextTop: 22px;

@mixin animate($animationName, $duration, $timingFunction, $delay, $noOfTimes) {
    animation: $animationName $duration $timingFunction $delay $noOfTimes;
}

@mixin keyframes() {
    @keyframes circle {
        from {
            transform: scale(0);
            opacity: 1;
        }

        to {
            transform: scale(1);
            opacity: 0;
        }
    }
}

.click-to-view {
    position: fixed;
    cursor: none;
    pointer-events: none;
}

.top-text {
    display: none;
}

.bottom-text {
    display: none;
}

.our-work:hover {
    border: inset 0.1px linear-gradient(to right, rgb(275, 275, 275, 0.5), rgb(275, 275, 275, 0.7));
    box-shadow: 2px -2px 13px 2px #153bf9;

    .circle1 {
        height: 140px;
        width: 140px;
        top: -70px;
        left:  -70px;
        position: absolute;
        border-radius: 50%;
    }

    .circle2 {
        height: 120px;
        width: 120px;
        top: -60px;
        left:  -60px;
        position: absolute;
        border-radius: 50%;
        background: radial-gradient(rgb(89, 156, 175, 0), rgba(94, 206, 237, 0.7), rgb(89, 156, 175, 0));
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    }


    .two {
        @include keyframes();
        @include animate(circle, 2s, linear, -1s, infinite);
        opacity: 0;
    }

    .three {
        @include keyframes();
        @include animate(circle, 2s, linear, -2s, infinite);
        opacity: 0;
    }

    .top-text {
        font-size: 15px;
        font-weight: 500;
        display: initial;
        position: absolute;
        color: white;
    }

    .top-left-rotate-position {
        top: -15px;
        left: -20.5px;
    }

    .bottom-text {
        font-size: 15px;
        font-weight: 500;
        display: initial;
        position: absolute;
        color: white;
        white-space: nowrap;
    }

    .bottom-right-rotate-position {
        top: 64px;
        left:  32px;
    }

    .bottom-left-rotate-position {
        top: 0px;
        left: -38px;
    }

    .right-rotate {
        transform: rotate(-35deg);
    }

    .left-rotate {
        transform: rotate(35deg);
    }
}