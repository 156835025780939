$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

.home-banner {
    padding-top: 5%;

    .banner-item {
        width: 77%;
        margin: 0 auto;
    }

    .carousel {
        .slider-wrapper {
            margin-bottom: 40px;
        }
    }

    .banner-title {
        color: #FFFFFF;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        //height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .IDEA-TEXT {
    //     color: #FF7900
    // }

    .banner-desc {
        color: #FFFFFF;
        font-weight: normal;
        font-size: 32px;
        padding-bottom: 0px;
        margin: 20px 10px 30px;
        line-height: 150%;
    }

    .button {
        background: $orangeColor;
        color: $whiteColor;
        border-radius: 8px;
        font-size: 24px;
        font-weight: 600;
        height: 58px;
        min-width: 237px;
        max-width: 337px;
    }

    button:hover {
        background: $orangeColor;
        box-shadow: 0 0 2px 2px $highlightColor;
    }


}
.carousel-root:not(.en)
{
  .carousel {
    .slider-wrapper {
        margin-bottom: 0px !important;
    }
}
}

.down-arrow {
    color: $whiteColor;
    margin-bottom: 5px;
    margin-top: 84px;
    text-align: center;

    img {
        height: 76px;
    }

    a {
        height: 100px;
        display: block;
    }
}

.down-arrow-img {
    position: relative;
    animation: mymove 0.8s infinite;
    animation-direction: alternate;
}

@keyframes mymove {
    from {
        top: 0px;
    }

    to {
        top: 25px;
    }
}

.banner-bg {
    background-image: url('../assets/Home/banner-bg11.png');
    mix-blend-mode: lighten;

}

@media (max-width: 640px) {
    .home-banner {
        width: 90%;
        padding-top: 0;
        margin: 51px auto 0;

        .banner-item {
            width: 100%;
        }

        .banner-title {
            font-size: 32px;
            //height: 150px;
        }

        .banner-desc {
            font-size: 16px;
            margin: 24px 0px 16px;
            line-height: 1.4;
        }

        .button {
            width: auto !important;
            padding: 12px 24px !important;
            margin-top: 25px !important;
            font-size: 16px !important;
            height: 44px !important;
        }

        .down-arrow {
            margin-top: 20px;
        }

        .carousel {
            padding-bottom: 180px;

            .control-dots {
                padding: 60px 0 100px;
            }
        }
    }

    .stack {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .home-partners {
        width: 90%;
        margin: 0 auto;
        column-gap: 0;
        justify-content: space-around;

        .MuiGrid-item:nth-child(n + 6) {
            display: none;
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .home-banner {
        padding-top: 0%;

        .banner-title {
            font-size: 32px;
        }

        .banner-desc {
            font-size: 16px;
            margin: 20px 0px 40px;
        }

        .button {
            font-size: 12px;
            height: 32px;
            width: 120px;
        }

        .down-arrow {
            margin-top: 20px;
        }

    }

    .down-arrow {
        a {
            height: 54px;
        }

        img {
            height: 34px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .home-banner {
        padding-top: 0%;

        .banner-title {
            font-size: 40px;
        }

        .banner-desc {
            margin: 20px 10px 45px;
            font-size: 20px;
        }

        .button {
            font-size: 14px;
            height: 38px;
            width: 150px;
        }

        .down-arrow {
            margin-top: 40px;

            a {
                height: 76px;

                img {
                    height: 50px;
                }
            }
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .home-banner {
        padding-top: 3%;
    }

    .about-banner {
        .down-arrow {
            margin-top: 35px;
        }
    }

    .career-banner {
        .down-arrow {
            margin-top: 40px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .home-banner {
        .banner-title {
            font-size: 50px;
        }

        .banner-desc {
            font-size: 24px;
        }

        .button {
            font-size: 16px;
            height: 40px;
            min-width: 170px;
            max-width: 200px;
        }


        .down-arrow {
            margin-top: 30px;

            a {
                img {
                    height: 60px;
                }
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .root-home {
        .home-banner {
          padding-top: 3%;
            .down-arrow {
                 margin-top: 20px;
                margin-bottom: 10px;

                a {
                    img {
                        height: 80px;
                    }
                }
            }

            // .banner-desc {
            //     margin-bottom: 70px;
            // }
        }
    }
}
@media (min-width:1601px) and (max-width: 2000px) {
  .home-banner {
    padding-top: 3%;
  }
}

.falling-comet-wrapper {
    position: relative;
}

.falling-comet {
    position: absolute;
    height: 28.585307585734462px;
    width: 463.0265118510293px;
    border-radius: 0px;
    top: 50%;
    left: 50%;
    background: radial-gradient(93.97% 97.41% at 4% 51.13%, #A3A3A3 5%, #919191 8%, #5C5C5C 15%, #484848 18%, #414141 20%, #292929 30%, #171717 40%, #090909 52%, #020202 68%, #000000 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    mix-blend-mode: color-dodge;
    transform: rotate(-45deg);
    animation: animate 1s linear infinite;
    visibility: hidden;
}

@keyframes animate {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: .5;
    }

    25% {
        opacity: .5;
    }

    50% {
        opacity: 0;
    }

    100% {
        transform: rotate(315deg) translateX(-1000px);
        opacity: 0;
        visibility: visible;
    }
}

.falling-comet:nth-child(1) {
    top: 200px;
    right: -50px;
    left: initial;
    animation-duration: 4s;
    animation-delay: .1s
}

.falling-comet:nth-child(2) {
    top: 200px;
    right: 250px;
    left: initial;
    animation-duration: 4s;
    animation-delay: 0.75s;
}

.falling-comet:nth-child(3) {
    top: 100px;
    right: 400px;
    left: initial;
    animation-duration: 4s;
    animation-delay: .3s
}

.falling-comet:nth-child(4) {
    top: 400px;
    right: initial;
    left: 200px;
    animation-duration: 4s;
    animation-delay: 0.75s;
}

.falling-comet:nth-child(5) {
    top: 100px;
    right: initial;
    left: 40px;
    animation-duration: 4s;
    animation-delay: .2s
}

.falling-comet:nth-child(6) {
    top: 60px;
    right: initial;
    left: 300px;
    animation-duration: 4s;
    animation-delay: 0.75s;
}