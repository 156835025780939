@import '../css/common';

.dynamic-html {
    padding-bottom: 150px;

    h2 {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: $orangeColor;
        margin-bottom: 64px;
    }
    h3 {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: $orangeColor;
        margin-bottom: 64px;
    }

    p {
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
    }

}

.cs-billers {
    .dynamic-html {
        padding-bottom: 0px;
        p {
            color: #FFFDFD;
            font-size: 32px;
            font-family: Neue Haas Grotesk Display Pro;
            font-weight: 500;
            line-height: 180%;
        }
    }
}

.cs-billers-html {
    .dynamic-html {
        p {
            font-weight: 400;
        }
    }
}


.carousel-container {
    position: relative;
    .carousel-container-swipeable-views {
        .carousel-container-image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            max-width: 268px;
            min-width: 268px;
            min-height: 160px;
            margin: 0 auto;
            .carousel-container-image {
                display: inline-block;
                width: auto;
                height: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    .carousel-items-wrapper {
        display: flex;
        justify-content: center;
        text-align: center;
        .carousel-item-name {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }
    .carousel-container-stepper {
        background: transparent;
        width: 116px;
        margin: 0 auto;
        padding: 0;
        .carousel-container-nav {
            background: transparent;
            border: 1px solid transparent;
            outline: none;
            cursor: pointer;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            &.disabled {
                pointer-events: none;
                opacity: 0.7;
                .carousel-prev-arrow, 
                .carousel-next-arrow {
                    border-color: #6B6E80 !important;
                }
            }
        }
        .MuiMobileStepper-dots {
            display: none;
        }
    }

}

@media (max-width: 640px) {
    .cs-testimonial + .cs-highlights + .dynamic-html {
        overflow: visible;
        padding-bottom: 40px !important;
        .columns {
            width: 400%;

            .column {
                width: 100%;
            }
        }

        &+ .dynamic-html p {
            margin-bottom: 0 !important;
        }

    }

    .biller-wrapper {
        width: 100%;
    }

    // /case-study/6
    .cs-major-tasks + .cs-highlights + .dynamic-html {
        overflow: visible;

        .columns {
            flex-wrap: wrap;

            .column {
                flex-basis: 100% !important;
                max-width: 100% !important;
                width: 100% !important;
                margin-bottom: 20px;
            }
        }
    }

    .dynamic-html {
        padding-bottom: 0;
    
        h2 {
            margin: 0 0 30px;
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            line-height: 1.5;
        }

        
        h3 {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 30px;
        }

        img {
            max-width: 100%;
        }

        .columns {
            flex-wrap: wrap;

            .column {
                width: 90% !important;
                max-width: 360px !important;
                flex-basis: 360px !important;

                img {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }

        .MuiGrid-container {
            .MuiGrid-grid-md-12 {
                overflow: auto !important;

                img {
                    width: 700% !important;
                }
            }
        }
    }
}
@media (min-width:1201px) and (max-width: 1500px) {
    .dynamic-html {

        p {
            font-size: $font-28;
        }
        h2{
            font-size: $subContentLg;
        }
        h3 {
            font-size: $subContentLg;
        }
    }
    .cs-billers {
        .dynamic-html {
            p{
                font-size: $subContentLgs;
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .dynamic-html {

        p {
            font-size: $subContentLgs;
        }
        h2{
            font-size: $mainHeaderLgs;
        }
        h3 {
            font-size: $mainHeaderLgs;
        }
    }
    .cs-billers {
        .dynamic-html {
            p{
                font-size: $font-28;
            }
        }
    }
}

.sl-container {
    display: none;
}

@media (max-width: 900px) {
    
    .sl-container {
        display: block;
        margin: 0 auto;
        position: relative;
        width: 100%;
        height: 485px;
        padding-top: 24px;
        padding-bottom: 50px;
        user-select: none;
        border-radius: 8px;
        background: transparent;
        box-sizing: border-box;
        input {
            display: none;
        }
        .slider-image {
            position: absolute;
            width: 100%;
            height: 302px;
            z-index: -1;
            display: flex;
            align-items: center;
            max-width: 400px;
            left: 0;
            right: 0;
            margin: auto;
            img {
                width: 100%;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
            .prev, .next {
                width: 44px;
                height: 44px;
                position: absolute;
                bottom: -78px;
                background-color:transparent;
                border: 1px solid #fff;
                border-radius: 50%;
                z-index: 99;
                cursor: pointer;
                text-align: center;
                background-repeat: no-repeat;
                background-position: center;
                outline: none !important;
                transition: none;
                &.on-touch,
                &.active-arrow,
                &:active,
                &:focus,
                &:hover {
                    background-color: #FF7900;
                    border: 1px solid #FF7900;
                }
                &.disabled-label {
                    opacity: 0.9;
                    border-color: #6B6E80 !important;
                    &:active,
                    &:focus,
                    &:hover {
                        background-color: transparent;
                    }
                }
                &.on-touch.disabled-label {
                    background-color: transparent;
                }
            }
            .next {
                right: 50%;
                transform: translateX(58px);
                background-image: url('../assets/arrow-next.svg');
                background-repeat: no-repeat;
            }
            .prev {
                left: 50%;
                transform: translateX(-58px);
                background-image: url('../assets/arrow-previous.svg');
                background-repeat: no-repeat;
            }
            .prev:focus-within, 
            .next:focus-within,
            .prev:target, 
            .next:target,
            .prev:focus-visible, 
            .next:focus-visible {
                background-color: yellowgreen;
                border: 1px solid #fff;
            }
            .prev:visited, 
            .next:visited {
                background-color: white;
                border: 1px solid red;
            }
           
            .slide-name {
                position: absolute;
                bottom: -4px;
                color: #ffffff;
                left: 0;
                display: block;
                right: 0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 133%; /* 23.94px */
            }
        }
    
        #sl-slide-nav {
            width: 100%;
            bottom: 30px;
            height: 11px;
            position: absolute;
            text-align: center;
            z-index: 99;
            cursor: default;
            display: none; // hide temporary
            .dots {
                top: -5px;
                width: 18px;
                height: 18px;
                margin: 0 3px;
                position: relative;
                border-radius: 100%;
                display: inline-block;
                background-color: #fff;
                transition: .4s;
                cursor: pointer;
            }
            #dot1:hover,
            #dot2:hover,
            #dot3:hover,
            #dot4:hover {background: #FF7900;}
        }

        &#wireframes {
            height: 725px;
            padding-bottom: 15px;
            .slider-image {
                height: 560px;
                display: flex;
                justify-content: center;
                .prev, .next {
                    bottom: -120px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
            }
        }
        &#delivered-solutions {
            height: 725px;
            padding-bottom: 15px;
            .slider-image {
                height: 560px;
                display: flex;
                justify-content: center;
                .prev, .next {
                    bottom: -120px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
            }
        }
        &#doctors-website {
            height: 725px;
            padding-bottom: 15px;
            .slider-image {
                height: 560px;
                display: flex;
                justify-content: center;
                .prev, .next {
                    bottom: -120px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
            }
        }
        &#togomed {
            height: 725px;
            padding-bottom: 15px;
            .slider-image {
                height: 560px;
                display: flex;
                justify-content: center;
                .prev, .next {
                    bottom: -120px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
            }
        }
        &#riso {
            height: 725px;
            padding-bottom: 15px;
            .slider-image {
                height: 560px;
                display: flex;
                justify-content: center;
                .prev, .next {
                    bottom: -128px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
                .slide-name {
                    position: absolute;
                    bottom: -55px;
                }
            }
        }
        &#togomed {
            .slider-image {
                .prev, .next {
                    bottom: -100px;
                }
                img {
                    width: auto;
                    height: auto;
                    display: inline-block;
                }
                .slide-name {
                    position: absolute;
                    bottom: -28px;
                }
            }
        }
    }
    #i1 ~ #slide-one,
    #i2 ~ #slide-two,
    #i3 ~ #slide-three,
    #i4 ~ #slide-four {
        opacity: 0;
    }
    #i1:checked ~ #slide-one,
    #i2:checked ~ #slide-two,
    #i3:checked ~ #slide-three,
    #i4:checked ~ #slide-four {
        opacity: 1;
        z-index: 9;
        animation: scroll 1s ease-in-out;
    }
    #i1:checked ~ #sl-slide-nav #dot1,
    #i2:checked ~ #sl-slide-nav #dot2,
    #i3:checked ~ #sl-slide-nav #dot3,
    #i4:checked ~ #sl-slide-nav #dot4 {background: #FF7900;}

    #ds-i1 ~ #ds-slide-one,
    #ds-i2 ~ #ds-slide-two,
    #ds-i3 ~ #ds-slide-three,
    #ds-i4 ~ #ds-slide-four {
        opacity: 0;
    }
    #ds-i1:checked ~ #ds-slide-one,
    #ds-i2:checked ~ #ds-slide-two,
    #ds-i3:checked ~ #ds-slide-three,
    #ds-i4:checked ~ #ds-slide-four {
        opacity: 1;
        z-index: 9;
        animation: scroll 1s ease-in-out;
    }
    #ds-i1:checked ~ #ds-slide-nav #dot1,
    #ds-i2:checked ~ #ds-slide-nav #dot2,
    #ds-i3:checked ~ #ds-slide-nav #dot3,
    #ds-i4:checked ~ #ds-slide-nav #dot4 {background: #FF7900;}
    

    #wfi1 ~ #wfslide-one,
    #wfi2 ~ #wfslide-two,
    #wfi3 ~ #wfslide-three,
    #wfi4 ~ #wfslide-four {
        opacity: 0;
    }
    #wfi1:checked ~ #wfslide-one,
    #wfi2:checked ~ #wfslide-two,
    #wfi3:checked ~ #wfslide-three,
    #wfi4:checked ~ #wfslide-four {
        opacity: 1;
        z-index: 9;
        animation: scroll 1s ease-in-out;
    }
    #wfi1:checked ~ #wfslide-nav #wfdot1,
    #wfi2:checked ~ #wfslide-nav #wfdot2,
    #wfi3:checked ~ #wfslide-nav #wfdot3,
    #wfi4:checked ~ #wfslide-nav #wfdot4 {background: #FF7900;}

    
}
