#page404 {
    margin: 0 auto;
    margin-top: 5%;
}

#pageInner404 {
  margin: 0 auto;
  margin-top: 10%;
}

.error-404-svg {
    background-size: cover;
    z-index: -1;
}


.page404-title {
    color: #FFF;
    text-align: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
}

.page404-status-code {
    color: #FFF;
    text-align: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
}

.page404-description {
    text-align: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #FFFFFF;
}

.page404-button {
    background: #FF7900 !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    height: 58px !important;
    width: 297px !important;
}

.page404-button:hover {
    background: #FF7900 !important;
    border-radius: 8px !important;
    box-shadow: 0 0 2px 2px #be9663 !important;
    color: #FFFFFF !important;
    font-size: 24px !important;
    font-weight: 600 !important;
}

@media (max-width: 640px) {
    #page404 {
        max-width: 80%;
        margin: 0 auto;
        padding-top: 60px;
    }

    .title-wrapper {
        padding-bottom: 70px !important;
    }

    .page404-status-code {
        font-size: 64px;
        font-weight: 600;
    }

    .page404-title {
        font-size: 24px;
        font-weight: 600;
    }

    .page404-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        padding-bottom: 30px;
        text-align: center;
    }

    .page404-button {
        width: auto !important;
        height: 44px !important;
        padding: 0 20px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
    }
}