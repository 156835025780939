@import './common.scss';

.about-employees {
    width: 85%;
    margin: 0 auto;

    .about-emp-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 90px;
        color: #FFFFFF;
    }

    .about-client-container {
        position: relative;
        padding: 30px;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        transition: top ease 0.2s;
        top: 0px;
    }

    .hover-effect {
        top: -25px;
    }

    .about-client-profile-img {
        width: 350px;
        z-index: 1;
        position: relative;
        display: block;
    }

    .about-client-profile-effect {
        height: 350px;
        margin-bottom: 27px;
        margin-left: -3px;
        position: absolute;
        bottom: 0%;
        width: 350px;
        border-radius: 32px;
    }

    .about-emp-main {
        margin-top: 4.412%;
    }

    .about-client-name {
        font-weight: 500;
        font-size: $subContentLg;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        padding-right: 45px;
    }

    .client-summary-details {
        background: linear-gradient(90deg, rgba(28, 40, 152, 0.85) 0%, rgba(28, 40, 152, 0.85) 100.83%);
        box-shadow: 0px 0px 10px rgba(33, 51, 220, 0.35);
        backdrop-filter: blur(4px);
        border-radius: 32px;
        position: absolute;
        top: 75%;
        z-index: 2;
        left: -10%;
        padding: 40px;
        width: 95%;
        .client-position {
            font-weight: 600;
            font-size: $subContentLg;
            line-height: 120%;
            text-align: center;
            color: #FF7900;
            mix-blend-mode: normal;
        }

        .client-location {
            font-weight: 600;
            font-size: 20px;
            line-height: 250%;
            text-align: center;
            color: #FFFFFF;
        }

        .client-summary {
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
            font-feature-settings: 'ss02' on;
            color: #FFFFFF;
            margin-top: 4.25%;
        }
    }
    .carousel-container {
        position: relative;
        .carousel-container-swipeable-views {
            .carousel-container-image-wrapper {
                text-align: center;
                max-width: 142px;
                min-width: auto;
                min-height: fit-content;
                margin: 0 auto 18px;
                .carousel-container-image {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
        .carousel-profile-wrapper {
            display: flex;
            justify-content: center;
            text-align: center;
            .carousel-profile-name {
                color: #FF7900;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
            }
            .carousel-profile-position {
                font-family: Neue Haas Grotesk Display Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                color: #ffffff;
            }
        }
        .carousel-profile-summary {
            min-height: 150px;
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 24px;
            margin-bottom: 12px;
            padding-left: 15px;
            padding-right: 15px;
        }
        .carousel-container-stepper {
            background: transparent;
            width: 116px;
            margin: 0 auto;
            padding: 0;
            .carousel-container-nav {
                background: transparent;
                border: 1px solid transparent;
                outline: none;
                cursor: pointer;
                width: 44px;
                height: 44px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                   background: #FF7900; 
                   border-color: #FF7900;    
                }
                &.disabled {
                    pointer-events: none;
                    opacity: 0.7;
                    .carousel-prev-arrow, 
                    .carousel-next-arrow {
                        border-color: #6B6E80 !important;
                    }
                }

            }
            .MuiMobileStepper-dots {
                display: none;
            }
        }
        .line-break {
            display: block;
            margin-bottom: 12px;
        }
        .line-break:last-child {
            margin-bottom: 0;
        }
    }

}



@media (max-width:900px) {

    .about-emp-main {
        margin-top: 0;
        padding: 24px 0px;
        border-radius: 8px;
        background: #353B5F;
    } 
}

@media (max-width:400px) {
    .about-client-profile-effect {
        height: 120px !important;
    }
}

@media (min-width:300px) and (max-width:600px) {
    .about-employees {
        width: 90%;
        
        .about-emp-title {
            font-size: 24px;
            line-height: 1.6;
            padding-top: 40px;
            padding-bottom: 10px;
        }

        .about-client-name {
            font-size: $subContentXs;
            padding-right: 0px;
        }

        .about-client-profile-img,
        .about-client-profile-effect,
        .client-summary-details,
        .about-client-name {
            width: 100px;
        }

        .about-client-profile-img {
            z-index: 1;
            position: relative;
            display: block;
        }

        .about-client-profile-effect {
            border-radius: 12px;
            height: 100px;
        }

        .client-summary-details {
            padding: 10px;
            border-radius: 12px;
            top: 55%;
            left: -10px;

            .client-position {
                font-size: $subContentXs;
            }

            .client-location {
                font-size: 10px;
            }

            .client-summary {
                font-size: 12px;
            }
        }

        .employee-card-wrapper {
            width: 100%;
    
            .employee-card {
                padding: 20px;
                border-radius: 8px;
                box-sizing: border-box;
                background-color: #353B5F;
    
                .MuiGrid-container {
                    display: block;
    
                    .image-wrapper {
                        max-width: 60%;
                        width: 130px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
    
            .about-client-name {
                width: 100%;
                color: #FF7900;
                font-weight: 500;
                font-size: $subHeaderXs;
                margin-bottom: 5px;
                text-align: center;
            }

            .client-summary-details {
                display: block !important;
                width: 100%;
                font-size: 16px;
                background: transparent;
                position: static;
                box-sizing: border-box;

                .client-position {
                    color: #fff;
                    font-size: 16px;
                }

                .client-location {
                    display: none;
                }

                .client-summary {
                    font-size: 16px;
                    line-height: 1.7;
                }
            }
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .about-employees {
        .about-emp-title {
            font-size: $mainHeaderSm;
        }

        .about-client-name {
            font-size: $subContentSm;
        }

        .about-client-profile-img {
            width: 130px;
            z-index: 1;
            position: relative;
            display: block;
        }

        .about-client-container {
            padding: 27.5px;
            padding-left: 0px;
            padding-top: 0px;
        }

        .about-client-profile-effect {
            border-radius: 16px;
            height: 130px;
            width: 130px;
        }

        .client-summary-details {
            width: 130px;
            padding: 20px;
            border-radius: 16px;
            top: 60%;
            left: -20px;

            .client-position {
                font-size: $subContentSm;
            }

            .client-location {
                font-size: 10px;
            }

            .client-summary {
                font-size: 11px;
            }
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .about-employees {
        .about-emp-title {
            font-size: $mainHeaderMd;
        }

        .about-client-name {
            font-size: $subContentMd;
        }

        .about-client-profile-img {
            width: 190px;
            z-index: 1;
            position: relative;
            display: block;
        }

        .about-client-profile-effect {
            height: 190px;
            width: 190px;
        }

        .client-summary-details {
            width: 190px;
            padding: 30px;
            top: 70%;
            left: -30px;

            .client-position {
                font-size: $subContentMd;
            }

            .client-location {
                font-size: 14px;
            }

            .client-summary {
                font-size: 16px;
            }
        }
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .about-employees {
        .about-emp-title {
            font-size: $mainHeaderLgs;
        }

        .about-client-name {
            font-size: $subContentLgs;
        }

        .about-client-profile-img {
            width: 250px;
            z-index: 1;
            position: relative;
            display: block;
        }

        .about-client-profile-effect {
            height: 250px;
            width: 250px;
        }

        .client-summary-details {
            width: 250px; // it should qual to about-client-profile-effect width
            padding: 30px;
            top: 70%;
            left: -30px;

            .client-position {
                font-size: $subContentLgs;
            }
            .client-location {
                font-size: 18px;
            }
            .client-summary {
                font-size: 22px;
            }
        }
    }

    @media (min-width:1500px) and (max-width: 1600px) {
        .about-employees {
            .about-client-profile-effect {
                height: 265px;
            }

            .about-client-profile-img,
            .client-summary-details,
            .about-client-profile-effect {
                width: 265px;
            }
        }
    }

}