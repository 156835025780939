@import './common.scss';

.story-card-container {
    position: relative;
    width: 100%;
}

.story-card-left {
    background: linear-gradient(to right, #1C2D7D, rgba(29, 32, 59, 0.5));
    padding: 5px;
    border-radius: 32px;
}

.story-card-right {
    background: linear-gradient(to left, #1C2D7D, rgba(29, 32, 59, 0.5));
    padding: 5px;
    border-radius: 32px;

}

.story-card {
    //background-image: linear-gradient(to right, rgba(29, 32, 59, 1), rgba(29, 32, 59, 0));
    // background: linear-gradient(#1D203B 0%, rgba(29, 32, 59, 0.7) 100%);

    background: linear-gradient(90deg, #1D203B 0%, rgba(29, 32, 59, 0.50) 100.83%);
    // border: 4px solid #1C2D7D;

    border-radius: 32px;
    // box-shadow: 0 0 2px 2px #142BA4;
    padding: 56px 56px 56px 56px;
    position: relative;
}

// .story-text-left-container {
//     padding-left: 70px;
// }

// .story-text-right-container {
//     padding-right: 70px;
// }

.story-text-left-container,
.story-text-right-container {
    flex-flow: column !important;
    row-gap: $subContentLg;
}


.story-right-image {
    box-shadow: 50px -30px 100px 0px #142ba4;
}

.story-left-image {
    box-shadow: 50px -30px 100px 0px #142ba4;
}

.story-read-time {
    color: #FFFFFF;
    font-size: 20px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.story-title {
    font-size: $mainHeaderLg;
    font-weight: 600;
    color: #FFFFFF;
    width: 80%;
}

.story-date {
    font-size: 32px;
    //font-weight: 500;
    color: #FFFFFF;
    padding-bottom: 3%;
}

.story-description {
    font-size: $subContentLg;
    //font-weight: 500;
    color: #FFFFFF;

    span {
        p {
            margin: 0px !important;
        }
    }
}

.story-image-grid {
    align-items: center;
}

.story-image {
    // width: 100%;
    height: max-content;
}

.story-date-seperator {
    color: #FF7900;
}

.story-button {
    background: #FF7900 !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    font-size: $buttonTextLg !important;
    //font-weight: 600 !important;
    height: 66px !important;
    min-width: 249px !important;
    max-width: 270px !important;
}

.story-button:hover {
    box-shadow: 0 0 2px 2px #be9663;
}

.story-image-bg-gardient {
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 50%;
    height: 150%;
    top: -25%;
    // z-index: 1;
}

.story-image-bg-gardient-right {
    right: -8%;
}

.story-image-bg-gardient-left {
    left: -8%;
}

.story-image-main {
    z-index: 1;
    position: relative;
    justify-content: space-between;
}

@media (min-width:1501px) and (max-width: 1600px) {
    .story-card {
        border-radius: 32px - (0.375*32);
        padding: 56px - (0.375*56);
    }

    // .story-text-left-container {
    //     padding-left: 70px - (0.375*70);
    // }

    // .story-text-right-container {
    //     padding-right: 70px - (0.375*70);
    // }


    .story-right-image {
        box-shadow: (50px - (0.375*50)) (-30px - (0.375*30)) (100px - (0.375*100)) 0px #142ba4;
    }

    .story-left-image {
        box-shadow: (50px - (0.375*50)) (-30px - (0.375*30)) (100px - (0.375*100)) 0px #142ba4;
    }

    .story-title {
        font-size: $mainHeaderLgs;
    }

    .story-date {
        font-size: 32px - (0.375*32);
    }

    .story-description {
        font-size: $subContentLgs;
    }

    .story-button {
        font-size: $buttonTextLgs !important;
        height: 66px - (0.375*66) !important;
        min-width: 249px - (0.375*249) !important;
        max-width: 270px - (0.375*249) !important;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .story-card {
        border-radius: 32px - (0.53125*32);
        padding: 56px - (0.53125*56);
    }

    // .story-text-left-container {
    //     padding-left: 70px - (0.53125*70);
    // }

    // .story-text-right-container {
    //     padding-right: 70px - (0.53125*70);
    // }


    .story-right-image {
        box-shadow: (50px - (0.53125*50)) (-30px - (0.53125*30)) (100px - (0.53125*100)) 0px #142ba4;
    }

    .story-left-image {
        box-shadow: (50px - (0.53125*50)) (-30px - (0.53125*30)) (100px - (0.53125*100)) 0px #142ba4;
    }

    .story-title {
        font-size: $mainHeaderMd;
    }

    .story-date {
        font-size: 32px - (0.53125*32);
    }

    .story-description {
        font-size: $subContentMd;
    }

    .story-button {
        border-radius: 6px !important;
        font-size: $buttonTextMd !important;
        height: 66px - (0.53125*66) !important;
        min-width: 249px - (0.53125*249) !important;
        max-width: 279px - (0.53125*249) !important;
    }
}

@media (max-width: 640px) {

    .story-card-container {
        width: 90%;
        margin: 20px auto;
    }

    .story-card-right, .story-card-left {
        padding: 0;
        background-color: transparent;
    }

    .story-image-main {
        flex-flow: initial !important;
        flex-wrap: wrap !important;
        row-gap: 25px !important;
        display: grid !important;
    }

    .story-card {
        border-radius: 32px - (0.66*32);
        padding: 56px - (0.66*56);
        box-shadow: none;
        background: linear-gradient(to right, rgb(37, 43, 94) 50%, rgb(35, 44, 123));
    }

    .story-image-grid {
        order: 1;
    }

    .story-text-container {
        order: 2;
        row-gap: 0 !important;
    }

    .story-image {
        max-width: 100%;
        border-radius: 33px - (0.66*32);
        height: auto;
    }

    .story-text-left-container,
    .story-text-right-container {
        row-gap: 10px;
    }

    .story-right-image {
        box-shadow: (50px - (0.66*50)) (-30px - (0.66*30)) (100px - (0.66*100)) 0px #142ba4;
    }

    .story-left-image {
        box-shadow: (50px - (0.66*50)) (-30px - (0.66*30)) (100px - (0.66*100)) 0px #142ba4;
    }

    .story-read-time {
        font-size: 11px;
        letter-spacing: 2px;
        padding-bottom: 20px;
    }

    .story-title {
        width: 100%;
        font-weight: 600;
        line-height: 1.5;
        padding-bottom: 20px;
        font-size: $mainHeaderSm;
    }

    .story-date {
        font-size: 32px - (0.66*32);
    }

    .story-description {
        line-height: 1.7;
        font-size: $subContentSm;
        padding-bottom: 20px;
    }

    .story-button {
        border-radius: 6px !important;
        font-size: $buttonTextMd !important;
        height: 44px !important;
        min-width: 140px !important;
        max-width: 170px !important;
        margin: 10px auto !important;
        display: block !important;
    }

    .story-image-bg-gardient {
        display: none;
    }
}