.career-contact {
    position: relative;
    min-height: 900px;
    margin-top: 5%;
}

.root-career {
    .career-contact {
        backdrop-filter: blur(250px);
    }
}

.career-contact {
    .contact-container {
        position: relative;
        width: 100%;
        padding-top: 100px;
    }

    .contact-form-container {
        position: absolute;
        top: 60px;
        right: 70px;
        width: 40%;
    }
}
   
@media (max-width: 640px) {    
    .career-contact {
        .contact-container {
            width: 90%;
            padding-top: 70px;

            margin: 0 auto;
            position: static;
        }

        .contact-form-container {
            position: static;
            width: 90%;
        }
    }
}


@media (min-width:641px) and (max-width:899px) {
    .career-contact {
        min-height: 1050px;

        .contact-form-container {
            width: 50%;
        }
    }
}

@media (max-width: 900px) {
    .career-contact {
        .contact-form-container {
            margin: 0 auto 56px;
        }
    }
} 

@media (min-width:900px) and (max-width: 1200px) {
    .career-contact {
        min-height: 830px;

        .contact-form-container {
            width: 50%;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .career-contact {
        min-height: 880px;

        .contact-form-container {
            width: 45%;
        }
    }
}