@import './common.scss';

.about-found {
    margin-top: 9.17% !important;
    width: 85%;
    margin: 0 auto;
}

.about-found-main {
    //font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    color: #FFFFFF;
    // margin-top: 4.412%;
    margin-bottom: 11%;
}

.about-found-title {
    font-weight: 700;
    font-size: $mainHeaderLg;
    line-height: 90px;
    /* identical to box height, or 129% */


    /* Theme/White/Default */

    color: #FFFFFF;
}

.about-client-container {
    position: relative;
    padding: 30px;
    padding-left: 0px;
    padding-top: 50px;
}

.about-client-profile-img {
    width: 286px;
    z-index: 1;
    position: relative;
    display: block;
}

.about-client-profile-effect {
    height: 282px;
    margin-bottom: 27px;
    margin-left: -3px;
    position: absolute;
    bottom: 0%;
    width: 289px;
    border-radius: 32px;
}

.founder-name {
    font-weight: 500;
    font-size: $subHeaderLg;
    line-height: 133%;
    color: #FFFFFF;
}

.founder-position {
    font-weight: 500;
    font-size: $sub2HeaderLg;
    line-height: 133%;
    margin-bottom: 28px;
    color: #FF7900;
}

.founder-desc {
    font-style: normal;
    font-size: $subContentLg;
    line-height: 150%;
    padding-bottom: 16px;
    color: #FFFFFF;
}

.lblock-right {
    position: relative;
}

.founder-lblock-grid-ryt {
    display: flex;
    align-items: flex-end;
}

.rblock-left {
    top: 2%;
    text-align: right;
    position: relative;
    right: 60px;
    //font-weight: 450;
}

.founder-lblock {
    position: relative;
}

@media (max-width:640px) {
    .about-client-profile-effect {
        border: none;
    }
    
    .about-found {
        width: 90%;
        margin: 100px auto 0 !important;
    }

    .about-found-main {
        margin: 0;
    }
    .about-found-title {
        font-weight: 500;
        font-size: $mainHeaderMobileMd;
    }

    .founder-lblock {
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #353B5F;

        .MuiGrid-container {
            display: block;

            .image-wrapper {
                max-width: 60%;
                width: 130px;
                display: block;
                margin: 0 auto;
            }
        }
    }
    
    .rblock-left, .lblock-right {
        position: static;
    }

    .about-found-main {
        font-size: 20px;
    }
    

    .about-client-container {
        position: relative;
        margin: 0 auto;
        padding: 0;
        width: 130px !important;
        max-width: 200px;
        margin-bottom: 20px;
    }
    .about-client-profile-effect {
        width: 130px;
        height: 80px;
        border-radius: 12px;
        margin: 0;
    }

    .about-client-profile-img {
        width: 130px !important;
    }

    .founder-name {
        color: #FF7900;
        font-weight: 500;
        font-size: $subHeaderXs;
        margin-bottom: 5px;
        text-align: center;
    }

    .founder-position {
        color: #fff;
        font-weight: 500;
        font-size: $sub2HeaderXs;
        margin-bottom: 30px;
        text-align: center;
    }

    .founder-desc {
        font-size: 14px;
        padding-bottom: 10px;
        line-height: 1.6;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .lblock-right {
        left: 25px;
    }

    .rblock-left {
        right: 40px;
    }

    .about-found-title {
        font-size: $mainHeaderSm;
    }

    .about-client-profile-effect {
        width: 125px;
        height: 125px;
        border-radius: 16px;
    }

    .about-found-main {
        font-size: 20px;
    }

    .about-client-profile-img {
        width: 125px;
    }

    .founder-name {
        font-size: $subHeaderSm;
    }

    .founder-position {
        font-size: $sub2HeaderSm;
        margin-bottom: 15px;
    }

    .founder-desc {
        font-size: $subContentSm;
        padding-bottom: 10px;
        line-height: 130%;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .lblock-right {
        left: 25px;
    }

    .about-found-title {
        font-size: $mainHeaderMd;
    }

    .about-client-profile-effect {
        width: 160px;
        height: 160px;
        border-radius: 16px;
    }

    .about-found-main {
        font-size: 26px;
    }

    .about-client-profile-img {
        width: 160px;
    }

    .founder-name {
        // font-weight: 500;
        font-size: $subHeaderMd;
        //margin-bottom: 15px;
    }

    .founder-position {
        // font-weight: 600;
        font-size: $sub2HeaderMd;
        margin-bottom: 18px;
    }

    .founder-desc {
        font-size: $subContentMd;
        padding-bottom: 10px;
        line-height: 130%;
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .lblock-right {
        // top: 20%;
    }

    .about-found-title {
        font-size: $mainHeaderLgs;
    }

    .about-client-profile-effect {
        width: 250px;
        height: 250px;
    }

    .about-found-main {
        font-size: 32px;
    }

    .about-client-profile-img {
        width: 250px;
    }

    .founder-name {
        font-size: $subHeaderLgs;
    }

    .founder-position {
        font-size: $sub2HeaderLgs;
        margin-bottom: 24px;
    }

    .founder-desc {
        font-size: $subContentLgs;
        padding-bottom: 18px;
    }
    .founder-lblock-grid {
        column-gap: 40px;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .about-client-profile-effect {
        width: 230px;
        height: 230px;
    }

    .about-client-profile-img {
        width: 230px;
    }
    .founder-lblock-grid {
        column-gap: 40px;
    }
}

@media (min-width:1501px) and (max-width: 1800px) {
    .lblock-right {
        // top: 35%;
    }
}