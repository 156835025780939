@import './common.scss';

.job-card {
    background: linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100.83%);
    /* NEW for cases */

    box-shadow: 0px 0px 15px rgba(0, 59, 232, 0.75);
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 32px;
    padding: 50px;
}

.job-card-position {
    font-weight: 500;
    font-size: 42px;
    line-height: 120%;
    /* or 50px */


    color: #FF7900;
    // height: 70px;
    margin-bottom: 15px;
}

.job-category {

    background: #FFFFFF !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 150%;
    padding: 20px 8px !important;
    border-radius: 50px !important;
    margin-right: 25px;

}

.btn-view-job:hover {
    border-color: #D9D9D9 !important;
    background-color: #FF7900 !important;

}
.job-card-content {
    height: 120px;
}
.btn-view-job {
    margin-top: 65px !important;
    font-size: 24px !important;
    border: 2px solid #FF7900 !important;
    border-radius: 8px !important;
}

@media (max-width: 640px) {
    .career-jobs-content > .MuiGrid-container {
        row-gap: 24px !important;
    }

    .job-card-wrapper {
        width: 100%;
    }
    
    .job-card {
        width: 100%;
        padding: 24px;
        border-radius: 8px;
        
        box-shadow: none;
        box-sizing: border-box;
        
        background: linear-gradient(to right, #1C2898 50%, #1E2994 80%);
    }

    .job-card-position {
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 15px;
    }

    .job-card-content {
        height: auto;
        min-height: 68px;
    }

    .job-category {
        font-weight: 500 !important;
        font-size: 11px !important;
        line-height: 24px;
        height: 24px;
        margin-right: 15px;
        padding: 0 12px !important;
    }

    .btn-view-job {
        font-weight: 400 !important;
        margin-top: 25px !important;
        font-size: 16px !important;
        // border: 2px solid #FF7900 !important;
        border-radius: 8px !important;
        background: #FF7900 !important;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .job-card-position {
        font-size: 16px;
    }
    .job-category {
        font-size: 10px !important;
        padding: 0px 4px !important;
        margin-right: 12px;
    }
    .btn-view-job {
        margin-top: 0px !important;
        font-size: 12px !important;
    }

    .job-card {
        padding: 30px;
    }

}
@media (min-width:900px) and (max-width: 1200px) {
    .job-card-position {
        font-size: 28px;
    }

}


@media (min-width:1200px) and (max-width: 1500px) {
    .job-card-position {
        font-size: 36px;
    }

}