@import './common';

.cs-solution-main-no-list-data {
    padding-bottom: 0px !important;
}
.cs-solution {
    // padding: 170px 0px;
    padding-bottom: 100px; // 30 t0 170 ; bug-20(19)-cs-lightpont


    .lt-gradient {
        background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
        width: 166px;
        height: 166px;
    }
    
    .title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        /* or 48px */


        color: $orangeColor;

        margin-bottom: 48px;

    }


    .sub-desc {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 57px */

        color: $whiteColor;
        margin-bottom: 64px;

        h3 {
            color: $orangeColor;
            /* Header 1 */
            font-size: $mainHeaderLg;
            font-weight: 700;
            line-height: 133%;
        }
    }
    .cs-solution-no-list-data {
        // margin-bottom: 0px !important;
    }

    .val {
        font-weight: 700;
        font-size: 72px;
        line-height: 130%;
        /* identical to box height, or 94px */

        text-align: center;

        color: $whiteColor;

    }

    .val-text {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 48px */

        text-align: center;

        color: $whiteColor;

        p {
            padding: 0px;
            margin: 0px;
        }
    }

    .val-radient {
        margin: 0 auto;
        position: relative;
        width: 400px;
        height: 400px;
        background: radial-gradient(50% 50% at 50% 50%, #1C2898 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
    }

    .val-container {
        position: absolute;
        top: 20%;
    }
}

@media (max-width: 640px) {
    .cs-highlights + .cs-solution {
        padding-top: 30px !important;
    }
    .cs-solution {
        padding-top: 50px;
        padding-bottom: 40px;

        .title {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
            line-height: 150%;
            margin-bottom: 30px;
        }

        .sub-desc {
            font-size: $subContentMobile;
            margin-bottom: 15px;

            p {margin: 0;
            padding-bottom: 10px;}
        }

        .task-wrapper {
            margin-bottom: 20px;
            flex-wrap: nowrap !important;
            align-items: flex-start !important;
        }
        
        .lt-gradient {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 18px;
            background: radial-gradient(50% 50.00% at 50% 50.00%, #FF7900 0%, rgba(28, 40, 152, 0.91) 40.08%, rgba(28, 38, 128, 0.00) 100%);
        }

        .gradient-wrapper {
            position: relative;
            height: 300px;
            margin: 10px auto;
        }

        .val-container {
            top: 30%;
            left: 40px;
            max-width: 90%;
            width: 220px;
            height: 220px;
        }

        .val-radient {
            max-width: 90%;
            width: 300px;
            height: 300px;
        }
        
        .val {
            font-size: $mainHeaderLgs;
        }

        .val-text {
            font-size: $subContentMobile;
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-solution {
        padding-bottom: 70px; // 0 t0 170 ; bug-20(19)-cs-lightpont
        .lt-gradient {
            width: 136px;
            height: 136px;
        }
        
        .title {
            font-size: $subHeaderLgs;
            margin-bottom: 48px;
    
        }
    
        .sub-desc {
            font-size: $subContentLgss;
            margin-bottom: 36px;
            // margin-bottom: 64px;
    
            h3 {
                font-size: $subContentLg;
            }
        }
    
        .val {
            font-size: 40px;
        }

        .val-text {
            //case-study
            font-size: 24px;
            // font-size: $subContentLgs;
        }

        .val-radient {
            width: 280px;
            height: 280px;
        }
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    .cs-solution {
        padding-bottom: 70px; // 0 t0 170 ; bug-20(19)-cs-lightpont
        .lt-gradient {
            width: 166px;
            height: 166px;
        }
        
        .title {
            font-size: $mainHeaderLgs;
            margin-bottom: 48px;
    
        }
    
        .sub-desc {
            font-size: $font-28;
            margin-bottom: 64px;
    
            h3 {
                font-size: $mainHeaderLgs;
            }
        }
    
        .val {
            font-size: 45px;
        }
    
        .val-text {
            font-size: $subContentLgs;
        }
    
        .val-radient {
            width: 300px;
            height: 300px;
        }
    }
}