body {
    font-family: 'Neue Haas Grotesk Display Pro' !important;
    font-style: normal;
    background-image: radial-gradient(circle at center,
            rgba(275, 275, 275, 0.1) 2px,
            transparent 0),
        linear-gradient(to right, rgba(24, 22, 43, 1), rgb(28, 43, 79));
    background-repeat: repeat, repeat;
    background-size: 20px 20px, cover;
}

html {
    scroll-behavior: smooth;
    overflow: overlay;
}

.cursor {
    cursor: pointer;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.relative {
    position: relative;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.skiy-main {
    max-width: 100%;
    overflow: hidden;
    /*background-color: #161A42;*/
}

#basic-menu .MuiPaper-root {
    padding: 0px 20px;
    background-color: #162E54;
}

.locale-disable {
    color: #6B6E80 !important;
}

.locale-active {
    color: #FFFFFF !important;
}

.top-bg {
    width: 100%;
    top: 0;
    padding-top: 135px;
    padding-bottom: 8%;
    position: relative;
}

.top-bg.galaxy-comet {
    background-image: url("./assets/Home/home-bg-image.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.align-center {
    text-align: center;
}

.bold-500 {
    font-weight: 500 !important;
}

.carousel .control-dots .dot {
    margin: 0px 35px !important;
    width: 12px !important;
    height: 12px !important;
}

.carousel .control-dots .dot.selected {
    background: #FF7900 !important;
}

.o-color {
    color: #FF7900 !important
}


.sk-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 11px solid #f3f3f3;
    border-top: 11px solid #1C2787;
    border-radius: 50%;
    width: 85px;
    height: 85px;
    animation: spin 2s linear infinite;
    opacity: 1;
    display: none;
}
.sk-loader.show-spinner {
    display: block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width:640px) {
    /* body {
        background-image: linear-gradient(to right, rgba(24, 22, 43, 1) 50%, rgb(28, 43, 79));
        background-repeat: repeat, repeat;
        background-size: cover;
    } */
    body {
      background-image: radial-gradient(circle at center,
      rgba(275, 275, 275, 0.1) 1px,
      transparent 0),
  linear-gradient(to right, rgba(24, 22, 43, 1), rgb(28, 43, 79));
    }

    .top-bg {
        padding-top: 80px;
    }

    .carousel .control-dots .dot {
        margin: 0px 14px !important;
        width: 6px !important;
        height: 6px !important;
    }

    .top-bg.galaxy-comet {
        background-size: 800px 780px;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .top-bg {
        padding-top: 100px;
    }

    .carousel .control-dots .dot {
        margin: 0px 16px !important;
        width: 6px !important;
        height: 6px !important;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .top-bg {
        padding-top: 130px;
    }

    .carousel .control-dots .dot {
        margin: 0px 26px !important;
        width: 8px !important;
        height: 8px !important;
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .carousel .control-dots .dot {
        width: 10px !important;
        height: 10px !important;
    }
}

.text-center {
    text-align: center;
    display: flex;
}

.app-footer-main {
    background: radial-gradient(50% 50.00% at 50% 50.00%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0.00) 100%);
}

.strong {
    font-weight: 500;
}
.w-100 {
    width: 100%;
}

@media (max-width:900px) {
    .app-footer-main {
        background: transparent;
    }
    .top-bg {
        padding-bottom: 0;
    }
}