@import './common.scss';

.blog-banner {
    width: 85%;
    margin: 0 auto;
    padding-top: 13%;
    // padding-bottom: 6%;

    .blog-banner-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 150%;
        color: #FFFFFF;
    }

    .c-title {
        color: #FF7900 !important;
    }

    .blog-banner-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        padding-top: 36px;
        color: #FFFFFF;
        width: 80%;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .blog-banner-title {
        font-size: $mainHeaderLgs !important;
    }
    .blog-banner-desc {
        font-size: $subContentLgs !important;
        padding-top: 28px !important;
    }
}
@media (min-width:900px) and (max-width: 1200px) {
    .blog-banner-title {
        font-size: $mainHeaderMd !important;
    }
    .blog-banner-desc {
        font-size: $subContentMd !important;
        padding-top: 24px !important;
    }
}
@media (min-width:641px) and (max-width:899px) {
    .blog-banner-title {
        font-size: $mainHeaderSm !important;
    }
    .blog-banner-desc {
        font-size: $subContentSm !important;
        padding-top: 18px !important;
    }
}
@media (max-width:640px) {
    .blog-banner {
        width: 90%;
        padding-top: 17%;
        margin: 71px auto 0;

        .blog-banner-title {
            font-size: $mainHeaderMobileLg
        }

        .blog-banner-desc {
            width: 90%;
            max-width: 480px;
            font-weight: 300;
            font-size: $subContentMobile;
            padding-top: 18px !important;
        }
    }
}

