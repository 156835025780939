@import './common.scss';
.career-banner {
    width: 85%;
    margin: 0 auto;
    .career-banner-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 150%;
        color: #FFFFFF;
    }

    .c-title {
        color: #FF7900 !important;
    }

    .career-banner-desc {
        font-weight: 400;
        font-size: $subHeaderLgs;
        line-height: 150%;
        padding-top: 15px;
        color: #FFFFFF;
        height: 170px;
        width: 85%;
    }

    .btn-see-pos {
        font-weight: 600;
        font-size: $buttonTextLg;
        line-height: 108%;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #FF7900;
        border-radius: 8px;
        min-width: 220px;
        max-width: 480px;
        height: 66px;
    }

    .btn-see-pos:hover {
        background: #FF7900;
        border: 1px solid #FFFFFF
    }

    .btn-talk {
        font-weight: 600;
        font-size: $buttonTextLg !important;
        line-height: 108%;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        border: 2px solid #FF7900;
        border-radius: 8px;
        min-width: 220px;
        max-width: 285px;
        height: 66px;
        margin-left: 4%;
    }

    .btn-talk:hover {
        border-color: #D9D9D9 !important;
        background-color: #FF7900 !important;

    }
}

@media (max-width: 640px) {
    .career-banner {
        width: 90%;
        padding-bottom: 100px;

        .career-banner-title {
            font-size: $mainHeaderMobileLg;
            font-weight: 600;
        }

        .career-banner-desc {
            width: 100%;
            height: auto;
            margin-bottom: 40px;
            font-size: $subContentMobile;
        }

        .btn-talk, .btn-see-pos {
            font-size: $subContentMobile !important;
            min-width: 220px;
            max-width: 252px;
            height: 44px;
            margin-left: 0;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .career-banner {
        .career-banner-title {
            font-size: 45px;
        }

        .career-banner-desc {
            font-size: 26px;
            height: 200px;
        }
        .btn-talk {
            font-size: 18px !important;
            font-size: 20px;
            min-width: 200px;
            min-width: 220px;
            height: 55px;
        }
        .btn-see-pos {
            font-size: 20px;
            min-width: 200px;
            max-width: 222px;
            height: 55px;
        }
    }
}

@media (min-width:901px) and (max-width: 1200px) {
    .career-banner {
        .career-banner-title {
            font-size: 50px;
        }

        .career-banner-desc {
            font-size: 30px;
        }
        
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .career-banner {
        .career-banner-title {
            font-size: $mainHeaderLgs;
        }

        .career-banner-desc {
            height: 130px;
            font-size: $subContentLgs;
        }
        .btn-talk {
            font-size: medium !important;
            min-width: 180px;
            max-width: 200px;
            height: 50px;
        }
        .btn-see-pos {
            font-size: medium !important;
            min-width: 180px;
            max-width: 260px;
            height: 50px;
        }
    }
}
@media (min-width:1501px) and (max-width: 1800px) {
    .career-banner {
        .career-banner-title {
            font-size: $mainHeaderLgs;
        }

        .career-banner-desc {
            font-size: $subContentLgs;
        }
        .btn-talk {
            font-size: medium !important;
            min-width: 180px;
            max-width: 200px;
            height: 50px;
        }
        .btn-see-pos {
            font-size: medium !important;
            min-width: 180px;
            max-width: 260px;
            height: 50px;
        }
    }
}