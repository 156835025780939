@import './common';

.cs-banner {
    .cs-banner-title {
        font-weight: 700;
        font-size: $font-35;
        line-height: 133%;
        color: $orangeColor;
        margin-bottom: 16px;
        width: 100%;
    }

    .cs-banner-desc {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;

        color: $whiteColor;
        margin-bottom: 64px;
        width: 100%;
    }

    .our-work-tags-stack {
        margin-right: 20px;
    }
    .cs-banner-left {
        // padding-top: 80px;
    }
    .cs-image {
      max-width: 100%;
  }
}

@media (max-width:640px) {
    .cs-banner {
        .cs-banner-title {
            font-weight: 600;
            color: #fff;
            font-size: $mainHeaderSm;
        }

        .cs-banner-desc {
            color: #FF7900;
            font-weight: 600;
            font-size: $mainHeaderMobileLg;
            margin-bottom: 24px;
        }

       .cs-image-wrapper {
        display: none;
       }
    }
}
@media (min-width:900px) and (max-width: 1200px) {
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-banner {
        .cs-banner-title {
            font-weight: 700;
            font-size: $sub2HeaderLgs;
            line-height: 133%;
            color: $orangeColor;
            margin-bottom: 16px;
            width: 100%;
        }
    
        .cs-banner-desc {
            font-weight: 700;
            font-size: $subHeaderLgs;
            line-height: 133%;
            color: $whiteColor;
            margin-bottom: 64px;
            width: 100%;
        }
    
        .our-work-tags-stack {
            margin-right: 20px - (0.375*20);
        }
        .cs-image {
            max-width: 80%;
        }
    }
   
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-banner {
        .cs-banner-title {
            font-weight: 700;
            font-size: $subHeaderLgs;
            line-height: 133%;
            color: $orangeColor;
            margin-bottom: 16px;
            width: 100%;
        }
    
        .cs-banner-desc {
            font-weight: 700;
            font-size: $mainHeaderLgs;
            line-height: 133%;
            color: $whiteColor;
            margin-bottom: 64px;
            width: 100%;
        }
    
        .our-work-tags-stack {
            margin-right: 20px - (0.375*20);
        }

        .cs-image {
            max-width: 80%;
        }
    }
}