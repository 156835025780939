@import './common.scss';

.about-focus {
    margin: 0px auto;
    width:85%;
    margin-top: 10%;
}

.about-focus:after {
    content: '';
    display: block;
    clear: both;
}

.focus-content{
    font-size: $mainHeaderLg;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 80px;
}

.focus-title-container {
    margin-bottom: 90px;
}

.focus-title-container {
    display: inline-flex;
    align-items: flex-start;
    .right{
        text-align: right !important;
        justify-content: flex-end;
    }
    .left {
        text-align: left !important;
        justify-content: flex-start;
    }
    
}

.focus-title-first-letter{
    font-size: $subHeaderLg;
    font-weight: 600;
    color: #FF7900;
}

.focus-title{
    font-size: $subHeaderLg;
    font-weight: 600;
    color: #FF7900;
}

.focus-desc {
    font-size: $subContentLg;
    color: #FFFFFF;
    line-height: 150%;
}

.focus-title-large-letter{
    height: 200px;
    font-size: 200px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 85%;
    position: relative;
}

.letter-f{
    width: 54% !important;
    float: left;
}

.letter-o{
    width: 54% !important;
    float: right;
}

.letter-c{
    width: 54% !important;
    float: left;
}

.letter-u{
    width: 54% !important;
    float: right;
}

.letter-s{
    width: 54% !important;
    float: left;
}

@media (min-width:1200px) and (max-width: 1500px) {
    
    .focus-content{
        font-size: $mainHeaderLgs;
        margin-bottom: 100px  - (0.375*100);
    }
    
    .focus-title-first-letter{
        font-size: $subHeaderLgs;
    }
    .focus-title-container {
        margin-bottom: 25px - (0.375*25);
    }
    .focus-title-large-letter{
        height: 157px;
        font-size: 157px;
    }
    .focus-title-container {
        
        .right{
            text-align: right !important;
            justify-content: flex-end;
        }
        .left {
            text-align: left !important;
            justify-content: flex-start;
        }
        
    }
    
    
    .focus-title{
        font-size: $subHeaderLgs;
    }
    
    .focus-desc {
        font-size: $subContentLgs;
    }
    
    .focus-title-large-letter{
        font-size: 200px - (0.375*200);
    }

    .letter-o{
        width: 53% !important;
        float: right;
    }
    
    .letter-u{
        width: 53% !important;
        float: right;
    }
}
@media (min-width:900px) and (max-width: 1200px) {

    
    .focus-content{
        font-size: $mainHeaderMd;
        margin-bottom: 100px  - (0.53125*100);
    }
    
    .focus-title-first-letter{
        font-size: $subHeaderMd;
    }
    .focus-title-container {
        margin-bottom: 32px;
    }
    
    .focus-title-container {
        .right{
            text-align: right !important;
            justify-content: flex-end;
        }
        .left {
            text-align: left !important;
            justify-content: flex-start;
        }
        
    }
    
    
    .focus-title{
        font-size: $subHeaderMd;
    }
    
    .focus-desc {
        font-size: $subContentMd;
    }
    
    .focus-title-large-letter{
        line-height: 85%;
        font-size: 200px - (0.53125*200);
        height: 200px - (0.53125*200);
    }

    .letter-o{
        width: 53% !important;
        float: right;
    }
    
    .letter-u{
        width: 53% !important;
        float: right;
    }
}
@media (min-width:641px) and (max-width: 899px) {
    
    .focus-content{
        font-size: $mainHeaderSm;
        margin-bottom: 100px  - (0.66*100);
    }
    
    .focus-title-first-letter{
        font-size: $subHeaderSm;
    }
    .focus-title-container {
        margin-bottom: 25px;
    }
    
    .focus-title-container {
        .right{
            text-align: right !important;
            justify-content: flex-end;
        }
        .left {
            text-align: left !important;
            justify-content: flex-start;
        }
        
    }
    
    
    .focus-title{
        font-size: $subHeaderSm;
    }
    
    .focus-desc {
        font-size: $subContentSm;
    }
    
    .focus-title-large-letter{
        line-height: 90%;
        font-size: 200px - (0.66*200);
        height: 200px - (0.66*150);
    }

    .letter-o{
        width: 53% !important;
        float: right;
    }
    
    .letter-u{
        width: 53% !important;
        float: right;
    }
}

@media (max-width:640px) {
    .about-focus {
        width: 90%;
        word-wrap: normal !important;
        margin: 40px auto 0;
    }

    .focus-content{
        font-size: $mainHeaderMobileMd;
        margin-bottom: 30px;
    }
    
    .focus-title-container {
        margin-bottom: 40px;
        flex-flow: row !important;

        .focus-content-wrapper {
            order: 2 !important;
        }

        .right{
            text-align: left;
            justify-content: flex-start;
        }

        .left {
            justify-content: flex-start;
        }
        
    }
    
    
    .focus-title{
        text-align: left;
        font-size: $mainHeaderSm;
    }
    
    
    .focus-title-first-letter{
        font-size: $mainHeaderSm;
    }
    
    .focus-desc {
        text-align: left;
        font-size: $subContentMobile;
    }
    
    .focus-title-large-letter{
        line-height: 1;
        font-size: 70px;
        height: 80px;
        line-height: 90%;
        order: 1 !important;
        position: static !important;
        margin-left: 0 !important;
        margin-right: 10% !important;
    }

    .letter-f, .letter-o, .letter-c, .letter-u, .letter-s {
        width: 100% !important;
        float: none !important;
    }
}
