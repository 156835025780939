@import 'common';

.root-services {
    .home-clients {
        padding-top: 3%;
        padding-bottom: 0%;
    }
}

.arrow-disable {
    border-color: #6B6E80 !important;
}

.home-clients {
    padding-top: 0%;
    width: 85%;
    margin: 0 auto;
    color: #FFFFFF;
    .client-partners {
        position: relative;
        .grad-container {
            position: absolute;
        }
    }

    .header {
        font-size: $mainHeaderLg;
        font-weight: 700;
        margin-bottom: 5.362%;

    }

    .desc {
        font-weight: 450;
        font-size: 40px;
        padding-left: 30%;
    }

    .title {
        font-weight: 500;
        font-size: 28px;
        margin-top: 20px;
    }

    .main-content {
        margin-top: 5%;
    }
    .profile {
        z-index: 1;
        max-width: 100%;
    }
}

.career-employees-say,
.client-banner, .home-clients {
    padding-top: 5%;
    .carousel {
        .slider-wrapper {
            margin-bottom: 40px;
        }
    }
    .banner-desc-container {
        position: relative;
        height: 100%;
        align-content: space-around;
        row-gap: 50px;
    }
    .banner-desc {
        font-weight: 400;
        font-size: $subContentLg;
        text-align: left;
        width: 85%;
        line-height: 150%;
        color: #FFFFFF;
    }
    .banner-author-main {
        text-align: right;
        width: 100%;
        .banner-author-name {
            font-weight: 700;
            font-size: $subContentLg;
            color: #FFFFFF;
        }
    }
    .banner-author-details {
        font-size: $subContentLg;
        line-height: 150%;
        color: #FFFFFF;
    }
    .client-container {
        position: relative;
        padding: 30px;
    }
    .client-profile-img {
        width: 289px;
        height: auto;
        z-index: 1;
        position: relative;
        display: block;
    }
    .client-profile-effect {
        height: 350px;
        margin-bottom: 27px;
        margin-left: -3px;
        position: absolute;
        bottom: 0%;
        width: 356px;
        background: linear-gradient(90deg, #42466C 0%, rgba(49, 56, 113, 0) 100.83%);
        border: 3px solid #2F416F;
        filter: drop-shadow(0px 0px 15px rgba(0, 59, 232, 0.75));
        backdrop-filter: blur(4px);
        border-radius: 32px;
    }
}

.career-employees-say {
    .slide {
        overflow: hidden;
        .client-container {
            width: 142px;
            height: 178px;
            img {
                width: 100%;
            }
        }
    }

}


.client-banner-left-arrow {
    width: 40px;
    height: 40px;
    border: 6px solid #FF7900;
    border-bottom: 0px;
    border-left: 0px;
    border-radius: 4px;
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
}

.client-banner-right-arrow {
    width: 40px;
    height: 40px;
    border: 6px solid #FF7900;
    border-top: 0px;
    border-right: 0px;
    border-radius: 4px;
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
}

.client-quotes {
    height: 95px;
}

.client-quotes-main {
    position: absolute;
    left: -62px;
    top: -40px;
}
#cs-page {
    .client-quotes-main{
        display: none;
    }
}
 
.client-banner-main {
    padding-top: 8%;
    height: 100%;
}

.focus-gradient {
    height: 230px;
    width: 230px;
    background: radial-gradient(35% 35% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
}

.focus-gradient.selected-focus {
    position: absolute;
    left: 0%;
    transition: all 0.5s ease-in-out;
}

@media (max-width:640px) {
    .root-services {
        .home-clients {
            padding-top: 0
        }
    }

    .career-employees-say,
    .home-clients {
        width: 90%;
        .header {
            font-size: $mainHeaderMobileMd;
            margin-bottom: 10%;
            margin-top: 50px;
        }
        .banner-item {
            margin-top: 0;
        }
        .banner-container {
            justify-content: center;
        }
        .client-desc-container {
            margin: 0 auto;
            width: 90%;
            .non-active-logo {
                display: none;
            }
            .client-partners {
                margin-bottom: 0%;
                border: 1px solid white !important;
                .client-img-tag {
                    width: 100%;
                }
            }
        }

    }

    .career-employees-say,
    .client-banner {
        padding: 0 0 20px 0;
        .slider-wrapper {
            margin-bottom: 0 !important;
            .slider {
                max-height: max-content;
                .client-profile-img {
                    width: 130px;
                    height: auto;
                    &.careers-profile-image {
                        width: 100%;
                    }
                }
            }
        }


        .client-profile-effect {
            height: 130px;
            width: 136px;
            border-radius: 14px;
        }
        .banner-desc-container {
            row-gap: 0;
        }
        .banner-desc {
            font-size: $subHeaderXs;
            width: 100%;
            padding-bottom: 30px;
        }
        .client-quotes-main {
            width: 40px;
            height: 40px;
            left: 0px;
            top: -20px;

            .client-quotes {
                height: 40px;
            }
        }
    }

    .client-banner-main {
        padding-top: 40px;
    }
    .client-partners {
        row-gap: 30px !important;
        grid-gap: 10px !important;
        justify-content: space-around;
        .clients-item {
            margin: 0px 10px 40px 10px;
            height: 40px;
            max-width: 90px;
            .client-img-tag {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .client-banner-left-arrow {
        top: 80px !important;
        left: 5px !important;
        width: 25px !important;
        height: 25px !important;
        border-width: 2px;
    }

    .client-banner-right-arrow {
        top: 80px !important;
        right: 5px !important;
        border-width: 2px;
        width: 25px !important;
        height: 25px !important;
    }

    .focus-gradient {
        display: none;
        height: 120px;
        width: 120px;
        top: -82px;
        left: -45px;
    }
}


@media (min-width:641px) and (max-width:899px) {
    .home-clients {
        .header {
            font-size: $mainHeaderSm;
        }
        .banner-item {
            margin-top: 60px;
        }
        .career-employees-say {
            margin: auto;
            padding: 0 0 20px 0;
        }
    }

    .client-banner-left-arrow {
        width: 25px !important;
        height: 25px !important;
    }

    .client-banner-right-arrow {
        width: 25px !important;
        height: 25px !important;
    }

    .focus-gradient {
        height: 120px;
        width: 120px;
        top: -82px;
        left: -45px;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .home-clients {
        .header {
            font-size: $mainHeaderMd;
        }
        .banner-item {
            margin-top: 60px;
        }
        .career-employees-say,
        .client-banner {
            .client-profile-img {
                width: 200px;
                height: auto;
            }
            .client-profile-effect {
                height: 200px;
                width: 206px;
                border-radius: 22px;
            }
            .banner-desc {
                font-size: $subContentMd;
            }

            .client-quotes-main {
                width: 55px;
                left: -42px;
                top: -54px;
            }
            .banner-author-main {
                .banner-author-name {
                    font-size: $subContentMd;
                }
            }
            .banner-author-details {
                font-size: $subContentMd;
            }
        }
    }

    .client-banner-left-arrow {
        width: 30px !important;
        height: 30px !important;
    }

    .client-banner-right-arrow {
        width: 30px !important;
        height: 30px !important;
    }

    .focus-gradient {
        height: 150px;
        width: 150px;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .home-clients {

        .header {
            font-size: $mainHeaderLgs;
        }
        .banner-item {
            margin-top: 60px;
        }
        .career-employees-say,
        .client-banner {
            .client-profile-img {
                width: 250px;
                height: auto;
            }

            .client-profile-effect {
                height: 250px;
                margin-bottom: 27px;
                width: 256px;
                border-radius: 22px;
            }

            .banner-desc {
                font-size: $subContentLgs;
            }
            .client-quotes-main {
                left: -42px;
                top: -28px;
                width: 70px;
            }
            .banner-author-main {
                .banner-author-name {
                    font-size: $subContentLgs;
                }
            }

            .banner-author-details {
                font-size: $subContentLgs;
            }
        }

        .focus-gradient {
            height: 200px;
            width: 200px;
        }
    }

    .client-banner-main {
        padding-top: 9%;
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .home-clients {
        padding-bottom: 0px;
        .header {
            font-size: $mainHeaderLgs;
        }
        .banner-item {
            margin-top: 60px;
        }
        .client-banner {
            .client-profile-img {
                width: 250px;
                height: auto;
            }
            .client-profile-effect {
                height: 250px;
                margin-bottom: 27px;
                width: 256px;
                border-radius: 22px;
            }
            .banner-desc {
                font-size: $subContentLgs;
            }
            .client-quotes-main {
                left: -42px;
                top: -28px;
                width: 70px;
            }

            .banner-author-main {
                .banner-author-name {
                    font-size: $subContentLgs;
                }
            }

            .banner-author-details {
                font-size: $subContentLgs;
            }
        }

        .focus-gradient {
            height: 200px;
            width: 200px;
        }
    }
}


@media (max-width:900px) {
        .client-banner-main,
        .career-employees-say {
            width: 100%;
            margin: auto;
            padding-top: 0;
            .banner-desc {
                font-size: 16px;
                padding-bottom: 16px;
                font-weight: 450;
            }
            .banner-container {
                column-gap: 0;
                flex-direction: column;
                align-items: center;
            }
            .client-desc-container {
                width: 100%;
                padding-left: 12px;
                padding-right: 12px;
            }    

            .client-banner-main {
                padding-top: 32px;
            }
            .banner-author-main {
                .banner-author-name {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }
                .banner-author-details {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 150%;
                }
            }
            .slide {
                .client-container {
                    padding: 0 !important;
                    margin-bottom: 16px;
                    padding: 0;
                    img {
                        width: 100%;
                    }
                    .client-quotes-main {
                        width: 40px;
                        height: 35px;
                        left: 0;
                    }
                }

                .client-profile-img {
                    max-height: 100%;
                }
            }
        }
    #cs-page {
        .client-quotes-main{
            display: block;
        }
        .client-content {
            line-height: 1.5;
            font-size: 16px;
        }
        .author-main {
            .author-name {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }
            .author-details {
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: 150%;
            }
        }
    }
}

@media (max-width:940px)and (orientation: landscape) {
    .root-career-content {
        .career-employees-say {
            .lbl-employee-say {
                margin-bottom: 130px;
            }
            .banner-desc {
                padding-bottom: 1px;
                width: 95%;
            }
            .banner-container {
                column-gap: 0;
                flex-direction: column;
                align-items: center;
            }
            .banner-desc-container {
                row-gap: 0;
            }
            .client-banner-main {
                padding-top: 5px;
            }
            .client-quotes-main {
                top: -20px;
            }
            .banner-author-main {
                .banner-author-name {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }
                .banner-author-details {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 150%;
                }
            }
            .client-desc-container {
                padding-left: 0;
                padding-right: 0;
                max-width: 85%;
            }
            .slide {
                overflow: hidden;
                .client-container {
                    padding: 0;
                    margin-bottom: 16px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .client-banner-left-arrow, .client-banner-right-arrow  {
            top: calc(30% - 15px) !important;
            border-width: 2px;
        }
    }

}