@import './common';

.root-cs {
  width: 85%;
  margin: 0 auto;
  padding-top: $topLg;

  .billers {
    color: $whiteColor;
  }

  #pageInner404 {
    margin-top: -$topLg;
    padding-top: 0;
  }

  .service-page-list-items,
  #service-faq,
  .contact {
    width: 100%;
  }

  .compare-image {
    div[data-rcs="root"] {
      border-radius: 32px !important;
    }

    .compare-image-desktop {
      display: block;
      margin-bottom: 40px;
    }

    .compare-image-mobile {
      display: none;
    }
  }
}

@media (max-width: 640px) {
  .root-cs {
    width: 90%;
    margin: 0 auto;
    padding-top: 127px;
  }
}

@media (min-width:1201px) and (max-width: 1500px) {
  .root-cs {
    padding-top: $topLg;
  }

  .compare-image {
    margin-bottom: 150px;
  }
}

@media (min-width:1501px) and (max-width: 1800px) {
  .root-cs {
    padding-top: $topLg;
  }

  .compare-image {
    margin: 170px 0px;
  }

}

@media (min-width:1900px) {
  .compare-image {
    margin-bottom: 170px;
  }
}

@media (max-width:900px) {
  .root-cs {
    .compare-image {
      .compare-image-desktop {
        display: none;
      }

      .compare-image-mobile {
        display: block;
        margin-bottom: 90px;

        .compare-image-wrapper {
          width: 100%;
          max-width: 328px;
          border-radius: 12px;
          border: 2px solid #FFF;
          overflow: hidden;
          display: flex;
          margin: auto;

          img {
            width: 100%;
          }
        }

        .compare-image-title {
          display: block;
          margin: 24px 0 32px 0;
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 133%;
          text-align: center;

          &:last-child {
            margin: 24px 0 0 0;
          }
        }
      }
    }
  }

}