@import 'common';

.service-page-banner {
    width: 85%;
    margin: 0 auto;
    padding-top: 30px;
    height: 560px;

    .service-page-banner-content {
        height: 55%;
    }

    .service-page-banner-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 150%;
        color: #FFFFFF;
    }

    .c-title {
        color: #FF7900 !important;
    }

    .c-text {
        color: $orangeColor;
    }

    .service-page-banner-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        color: #FFFFFF;
        width: 80%;
    }

    .down-arrow {
        text-align: center;
    }
}

@media (max-width: 640px) {
    .service-page-banner {
        padding-top: 0px;
        height: 225px;

        .service-page-banner-title {
            font-size: $mainHeaderXs;
        }

        .service-page-banner-desc {
            width: 90%;
            font-size: $subContentXs;
            padding-top: 20px;
        }

        .service-page-banner-content {
            height: 60%;
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-page-banner {
        padding-top: 0px;
        height: 310px;

        .service-page-banner-title {
            font-size: $mainHeaderSm;
        }

        .service-page-banner-desc {
            font-size: $subContentSm;
            padding-top: 20px;
        }

        .service-page-banner-content {
            height: 60%;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .service-page-banner {
        padding-top: 0px;
        height: 360px;

        .service-page-banner-title {
            font-size: $mainHeaderMd;
        }

        .service-page-banner-desc {
            font-size: $subContentMd;
            padding-top: 24px;
        }

        .service-page-banner-content {
            height: 60%;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .service-page-banner {
        padding-top: 0px;
        height: 420px;

        .service-page-banner-title {
            font-size: $mainHeaderLgs;
        }

        .service-page-banner-desc {
            font-size: $subContentLgs;
            padding-top: 0px;
        }

        .service-page-banner-content {
            height: 50%;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .service-page-banner {
        padding-top: 40px;
        height: 460px;
        .service-page-banner-title {
            font-size: $mainHeaderLgs;
        }

        .service-page-banner-desc {
            font-size: $subContentLgs;
        }

        .service-page-banner-content {
            height: 45%;
        }
    }
}

@media (max-width:640px) {
    .service-page-banner {
        width: 90%;
        padding-top: 0;
        padding-bottom: 100px;
        margin: 0 auto;
        height: auto;

        .service-page-banner-title {
            font-weight: 600;
            line-height: 1.3;
            font-size: $mainHeaderMobileLg;
        }

        .service-page-banner-desc {
            font-weight: 300;
            font-size: $subContentMobile;
            line-height: 2;
        }
    }
}