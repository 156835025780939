@import './common';

.cs-major-tasks {
    padding-bottom: 170px;
    .lt-gradient {
        background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
        width: 166px;
        height: 166px;
    }

    .title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: $orangeColor;
    }

    .desc {
        font-size: $subHeaderLg;
        line-height: 133%;
        color: $whiteColor;
        margin: 64px 0px;
    }

    .sub-desc {
        font-size: $subContentLg;
        line-height: 150%;
        /* or 57px */

font-weight: 350;
        color: $whiteColor;
    }
}

@media (max-width: 900px) {
    .cs-major-tasks {
        padding-bottom: 9px;
        .title {
            font-weight: 600;
            font-size: $mainHeaderMobileMd;
        }

        .desc {
            margin: 40px 0;
            font-size: $sub2HeaderMd;
            font-weight: 600;
        }

        .task-wrapper {
            margin-bottom: 20px;
            flex-wrap: nowrap !important;
            align-items: top !important;

            & > .MuiGrid-container {
                align-content: flex-start !important;
            }
        }

        .tasks-container {
            margin-top: 7px;
        }

        .lt-gradient {
            background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .sub-desc {
            font-size: $subContentMobile;

            p {
                margin: 0;
            }
        }
    }
}
@media (min-width:1201px) and (max-width: 1500px) {
    .cs-major-tasks {
        padding-bottom: 120px;

        .lt-gradient {
            width: 126px;
            height: 126px;
        }

        .title {
            font-weight: 700;
            font-size: $subHeaderLgs;
        }

        .desc {
            font-size: $font-28;
        }

        .sub-desc {
            font-size: 24px;
            line-height: 150%;

        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-major-tasks {
        padding-bottom: 120px;

        .lt-gradient {
            width: 136px;
            height: 136px;
        }

        .title {
            font-weight: 700;
            font-size: $mainHeaderLgs;
        }

        .desc {
            font-size: $subHeaderLgs;
        }

        .sub-desc {
            font-size: $sub2HeaderLgs;
            line-height: 150%;

        }
    }
}