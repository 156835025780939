.footer-main {
    background: linear-gradient(rgba(22, 26, 66, 0) 0%,
            rgba(22, 26, 66, 0.8) 3.03%,
            #161A42 8.15%);
    mix-blend-mode: normal;
    padding-top: 70px;
    margin-top: -20px;
}

.footer {
    width: 85%;
    margin: 0 auto;
    .footer-logo {
        height: 85px;
    }
    .main-footer-link {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        margin: 0 0 24px 0;
    } 
    h6.main-footer-link {
        margin: 0 0 24px 0;
    }
    .sub-main-footer-link {
        display: block;
        font-weight: normal;
        font-size: 20px;
        line-height: 130%;
        color: #FFFFFF;
        margin: 0 0 16px 0;
    }
    .main-footer-content {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        margin: 0 0 24px 0;
    }
    .linkedInDesc {
        padding-left: 8px;
        padding-top: 0px;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #FFFFFF;
        cursor: pointer;
    }
    .linkedInList {
        display: flex;
        height: 26px;
        align-items: center;
        &:first-child {
            margin: 8px 0 24px 0;
        }
        img {
            height: 24px;
        }
    }


    .mail-content {
        margin: 20px 0px;
        .heart-icon {
            color: #FF7900;
            font-size: 16px;
            vertical-align: middle;
        }
    }

    .lbl-mail {
        font-weight: 700;
        font-size: 28px;
        line-height: 100%;
    }

    .lbl-mail-content {
        color: rgb(255, 255, 255);
        padding-left: 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
    }

    .lbl-developed {
        color: rgb(255, 255, 255);
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        padding-bottom: 50px;

    }
}

.footer-services-mobile {
    display: none;  
    li {
        padding-left: 12px;
        padding-right: 12px;
        .footer-services-mobile-link {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-decoration: none;
            color: #fff;
        }
    }
}

.hide-for-desktop-footer {
    display: none;
}
.footer-services-menu {
    display: none;
}
.services-arrow-wrapper {
    display: none;
}

@media (max-width: 900px) {
    .footer {
        .main-footer-content {
            color: #fff;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            margin: 0 0 20px 0;
        }
        .MuiGrid-container {
            display: block !important;
        }
        ul {
            width: 100%;
        }
        .MuiGrid-root {
            .MuiGrid-item {
                display: none;
                &:first-child {
                    display: flex;
                    text-align: center;
                    justify-content: center;
                }
                &:last-child {
                    display: block;
                    margin-top: 20px;
                }
            }
        }

        
        .linkedInList {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            &:first-child {
                margin: 0 0 18px 0;
            }
            img {
                height: 20px;
            }
        }
    }

    
    .footer-services-menu {
        display: none;
        padding: 24px;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 204px;
        box-sizing: border-box;
        border-radius: 12px;
        background: #353B5F;
        margin-bottom: 20px;
        &.show-menu {
            display: flex;
        }
        li {
            margin-bottom: 20px;
            &:nth-child(4) {
                margin-bottom: 0;
            }
        }
        .footer-service-menu-item {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            cursor: pointer;
            text-transform: capitalize;
        }
    }
    .footer-list-item {
        position: relative;
    }
    .services-arrow-wrapper {
        display: inline-block;
        margin-left: 8px; 
        width: 9px;
        height: 13px;
        img {
            width: auto;
            box-sizing: border-box;
        }
        &.is-menu-open {
            img {
                transform: rotate(180deg);
            }
        }
    }
}  

@media (max-width: 640px) {
    .footer-main {
        position: relative;
        margin-top: 0; 
        padding-top: 162px;
        // background: transparent;
    } 

    .footer {
        .main-footer-link {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 24px;
            display: inline-block;
        }

        .sub-main-footer-link {
            font-size: 12px;
        }
        .linkedInDesc {
            position: static;
            padding-top: 0px;
            padding-left: 10px;
            font-size: 13px;
        }
        .mail-content {
            text-align: center;
            margin: 20px 0 12px 0;
        }
        
        .footer-logo {
            width: 86px;
            height: 64px;
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .lbl-mail {
            display: block;
            font-size: 16px;
        }

        .lbl-mail-content {
            font-size: 13px;
            display: block;
            padding-left: 0 !important;
            margin-top: 12px;
        }

        .lbl-developed {
            font-size: 10px;
        }
        .footer-services-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 48px;
            margin-bottom: 48px;
        }
        .css-ohxbf2-MuiTypography-root {
            margin-top: 48px;
        }
        .hide-for-desktop-footer {
            display: inline-block;
        }
    }


}

@media (min-width:641px) and (max-width: 899px) {
    .footer {
        .footer-logo {
            height: 40px;
        }

        .main-footer-link {
            font-weight: 700;
            font-size: 16px;
        }

        .sub-main-footer-link {
            font-size: 14px;
        }

        .linkedInDesc {
            font-size: 14px;
        }

        .lbl-mail {
            font-size: 20px;
        }

        .lbl-mail-content {
            font-size: 16px;
        }

        .lbl-developed {
            font-size: 16px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .footer {
        .footer-logo {
            height: 50px;
        }

        .main-footer-link {
            font-weight: 700;
            font-size: 18px;
        }

        .sub-main-footer-link {
            font-size: 14px;
        }

        .main-footer-content {
            font-size: 17px;
        }

        .linkedInList {
            height: auto;
            align-items: flex-start;
            &:first-child {
                margin: 8px 0 24px 0;
            }
            img {
                height: 18px;
                vertical-align: middle;
            }
            .linkedInDesc {
                font-size: 14px;
                line-height: 1;
                margin-top: 2px;
            }
        }
        .lbl-mail {
            font-size: 22px;
        }

        .lbl-mail-content {
            font-size: 18px;
        }

        .lbl-developed {
            font-size: 18px;

        }
    }
}

@media (min-width:1201px) and (max-width: 1600px) {
    .footer {
        .main-footer-link,
        .main-footer-content {
            font-size: 18px;
        }

        .sub-main-footer-link {
            font-size: 14px;
        }
        .lbl-developed, .lbl-mail-content {
            font-size: 14px;
        }
        .lbl-mail {
            font-size: 20px;
        }

        .linkedInList {
            height: 24px;
            &:first-child {
                margin: 0 0 18px 0;
            }
            img {
                height: 18px;
                vertical-align: middle;
            }

            .linkedInDesc {
                font-size: 14px;
            }
        }
        .footer-logo {
            height: 55px;
        }
    }
}