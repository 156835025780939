@import './common.scss';

.tac-privacy-policy,
.tac-legal-notice {
    margin: 0px auto;
    width: 80% !important;
    padding-top: 250px;
    margin-left: 140px !important;
    margin-bottom: 150px;
}

.tac-legal-notice-heading {
    color: var(--orange, #FF7900);
    font-size: $mainHeaderLg;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    padding-bottom: 176px;
}

.tac-main-info {
    .tac-main-info-heading {
        color: var(--orange, #FF7900);
        font-size: $mainHeaderLg;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        padding-bottom: 100px;
    }
}

.tac-basic-info {
    .tac-basic-info-heading {
        color: var(--orange, #FF7900);
        font-size: $subHeaderLg;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        padding-bottom: 48px;
    }

}

.tac-sub-basic-info2 {
    .tac-sub-basic-info2-heading {
        color: #FFF;
        font-size: $subHeaderLg;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        padding-bottom: 32px;
    }

    .tac-sub-basic-info2-details {
        color: #FFFFFF;
        font-size: $subContentLg;
        font-style: normal;
        //font-weight: 450;
        line-height: 150%;
        padding-bottom: 176px;

        a {
            color: #FF7900;
            word-wrap: break-word;
        }

        br {
            content: "";
            margin: 2em;
            display: block;
            font-size: 24%;
        }
    }
}

.tac-sub-basic-info {
    .tac-sub-basic-info-heading {
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        padding-bottom: 32px;
    }

    .tac-sub-basic-info-details {
        color: #FFF;
        font-size: $subContentLg;
        font-style: normal;
        line-height: 150%;
        padding-bottom: 96px;
    }
}

.tac-table-info-row {
    padding-bottom: 96px;
    .tac-table-info-row-heading {
        color: var(--orange, #FF7900);
        font-size: $subContentLg;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
    }

    .tac-table-info-row-details {
        color: #FFF;
        font-size: $subContentLg;
        font-style: normal;
        line-height: 150%;
    }
}

.tac-legal-notice-rt-cont {
    .tac-legal-notice-rt-cont1 {
        .tac-sub-basic-info2-details {
            padding-bottom: 70px !important;
        }
    }
}
.control-cookie-pref {
    padding-top: 80px;
}


@media (min-width:1500px) and (max-width: 1800px) {

    .tac-privacy-policy,
    .tac-legal-notice {
        margin-left: 119px !important;
        margin-bottom: 0px !important;
    }
    .control-cookie-pref {
        padding-top: 80px;
    }
    .tac-sub-basic-info2-details {
        font-size: $sub2HeaderLgs !important;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {

    .tac-privacy-policy,
    .tac-legal-notice {
        padding-top: 185px;
        margin-left: 100px !important;
        margin-bottom: 0px !important;
    }

    .tac-legal-notice-heading {
        font-size: $mainHeaderLgs;
        padding-bottom: 176px - (0.375*176);
    }

    .tac-main-info {
        .tac-main-info-heading {
            font-size: $mainHeaderLgs;
            padding-bottom: 70px;
            margin-top: 65px;
        }
    }

    .tac-basic-info {
        .tac-basic-info-heading {
            font-size: $subHeaderLgs;
            padding-bottom: 48px - (0.375*48);
        }

    }

    .tac-sub-basic-info2 {
        .tac-sub-basic-info2-heading {
            font-size: $sub2HeaderLgs;
            padding-bottom: 50px;
        }

        .tac-sub-basic-info2-details {
            font-size: $subHeaderMd;
            padding-bottom: 65px;
        }
    }

    .tac-sub-basic-info {
        .tac-sub-basic-info-heading {
            font-size: 28px;
            padding-bottom: 32px - (0.375*32);
        }

        .tac-sub-basic-info-details {
            font-size: $subContentLgs;
            padding-bottom: 96px - (0.375*96);
        }
    }

    .tac-table-info-row {
        padding-bottom: 96px - (0.375*96);

        .tac-table-info-row-heading {
            font-size: $subContentLgs;
        }

        .tac-table-info-row-details {
            font-size: $subContentLgs;
        }
    }
    .control-cookie-pref {
        padding-top: 80px;
    }
}

@media (min-width:900px) and (max-width: 1200px) {

    .tac-privacy-policy,
    .tac-legal-notice {
        padding-top: 125px;
    }

    .tac-legal-notice-heading {
        font-size: $mainHeaderMd;
        padding-bottom: 176px - (0.53125*176);
    }

    .tac-main-info {
        .tac-main-info-heading {
            font-size: $mainHeaderMd;
            padding-bottom: 176px - (0.53125*176);
        }
    }

    .tac-basic-info {
        .tac-basic-info-heading {
            font-size: $subHeaderMd;
            padding-bottom: 48px - (0.53125*48);
        }

    }

    .tac-sub-basic-info2 {
        .tac-sub-basic-info2-heading {
            font-size: $subHeaderMd;
            padding-bottom: 48px - (0.53125*48);
        }

        .tac-sub-basic-info2-details {
            font-size: $subContentMd;
            padding-bottom: 176px - (0.53125*176);
        }
    }

    .tac-sub-basic-info {
        .tac-sub-basic-info-heading {
            font-size: 20px;
            padding-bottom: 32px - (0.53125*32);
        }

        .tac-sub-basic-info-details {
            font-size: $subContentMd;
            padding-bottom: 96px - (0.53125*96);
        }
    }

    .tac-table-info-row {
        padding-bottom: 96px - (0.53125*96);

        .tac-table-info-row-heading {
            font-size: $subContentMd;
        }

        .tac-table-info-row-details {
            font-size: $subContentMd;
        }
    }
}

@media (min-width:641px) and (max-width: 899px) {

    .tac-privacy-policy,
    .tac-legal-notice {
        padding-top: 125px;
    }

    .tac-legal-notice-heading {
        font-size: $mainHeaderSm;
        padding-bottom: 176px - (0.66*176);
    }

    .tac-main-info {
        .tac-main-info-heading {
            font-size: $mainHeaderSm;
            padding-bottom: 176px - (0.66*176);
        }
    }

    .tac-basic-info {
        .tac-basic-info-heading {
            font-size: $subHeaderSm;
            padding-bottom: 48px - (0.66*48);
        }

    }

    .tac-sub-basic-info2 {
        .tac-sub-basic-info2-heading {
            font-size: $subHeaderSm;
            padding-bottom: 48px - (0.66*48);
        }

        .tac-sub-basic-info2-details {
            font-size: $subContentSm;
            padding-bottom: 176px - (0.66*176);
        }
    }

    .tac-sub-basic-info {
        .tac-sub-basic-info-heading {
            font-size: 16px;
            padding-bottom: 32px - (0.66*32);
        }

        .tac-sub-basic-info-details {
            font-size: $subContentSm;
            padding-bottom: 96px - (0.66*96);
        }
    }

    .tac-table-info-row {
        padding-bottom: 96px - (0.66*96);

        .tac-table-info-row-heading {
            font-size: $subContentSm;
        }

        .tac-table-info-row-details {
            font-size: $subContentSm;
        }
    }
}

@media(max-width: 640px) {
    .mobile-paragraph {
        display: inline-block;
        padding-bottom: 10px;
        }

    .tac-privacy-policy,
    .tac-legal-notice {
        margin: 0 auto !important;
        width: 90% !important;
        padding-top: 170px;
    }

    .tac-legal-notice-heading {
        font-size: $mainHeaderMobileLg;
        padding-bottom: 30px;
    }

    .tac-main-info {
        .tac-main-info-heading {
            font-size: $mainHeaderXs;
            font-weight: 600;
            padding-bottom: 30px;
        }
    }

    .tac-privacy-policy {
        .tac-main-info {
            .tac-main-info-heading {
                font-size: 22px;
            }
        }
    }

    .tac-basic-info {
        .tac-basic-info-heading {
            font-size: 20px;
            padding-bottom: 12px;
        }

    }

    .tac-sub-basic-info2 {
        width: 100%;

        .tac-sub-basic-info2-heading {
            font-size: $mainHeaderSm;
            font-weight: 600;
            padding-bottom: 20px;
        }

        .tac-sub-basic-info2-details {
            font-size: $subContentMobile;
            padding-bottom: 80px;
            line-height: 1.6;

            br {
                margin: 0;
                padding-bottom: 2em;
            }
        }
    }

    .tac-sub-basic-info {
        .tac-sub-basic-info-heading {
            font-size: 16px;
            padding-bottom: 15px;
        }

        .tac-sub-basic-info-details {
            font-size: $subContentMobile;
            padding-bottom: 30px;
        }
    }

    .tac-table-info-row {
        display: block !important;
        padding-bottom: 96px - (0.875*96);

        .tac-table-info-row-heading {
            font-size: $sub2HeaderSm;
            max-width: 100%;
            padding-bottom: 10px;
        }
        
        .tac-table-info-row-details {
            font-size: $subContentMobile;
            max-width: 100%;
            padding-bottom: 20px;
        }
    }
}