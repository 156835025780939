.icon-animation-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 5px;
  margin-top: 4%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.success-svg {
  height: 100px;
}

.card > span {
  color: white;
  margin-top: 20px;
}

.success-svg:hover + span {
  color: #ff7900;
}

/* Laptop icon */
.laptop {
  background-image: url("../images/Fintech.svg");
}
.connector {
  visibility: hidden;
}

.dollar {
  visibility: hidden;
  transform: translateY(19px);
  stroke: white;
}

.laptop:hover {
  background-image: none;
}

.laptop:hover .dollar {
  visibility: visible;
  animation: 1s ease bounceTop forwards;
}

.laptop:hover .connector {
  animation: 1s ease bounceTopConnector forwards;
}

@keyframes bounceTop {
  from {
    transform: translateY(19px);
  }

  to {
    transform: translateY(-5px);
    stroke: #ff7900;
  }
}

@keyframes bounceTopConnector {
  0% {
    opacity: 0;
  }
  50% {
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes bounceBack {
  from {
    transform: translateY(0);
    stroke: #ff7900;
  }

  to {
    transform: translateY(20px);
  }
}

/* Industry icon */
.industry {
  background-image: url("../images/Manufacturing.svg");
}

.industry .gear {
  animation: 300ms rotate;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

.industry:hover .gear {
  animation: 300ms forwards rotateR;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    stroke: #ff7900;
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotateR {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    stroke: #ff7900;
  }
}

/* Health icon */
.health {
  background-image: url("../images/Healthcare.svg");
}

.health .square {
  animation: 300ms rotate;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

.health:hover .square {
  animation: 300ms forwards rotateR;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    stroke: #ff7900;
  }
  to {
    -webkit-transform: rotate(90deg);
  }
}

@keyframes rotateR {
  0% {
    -webkit-transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    stroke: #ff7900;
  }
}

/* Automotive icon */
.automotive .automotive-block {
  animation: automotive-rotateR 300ms linear forwards;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

.automotive:hover .automotive-block {
  animation: automotive-rotate 300ms linear forwards;
  transform-origin: 50% 50%;
  transform-box: fill-box; /* Important, otherwise animation is not centered */
}

.automotive:hover .automotive-block > path{
  stroke: #ff7900;
}

@keyframes automotive-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes automotive-rotateR {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* apparel icon */
.apparel .hook {
  animation: 300ms ease bounceBottomCloth forwards;
}

.hook2 {
  visibility: hidden;
}

.cloth {
  visibility: hidden;
}

.apparel:hover .hook2 {
  visibility: visible;
  animation: 800ms ease opacityHook2 forwards;
}

.apparel:hover .cloth {
  animation: 800ms ease bounceBottomCloth forwards;
}

.apparel:hover .hanger-line {
  visibility: hidden;
}

.apparel:hover .hook {
  animation: 800ms ease opacityHook forwards;
}

@keyframes opacityHook2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityHook {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounceBottomCloth {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
