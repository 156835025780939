.job-banner-bg {
    background: linear-gradient(0deg, #19193C, #19193C), linear-gradient(97.13deg, rgba(8, 25, 164, 0.12) -12.87%, rgba(12, 13, 15, 0.25) 117.57%);
    filter: blur(250px);
    width: 100%;
    height: 100%;
    position: absolute;
}

.job-banner-container {
    position: relative;
}

.job-banner {
    left: 5%;
    width: 90%;
    position: relative;
    padding-top: 185px;
    padding-bottom: 100px;
    .btn-back-all-pos {
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        border: 2px solid #FF7900;
        border-radius: 8px;
        padding: 16px 36px;
        height: 66px;
    }

    .btn-back-all-pos:hover {
        border-color: #D9D9D9 !important;
        background-color: #FF7900 !important;
    }

    .lbl-job-title {
        font-weight: 250;
        font-size: 96px;
        line-height: 130px;
        letter-spacing: 4.8px;
        color: #FFFFFF;
        text-shadow: 0px 0px 2px rgba(0, 59, 232, 0.5);
        -webkit-text-stroke: 2px rgba(0, 59, 232, 0.5);

        div {
            padding-left: 10%;
        }
    }

    .banner-high-card {
        background: linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100.83%);
        /* NEW for cases */

        box-shadow: 0px 0px 15px rgba(0, 59, 232, 0.75);
        backdrop-filter: blur(4px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 32px;
        padding: 45px;
        position: relative;
        // right: -3%;
    }

    .high-title {
        // width: 60%;
        float: left;
        font-weight: 450;
        font-size: 32px;
        line-height: 150%;
        /* or 48px */


        /* Orange */

        color: #FF7900;
        display: flex;
        width: 50%;
        padding-right: 20px;

    }

    .high-title:after {
        height: 34px;
        border-bottom: 1px dashed #FFFFFF;
        content: '';
        flex: 1;
        margin-left: 10px;
    }

    .high-title-value {
        // width: 40%;
        // float: right;
        font-weight: 500;
        font-size: 32px;
        line-height: 150%;
        /* or 48px */


        /* White */

        color: #FFFFFF;

    }
}

@media (max-width: 640px) {
    .job-banner {
        margin: 0 auto;
        position: static;
        .lbl-job-title {
            font-size: 32px;
            line-height: 150%;
            letter-spacing: normal;
            margin-bottom: 20px;
        }

        .banner-high-card {
    
            box-shadow: none;
            border-radius: 8px;
            padding: 25px;
            position: relative;
        }

        .job-row-wrapper {
            display: flex;
            flex-wrap: nowrap;
        }

        .high-title {
            float: none;
            font-weight: 500;
            font-size: 15px;
            line-height: 150%;
            color: #FF7900;
            display: flex;
            width: 50%;
            padding-right: 10px
        }
        
        .high-title:after {
            height: 16px;
            margin-left: 10px;
        }

        .high-title-value {
            font-size: 15px;
            font-weight: 300;
        }
        
    }
}

.btn-back-all-pos-icon {
    display: none !important;
}


@media (max-width: 900px) {
    .job-banner {
        left: 5%;
        padding-top: 40px;
        padding-bottom: 73px;
        .lbl-job-title {
            font-size: 26px;
            font-style: normal;
            font-weight: 350;
            line-height: 150%;
            letter-spacing: 1.3px;
            margin-bottom: 20px;
        }
        .high-title-value,
        .high-title {
            font-size: 16px;
        }
        .btn-back-all-pos {
            display: none;
        }
        .btn-back-all-pos-icon {
            background: transparent;
            border: 1px solid transparent;
            margin-bottom: 63px;
            display: inline-flex !important;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            padding: 5px 10px 5px 0;
            outline: none; 
            min-width: auto;
            width: auto;
        }
    }
    .banner-high-card {
        border-radius: 8px;
        .css-851zx1-MuiGrid-root {
            row-gap: 5px;
        }
    }

}

@media (min-width:641px) and (max-width:899px) {
    .job-banner {
        .banner-high-card {
            padding: 30px;
        }

        .high-title {
            width: 50%;
        }

        .high-title-value,
        .high-title {
            font-size: 18px;
        }

        .high-title:after {
            height: 18px;
        }

        .lbl-job-title {
            font-size: 26px;
            text-shadow: 0px 0px 0.45px rgba(0, 59, 232, 0.5);
            -webkit-text-stroke: 0.45px rgba(0, 59, 232, 0.5);
        }

        .btn-back-all-pos {
            font-size: 16px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .job-banner {
        padding-top: 100px;
        padding-bottom: 100px;

        .high-title {
            width: 50%;
        }

        .high-title-value,
        .high-title {
            font-size: 26px;
        }

        .lbl-job-title {
            font-size: 40px;
            text-shadow: 0px 0px 0.7px rgba(0, 59, 232, 0.5);
            -webkit-text-stroke: 0.7px rgba(0, 59, 232, 0.5);
        }

        .btn-back-all-pos {
            font-size: 18px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .job-banner-bg {
        height: 70px;
    }

    .job-banner {
        padding-bottom: 0px;
        .high-title {
            width: 38%;
        }
        .high-title::after {
            height: 26px;
        }

        .high-title-value,
        .high-title {
            font-size: 24px;
        }

        .lbl-job-title {
            font-size: 56px;
            line-height: 76px;
            text-shadow: 0px 0px 1.25px rgba(0, 59, 232, 0.5);
            -webkit-text-stroke: 1.25px rgba(0, 59, 232, 0.5);
        }

        .btn-back-all-pos {
            font-size: 18px;
            padding: 16px 28px;
            height: 52px;
        }
    }

}

@media (min-width:1501px) and (max-width: 1800px) {
    .job-banner-bg {
        height: 70px;
    }

    .job-banner {
        .lbl-job-title {
            font-size: 68px;
            line-height: 100px;
        }

        .high-title {
            font-size: 30px;
            width: 40%;
        }
        .high-title::after {
            height: 31px;
        }

        .high-title-value {
            font-size: 30px;
        }

        .btn-back-all-pos {
            font-size: 22px;
        }
    }
}