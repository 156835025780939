@import '../css/common';

.work-us-main {
    .item-title {
        font-weight: 500;
        font-size: $subHeaderLg;
        line-height: 120%;
        /* identical to box height, or 65px */


        color: #FF7900;
    }

    .item-desc {
        //font-weight: 500;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 60px */


        /* Theme/White/Default */

        color: #FFFFFF;
    }

    .work-us-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 90px;
        /* identical to box height, or 129% */


        color: #FFFFFF;

    }

    .work-us-list-item {
        padding-top: 70px;
    }


}

.root-career-content {
    .work-us-main {
        // margin-top: 370px;
        // margin-bottom: 370px;
        margin-top: 5%;
        // margin-top: 15%;
        margin-bottom: 15%;

    }
}

@media (min-width:641px) and (max-width:899px) {
    .work-us-main {
        .work-us-title {
            font-size: $mainHeaderSm;
        }

        .item-title {
            font-size: $subHeaderSm;
        }

        .item-desc {
            font-size: $subContentSm;
        }

        .work-us-list-item {
            padding-top: 60px;
        }
    }

}

@media (min-width:900px) and (max-width: 1200px) {
    .work-us-main {
        .work-us-title {
            font-size: $mainHeaderMd;
        }

        .item-title {
            font-size: $subHeaderMd;
        }

        .item-desc {
            font-size: $subContentMd;
        }
    }

}

@media (min-width:1201px) and (max-width: 1500px) {
    .work-us-main {
        .work-us-title {
            font-size: $mainHeaderLgs;
        }

        .item-title {
            font-size: $subHeaderLgs;
        }

        .item-desc {
            font-size: $subContentLgs;
        }

        .work-us-list-item {
            svg {
                max-width: 100%;
            }
        }
    }

}

@media (min-width:1501px) and (max-width: 1800px) {

    .work-us-main {
        .work-us-title {
            font-size: $mainHeaderLgs;
        }

        .item-title {
            font-size: $subHeaderLgs;
        }

        .item-desc {
            font-size: $subContentLgs;
        }

        .work-us-list-item {
            column-gap: 48px !important;

            svg {
                max-width: 100%;
            }
        }
    }
}