@import './common';

.root-cs {
    .cs-highlights {
        padding-bottom: 60px;
        .tail_text {
            .columns {
                display: flex;
                align-items: center;
                width: 100%;
                height: 700px;
                box-sizing: border-box;
                .column {
                    flex-basis: 33.33% !important;
                    max-width: 100% !important;
                    box-sizing: border-box;
                    padding: 0 8px;
                    img {
                        display: inline-block;
                        width: auto !important;
                        max-width: 100%;
                        max-height: 100%;
                        height: auto !important;
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
}
.cs-highlights {
    padding-bottom: 80px;
    .cs-highlight+.cs-highlight {
        padding-top: 95px;
    }
    .icon-align {
        text-align: right;
    }
    .cs-hl-rt {
        padding-left: 15%;
        .cs-hl-arrow-content {
            display: flex;
            align-items: flex-end;
        }
    }

    .cs-hl-ni {
        width: 50%;
        .cs-hl-ni-title {
            margin-bottom: 29px;
        }
        .cs-hl-ni-image {
            max-width: 100%;
        }
    }
    .flt-rt {
        float: right;
    }
    .cs-highlight-header {
        margin-bottom: 24px;
    }
    .title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: $orangeColor;
    }
    .desc {
        font-weight: 500;
        font-size: $subHeaderLg;
        line-height: 133%;
        width: 60%;
        color: $whiteColor;
        margin-bottom: 30px;
    }

    .sub-desc {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
        p {
            margin: 0px !important;
        }
    }

    .sub_content {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
        h2 {
            color: $orangeColor;
        }
        h4 {
            font-size: $subHeaderLg;
            font-weight: 700;
        }
        p:first-of-type {
            margin-top: 0px;
        }
    }

    .lt-gradient-number {
        color: #FFF;
        text-align: center;
        font-size: 70px;
        font-family: Neue Haas Grotesk Display Pro;
        font-weight: 700;
        line-height: 225%;
        width: 166px;
        height: 166px;
        border-radius: 50%;
        background: radial-gradient(50% 50.00% at 50% 50.00%, #1C2898 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0.00) 100%);
        div {
            padding-top: 26px;
        }
    }

    .lt-gradient {
        background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
        width: 166px;
        height: 166px;
    }

    .tail_text {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
        margin-top: 70px !important;
    }

    .n-title {
        font-size: $subContentLg;
        line-height: 133%;
        color: $whiteColor;
        margin-bottom: 10px;
        margin-top: 8px;
    }
    .ni-title {
        font-weight: 700;
        font-size: $subHeaderLg;
        line-height: 134%;
        color: $whiteColor;
        font-style: normal;
    }
    .ni-desc {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
    }
    .lt-number {
        font-weight: 400;
        font-size: 50px;
        line-height: 90px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 90px;
        height: 90px;
        border: 3.13889px solid #FF7900;
        border-radius: 50%;
        cursor: pointer;
    }
    .val {
        font-weight: 700;
        font-size: 72px;
        line-height: 130%;
        text-align: center;
        color: $whiteColor;
    }
    .val-text {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        text-align: center;
        color: $whiteColor;
        p {
            padding: 0px;
            margin: 0px;
        }
    }
    .val-radient {
        margin: 0 auto;
        position: relative;
        width: 400px;
        height: 400px;
        background: radial-gradient(50% 50% at 50% 50%, #1C2898 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
    }
    .val-container {
        position: absolute;
        top: 20%;
    }
    .cs-highlight-conainer {
        .cs-highlight-conainer-item {
            margin-bottom: 40px;
        }
    }
}

.sl-container {
    display: none;
}
.gradient-wrapper {
    position: relative;
}
.iovando-upgrade {
    margin: 96px 0 96px 0;
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
}
.iovando-clinical-trials {
    margin: 56px 0 0 0;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
}


@media (max-width: 900px) {
    
    .sl-container {
        display: block;
        margin: 0 auto;
        position: relative;
        width: 100%;
        height: 565px;
        padding-top: 24px;
        padding-bottom: 50px;
        user-select: none;
        border-radius: 8px;
        background: transparent;
        box-sizing: border-box;
        input {
            display: none;
        }
        .slider-image {
            position: absolute;
            width: 100%;
            height: 450px;
            z-index: -1;
            display: flex;
            align-items: center;
            max-width: 400px;
            left: 0;
            right: 0;
            margin: auto;
            img {
                width: 100%;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
            .prev, .next {
                width: 44px;
                height: 44px;
                position: absolute;
                bottom: -65px;
                background-color:transparent;
                border: 1px solid #fff;
                border-radius: 50%;
                z-index: 99;
                transition: .45s;
                cursor: pointer;
                text-align: center;
                background-repeat: no-repeat;
                background-position: center;
            }
            .next {
                right: 50%;
                transform: translateX(58px);
                background-image: url('../assets/arrow-next.svg');
            }
            .prev {
                left: 50%;
                transform: translateX(-58px);
                background-image: url('../assets/arrow-previous.svg');
            }
    
            .prev:hover, .next:hover {
                transition: .3s;
                background-color: #FF7900;
                border: 1px solid #FF7900;
            }
        }
    
        #sl-slide-nav {
            width: 100%;
            bottom: 30px;
            height: 11px;
            position: absolute;
            text-align: center;
            z-index: 99;
            cursor: default;
            display: none; // hide temporary
            .dots {
                top: -5px;
                width: 18px;
                height: 18px;
                margin: 0 3px;
                position: relative;
                border-radius: 100%;
                display: inline-block;
                background-color: #fff;
                transition: .4s;
                cursor: pointer;
            }
            #dot1:hover,
            #dot2:hover,
            #dot3:hover {background: #FF7900;}
        }
    }
    #i1 ~ #slide-one,
    #i2 ~ #slide-two,
    #i3 ~ #slide-three {
        opacity: 0;
    }
    #i1:checked ~ #slide-one,
    #i2:checked ~ #slide-two,
    #i3:checked ~ #slide-three {
        opacity: 1;
        z-index: 9;
        animation: scroll 1s ease-in-out;
    }
    #i1:checked ~ #sl-slide-nav #dot1,
    #i2:checked ~ #sl-slide-nav #dot2,
    #i3:checked ~ #sl-slide-nav #dot3 {background: #FF7900;}
    

    .root-cs .cs-highlights {
        .cs-hl-ni-title {
            column-gap: 0 !important;
        }
        .ni-title {
            font-size: 20px;
        }
        .lt-number {
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
            margin-right: 15px;
        }
        
        .cs-hl-ni {
            width: 100%;
            img {
                max-width: 100%;
                margin-top: 5px;
            }
        }
    }


    #automotive-desktop {
        display: none;
    }
    #automotive-mobile {
        display: block;
        margin: 32px auto;
    }

    .cs-highlights {
        .lt-gradient-number {
            font-size: 52px;
            width: 124px;
            height: 124px;
        }
        .n-title {
            font-size: 24px;
        }
    }
}


@media (max-width: 640px) {
    .root-cs .cs-highlights {
        padding-bottom: 30px;
        .cs-highlight-wrapper {
            column-gap: 18px !important;
            align-items: center !important;
        }
        .n-title {
            margin: 0;
            font-size: 16px;
        }
        .ni-desc {
            font-size: 16px;
        }
        .gradient-wrapper {
            position: relative;
            height: 260px;
            margin: 0 auto;
        }
        .val-container {
            top: 25%;
            left: 15px;
            max-width: 90%;
            width: 220px;
            height: 220px;
        }
        .val-radient {
            max-width: 90%;
            width: 250px;
            height: 250px;
        }
        .val {
            font-size: $mainHeaderLgs;
        }
        .val-text {
            font-size: $subContentMobile;
        }
    }
    .sub-desc {
        h3 {
            font-weight: 600;
            font-size: 24px !important;
            margin-top: 0;
        }
    }
    .root-cs {
        .cs-highlight {
            padding-top: 60px !important;
        }
    }
    .cs-highlight {
        padding-top: 0 !important;
        padding-bottom: 0;
        .cs-highlight-header {
            margin-bottom: 30px;
            column-gap: 0 !important;
            align-items: flex-start !important;
            .lt-gradient {
              margin-right: 16px;
            }
        }
        .task-wrapper {
            margin-bottom: 20px;
            flex-wrap: nowrap !important;
            align-items: flex-start !important;
        }
        .tasks-container {
            margin-top: 7px;
        }
        .lt-gradient {
            width: 40px;
            height: 40px;
            font-size: 0;
            margin-right: 10px;
            border-radius: 50%;
            background: radial-gradient(50% 50.00% at 50% 50.00%, #FF7900 0%, rgba(28, 40, 152, 0.91) 40.08%, rgba(28, 38, 128, 0.00) 100%);
        }
        .sub-desc {
            font-size: $subContentMobile;
            p {
                margin: 0;
            }
            h3 {
                font-weight: 600;
                font-size: 24px;
                margin-top: 0;
            }
        }
        .lt-gradient-number {
            font-size: 20px;
            font-weight: 600;
            line-height: 40px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: none;
            border: 1px solid #FF7900;
        }
        .sub_content {
            font-size: $subContentMobile;
            margin-bottom: 20px;
            b {font-size: 20px;}
            p {
                margin: 0;
            }
            h4 {
                font-size: 20px;
            }
        }
        .tail_text {
            font-size: 20px;
            margin-top: 24px !important;

            h3 {
                font-size: 20px;
                line-height: 1.5;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
            }
        }
        .highlight-section {
            row-gap: 60px !important;
            display: grid;
        }

        .title {
            font-weight: 600;
            margin-top: 3px;
            font-size: $mainHeaderMobileMd;
        }

        .desc {
            margin: 0;
            width: 100%;
            font-size: $mainHeaderSm;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .cs-hl-rt {
            padding-left: 0;
            flex-wrap: nowrap !important;
            align-items: flex-start;
            column-gap: 18px !important;

            .icon-align {
                min-width: 40px;
                max-width: 40px;
                height: 40px;

                img {
                    max-width: 100%;
                }
            }

            .sub-desc {
                font-size: $subContentMobile;

                p {
                    margin: 0;
                }

                h3 {
                    font-weight: 600;
                    font-size: 24px;
                    margin-top: 0;
                }
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-highlights {
        .cs-highlight-header {
            margin-bottom: 20px;
        }

        .title {
            font-size: $mainHeaderLgs;
        }

        .desc {
            font-weight: 700;
            font-size: $mainHeaderLgs;
            line-height: 133%;
            width: 50%;
            margin-bottom: 44px;
        }

        .sub-desc {
            font-weight: 350;
            font-size: $subContentLgs;
            line-height: 150%;
        }

        .cs-highlight-header {
            margin-bottom: 24px;
        }

        .sub_content {
            font-size: $subContentLgs;

            h2 {
                font-size: $mainHeaderLgs;
            }

            h3 {
                font-size: $subContentLg;
            }

            p {
                font-size: $subContentLgs;
            }
        }

        .lt-gradient-number {
            font-size: 38px;
            width: 110px;
            height: 110px;
            border-radius: 166px;
            line-height: 260%;

            div {
                padding-top: 26px;
            }
        }

        .lt-gradient {
            width: 110px;
            height: 110px;
        }

        .tail_text {
            font-size: $subContentLgs;
            margin-top: 70px !important;
            h3 {
                font-size: $subHeaderLgs;
            }
        }
        .n-title {
            font-size: $subContentLgs;
            margin-bottom: 20px;
        }
        .ni-title {
            font-size: $mainHeaderLgs;
        }
        .lt-number {
            font-size: 40px;
            line-height: 65px;
            width: 65px;
            height: 65px;
        }
        .val {
            font-size: 45px;
        }
        .val-text {
            font-size: $subContentLgs;
        }

        .val-radient {
            width: 300px;
            height: 300px;
        }

    }
}

@media (min-width:900px) and (max-width: 1200px) {

    .root-cs .cs-highlights {
        .cs-hl-ni-title {
            column-gap: 0 !important;
        }
        .ni-title {
            font-size: 24px;
        }
        .lt-number {
            width: 45px;
            height: 45px;
            font-size: 24px;
            line-height: 45px;
            margin-right: 15px;
        }
    }
}
@media (min-width:1201px) and (max-width: 1500px) {
    .cs-highlights {

        .title {
            font-weight: 700;
            font-size: $subContentLg;
            line-height: 133%;
        }
        .desc {
            font-size: $subHeaderLgs;
            margin-bottom: 44px;
        }
        .sub-desc {
            font-size: $subContentLgss;
        }

        .cs-highlight-header {
            margin-bottom: 50px; // bug-cs-iuvando -10
        }

        .sub_content {
            font-size: $subContentLgs;
            h3 {
                font-size: $subContentLgs;
            }
            h2 {
                font-size: $subHeaderLgs;
            }
            p {
                //case-study
                font-size: 24px;
            }
        }

        .lt-gradient-number {
            font-size: 32px;
            width: 90px;
            height: 90px;
            border-radius: 166px;
            line-height: 260%;
            div {
                padding-top: 26px;
            }
        }
        .lt-gradient {
            width: 110px;
            height: 110px;
        }

        .tail_text {
            font-size: $subContentLg;
            margin-top: 70px !important;
            h3 {
                font-size: $sub2HeaderLgs;
            }
            p {
                font-size: 24px;
            }
        }
        .n-title {
            //case-study
            font-size: 24px;
            line-height: 133%;
            margin-bottom: -14px;
        }
        .ni-title {
            font-size: $subHeaderLgs;
        }
        .lt-number {
            font-size: 30px;
            line-height: 55px;
            width: 55px;
            height: 55px;
        }
        .val {
            font-size: 40px;
        }
        .val-text {
            font-size: 24px;
        }
        .val-radient {
            width: 280px;
            height: 280px;
        }
    }
}



#automotive-mobile {
    display: none;
}
