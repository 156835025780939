@import './common.scss';

.work-banner {
    width: 85%;
    margin: 0 auto;
    padding-top: 14.9%;

    .work-banner-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 133%;
        color: #FF7900;
    }

    .c-title {
        color: #FF7900 !important;
    }

    .work-banner-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        color: #FFFFFF;
    }
}
@media (min-width:1501px) and (max-width: 1600px) {
    .work-banner {
        .work-banner-title {
            font-size: $mainHeaderLgs;
        }

        .work-banner-desc {
            font-size: $subContentLgs;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .work-banner {
        .work-banner-title {
            font-size: $mainHeaderLgs;
        }

        .work-banner-desc {
            font-size: $subContentLgs;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .work-banner {
        .work-banner-title {
            font-size: $mainHeaderMd;
        }

        .work-banner-desc {
            font-size: $subContentMd;
        }
    }
}

@media (min-width:641px) and (max-width: 899px) {
    .work-banner {
        .work-banner-title {
            font-size: $mainHeaderSm;
        }

        .work-banner-desc {
            font-size: $subContentSm;
        }
    }
}

@media (max-width:640px) {
    .work-banner {
        width: 90%;
        padding-top: 17%;
        margin: 71px auto 0;

        .work-banner-title {
            font-size: $mainHeaderMobileLg;
        }

        .work-banner-desc {
            width: 90%;
            max-width: 480px;
            font-weight: 300;
            font-size: $subContentMobile;
            padding-top: 18px !important;
        }
    }
}