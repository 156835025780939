@import './common.scss';

.home-signup {
    // height: 375px;
    width: 85%;
    margin: 0 auto;
    // margin-top: 100px;
    padding-top: 200px;
    padding-bottom: 30px;
    //temporary
    // background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
    // background: url('../assets/signup-radiant.png');
}

.main-content {
    top: 30%;
    position: relative;
}

.mobile {
    display: none !important;
}

.lbl-signup {
    font-style: normal;
    font-weight: 700;
    font-size: $sub2HeaderLg;
    line-height: 120%;
    padding-left: 20%;
    color: #FF7900;
    .lbl-signup-break {
        display: block;
    }
}

.txt-mail-wrapper {
    margin-top: 11px;
    margin-bottom: 24px;
}

.txt-mail {
    input {
        background: #FFFFFF;
        border: 3px solid #D9D9D9;
        border-radius: 8px;
        width: 500px;
    }
}

.btn-signup {
    height: 62px;
    width: 140px;
    margin-left: 23px !important;
    border-radius: 8px !important;
    background: #FF7900 !important;
    color: #FFFFFF !important;
    line-height: 26px !important;
    font-size: 18px !important;
}

.btn-signup:hover {
    box-shadow: 0 0 2px 2px #BE9663 !important;
}

.terms-conditions-main {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    .chk-terms {
        padding: 0px 10px 5px 0;
    }
}

.terms-conditions-text-content {
    display: inline-block;
    vertical-align: middle;
    vertical-align: top;
    width: calc(100% - 35px);
    padding-top: 3px;
}

.terms-color {
    color: #FF7900
}

.chk-terms {
    color: #FF7900 !important;
}

#txt-emailid-helper-text,
.error-msg {
    font-size: 15px;
    font-weight: 500;
    color: #d32f2f;
    margin-left: 0px;
    margin-top: 8px;
}

.success-msg {
    margin-top: 25px;
    font-weight: 700;
    font-size: 28px;
    line-height: 133%;
    color: #FFFFFF;
}
.terms-conditions-main-inner-container {
    position: relative;
}

.terms-conditions-link {
    display: inline-block;
    color: #FF7900;
    cursor: pointer;
    text-decoration: underline;
}

//! Todo! Enquire why we have two width ranges
//! 1. from 300 to 600
//! 2. from 400 to 640
@media (min-width:275px) and (max-width:640px) {
    .main-content {
        position: relative;
        top: 0 !important;
        flex-wrap: wrap !important;
    }

    .home-signup {
        width: 90%;
        padding-top: 0;
        height: 320px !important;
    }

    .non-mobile {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .lbl-signup {
        text-align: left;
        font-size: $mainHeaderMobileMd;
        padding: 0px;
        margin-top: 15px !important;
    }

    .txt-btn-container {
        width: 50%;
    }

    .txt-mail {
        width: 100% !important;
        height: auto;
        input {
            border-radius: 12px !important;
        }
    }

    #txt-emailid {
        font-size: $subContentSm;
        padding: 6px;
        height: 28px;
    }

    #txt-emailid-helper-text,
    .error-msg {
        font-size: 12px;
    }

    .error-msg {
        margin-bottom: 20px;
    }

    .terms-conditions-main {
        width: 100% !important;
        font-size: 12px !important;
        padding: 0px;
    }

    .terms-conditions-main-inner-container {
        align-items: center;
        align-items: flex-start;
    }

    .btn-signup {
        position: absolute !important;
        top: 100%;
        left: 0;
        right: 0;
        border-radius: 6px !important;
        font-size: 16px !important;
        height: 44px !important;
        width: 200px !important;
        margin: 0 auto !important;
        display: block !important;
    }
}



@media (min-width:400px) and (max-width:640px) {
    .main-content {
        padding-bottom: 20px;
    }

    #txt-emailid {
        font-size: 10px;
        padding: 5px;
    }

    btn-signup {
        height: 28px !important;
        width: auto !important;
        margin-top: 0px !important;
        margin-left: 11px !important;
        font-size: 12px !important;
    }

    lbl-signup {
        font-size: 18px !important;
        padding: 0px;
        padding-bottom: 30px !important;
        margin-top: 20px !important;
    }

    .error-msg {
        margin-bottom: 20px;
    }
}

@media (max-width:1366px) {
    .success-msg {
        margin-top: 0;
    }
}

@media (max-width:900px) {
    .lbl-signup {
        margin-bottom: 25px;
    }
    .txt-mail-wrapper {
        display: flex;
        justify-content: center;
    }
    .terms-conditions-main-inner-container {
        text-align: center;
    }
    .terms-conditions-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .terms-conditions-text-content {
        width: auto;
        text-align: left;
        padding-top: 0;
    }
    .success-msg {
        font-size: 18px;
        text-align: center;
        letter-spacing: 1.20px;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .home-signup {
        height: 240px;
        background-size: cover;
    }

    .lbl-signup {
        text-align: center;
        font-size: 18px;
        padding-left: 0%;
        .lbl-signup-break {
            display: inline;
        }
    }
    .txt-mail {
        width: 62%;
    }

    #txt-emailid {
        font-size: 16px;
        padding: 6px;
    }

    .btn-signup {
        height: 40px;
        // width: 110px;
        font-size: small !important;
        margin-left: 10px !important;
    }

    .terms-conditions-main {
        width: 100% !important;
        font-size: 12px !important;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .home-signup {
        height: 275px;
        background-size: cover;
    }

    .lbl-signup {
        font-size: 22px;
    }

    .txt-mail input {
        width: 220px;
        width: 220px;
        padding: 5px;
    }

    .btn-signup {
        height: 40px;
        font-size: 14px !important;
    }

    .terms-conditions-main {
        font-size: 10px;
        padding-top: 0;
        width: calc(100% - 120px);
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .home-signup {
        padding-top: 140px;
        padding-bottom: 30px;
    }

    .lbl-signup {
        font-size: 25px;
    }

    .txt-mail {
        width: 340px;
    }
    .btn-signup {
        width: auto;
        padding: 10px 15px !important;
    }
}

@media (min-width:1500px) and (max-width: 1600px) {
    .home-signup {
        padding-top: 140px;
        padding-bottom: 30px;
    }

    .lbl-signup {
        font-size: 27px;
    }

    .txt-mail {
        width: 380px;
    }
    .btn-signup {
        width: auto;
        padding: 20px 24px !important;
    }
}

@media (min-width:1900px) {
    .btn-signup {
        width: auto;
        padding: 20px 24px !important;
    }
}

@media (max-width:640px) {
    .home-signup {
        padding-bottom: 0;
    }

}

@media (min-width:300px) and (max-width:440px) {
  .home-signup {
      padding-bottom: 25px;
  }

}