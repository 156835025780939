$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

.home-partners {
    width: 85% !important;
    margin: 0 auto;
    padding-top: 3%;
    padding-bottom: 2%;
    align-items: center;

    .partner-img-blk {
        margin-left: 25px;
        margin-right: 25px;
    }
    .home-partners-row1 {
        padding-bottom: 70px;
    }
}
.root-services, .root-career, .root-about {
    .home-partners {
        .home-partners-row1 {
            padding-bottom: 40px;
        }
    }
}

.root-about {
    .home-partners {
        padding-bottom: 2%;
    }
}

.root-work {
    .home-partners {
        padding-bottom: 2%;
    }
}

.root-career {
    .home-partners {
        padding-bottom: 2%;
    }
}

@media (max-width:900px) {

    .root-home, .root-career, .root-about, .root-services {
        .home-partners {
            padding-top: 125px;
            .home-partners-row1 {
                padding-bottom: 20px;
                .home-partner-item {
                    padding-top: 18px;
                    padding-bottom: 22px !important;
                }
            }
            .home-partners-row2 {
                display: none;
            }
        }
    }
    
}
@media (max-width:640px) {

    .about-banner {
        margin-bottom: 50px !important;
    }
    .home-partners {
        width: 90% !important;
        
    }
    .root-home, .root-career, .root-about, .root-services {
        .home-partners {
            .home-partners-row1 {
                row-gap: 0px !important;
                column-gap: 12px !important;
                justify-content: space-evenly !important;
                .home-partner-item {
                    width: 30%;
                    padding-bottom: 32px;

                    &:last-child {
                        display: none !important;
                    }
                }
            }
            .home-partners-row2 {
                display: none;
            }
        }
    }

    .root-about {
        .home-partners {
            padding-bottom: 0;
            gap: 25px;
            justify-content: space-between;
        }
    }

    .partner-logo {
        margin: 0 auto;
        max-height: 34px;
        mix-blend-mode: lighten;
    }

    .career-bg2 .home-partners-row1 {
        column-gap: 12px !important;
    }

    .down-arrow,  .home-partners-row2 {
        display: none;
    }
}

@media (max-width:900px) {
    .top-bg {
        .home-partners {
            width: 100% !important;
            position: relative;
            padding-top: 45px;
            padding-bottom: 35px;
            margin: 70px auto 0;
        }
    }
}
@media (min-width:641px) and (max-width:899px) {
    .partner-logo {
        max-height: 30px;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .root-about {
        .home-partners {
            padding-bottom: 5%;

        }
    }
    .partner-logo {
        max-height: 30px;
    }

}

@media (min-width:1200px) and (max-width: 1500px) {
    .partner-logo {
        max-height: 40px;
    }
    .home-partners {
        padding-top: 1%;
        .home-partners-row1 {
            padding-bottom: 45px;
        }
    }

}

@media (min-width:1500px) and (max-width: 1600px) {
    .partner-logo {
        max-height: 48px;
    }
    .home-partners-row1 {
        column-gap: 90px !important;
    }
}