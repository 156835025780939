.root-position-detail {
  padding-top: 85px;
  position: relative;

  #pageInner404 {
    margin: 0 auto;
  }
}


.root-position-job-detail {
  width: 85%;
  margin: 0 auto;

  .job-desc-container {
    font-weight: 450;
    font-size: 32px;
    line-height: 150%;
    margin-top: 80px;
    padding-bottom: 22px;

    h2,
    h4 {
      span {
        font-size: 43px !important;

      }
    }

    span {
      font-size: 32px !important;
      background-color: transparent !important;
      color: #FFFFFF;

      strong {
        color: #FF7900;
      }
    }

    background-color: transparent !important;
    color: #FFFFFF;

    p {
      background-color: transparent !important;
      font-weight: 450;
      font-size: 32px;
      line-height: 150%;
      color: #FFFFFF;
    }

    ul {
      padding-left: 30px;
      background-color: transparent !important;
    }

    a {
      color: #FFFFFF;
      background-color: transparent !important;
    }

    li {
      font-weight: 450;
      font-size: 32px;
      line-height: 150%;
      color: #FFFFFF;
      list-style-type: disc;
      background-color: transparent !important;
    }

    li::marker {
      color: #FF7900;
    }
  }
}

.job-desc-container :is(h1, h2, h3, h4, h5, h6) {
  font-weight: 500;
  font-size: 48px;
  line-height: 133%;
  margin-top: 40px !important;
  margin-block-end: 0px;
  color: #FF7900;
  background-color: transparent !important;

}

@media (max-width: 900px) {
  .root-position-detail {
    padding-top: 75px;

    .job-desc-container {
      margin-top: 0;
      line-height: normal;
    }
  }

}

@media (max-width: 640px) {

  .root-position-job-detail {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;

    .job-desc-container {
      p {
        margin: 0;
        font-size: 14px;

        span {
          font-size: 14px !important;
        }

        strong {
          font-size: 20px;
        }
      }

      ul,
      ol {
        padding-left: 15px;
        padding-bottom: 30px;
      }

      span {
        color: #fff !important;
        background-color: transparent !important;
        font-size: 16px !important;
      }

      h4 span {
        font-size: 24px !important;
      }

      li {
        font-size: 14px;
        margin-bottom: 15px;

        span {
          font-size: 14px !important;
        }
      }
    }

    .client-banner {
      max-width: 100%;
    }
  }
}

@media (min-width:641px) and (max-width:899px) {

  .root-position-job-detail {
    font-size: 18px;

    .job-desc-container {
      p {
        font-size: 18px;
      }

      ul {
        padding-left: 18px;
      }

      li {
        font-size: 18px;
      }
    }
  }

  .job-desc-container :is(h1, h2, h3, h4, h5, h6) {
    font-size: 26px;
  }

}

@media (min-width:900px) and (max-width: 1200px) {

  .root-position-job-detail {
    font-size: 24px;

    .job-desc-container {
      p {
        font-size: 24px;
      }

      ul {
        padding-left: 18px;
      }

      li {
        font-size: 24px;
      }
    }
  }

  .job-desc-container :is(h1, h2, h3, h4, h5, h6) {
    font-size: 36px;
  }

}

@media (min-width:1200px) and (max-width: 1500px) {

  .root-position-job-detail {

    .job-desc-container {

      h2,
      h4 {
        span {
          font-size: 32px !important;

        }
      }

      span {
        font-size: 24px !important;

        strong {
          color: #FF7900;
        }
      }

      // font-size: 28px;

      p {
        font-size: 28px;
      }

      ul {
        padding-left: 18px;
      }

      li {
        font-size: 28px;
      }
    }
  }


  .job-desc-container :is(h1, h2, h3, h4, h5, h6) {
    font-size: 40px;
    line-height: 40%;
    margin-top: 80px !important;
    padding-bottom: 22px;
  }

}

@media (min-width:1500px) and (max-width: 1800px) {
  .root-position-job-detail {

    .job-desc-container {
      margin-top: 80px !important;
      padding-bottom: 22px;

      h2,
      h4 {
        span {
          font-size: 36px !important;

        }
      }

      span {
        font-size: 28px !important;

        strong {
          color: #FF7900;
        }
      }
    }
  }
}