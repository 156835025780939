@import './common';

.cs-testimonial {
    padding: 170px 0px 60px;

    .title {
        font-weight: 350;
        font-size: $subContentLg;
        line-height: 150%;
        color: $whiteColor;
    }
    .logos-container-parent {
        display: flex;
        align-items: flex-end;
    }
}

@media (max-width: 640px) {
    .cs-testimonial-logo-container {
        margin: 0 auto;    
    }

    .cs-testimonial {
        padding: 40px 0px 20px;
        .title {
            font-weight: 400;
            font-size: $subContentMobile;
            line-height: 150%;
    
            color: $whiteColor;
        }

        .logo-container-parent {
            margin: 0 auto;
        }
        
        .logo-container {
            width: 150px;
            img {
                max-width: 100%;
            }
        }
        .cs-testimonial-logo-grid {
            column-gap: 20px !important;
        }
    } 
}

@media (max-width: 900px) {
    #cs-myfitmix.cs-testimonial,
    #cs-iuvando.cs-testimonial,
    #cs-light-pong.cs-testimonial,
    #cs-togomed.cs-testimonial
    {
        padding: 56px 0px 10px;
        .logo-container {
            width: 100%;
            max-width: max-content;
            margin-bottom: 16px;
            img {
                max-width: 100%;
            }
            #video-medical-webapp-solutions {
                width: 100%;
                display: block;
            }
        }
    }
}

@media (max-width:940px)and (orientation: landscape) {
    #cs-myfitmix.cs-testimonial,
    #cs-iuvando.cs-testimonial,
    #cs-light-pong.cs-testimonial,
    #cs-togomed.cs-testimonial {
        .cs-testimonial-grid {
            justify-content: center;
        }
        .logo-container {
            width: 100%;
            max-width: 540px;
            img {
                max-width: 100%;
            }
            #video-medical-webapp-solutions {
                width: 100%;
                display: block;
            }
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-testimonial {
        padding: 110px 0px 60px;
    
        .title {
            font-weight: 400;
             font-size: 24px;
            line-height: 150%;
           color: $whiteColor;
        }
        .cs-testimonial-grid {
            column-gap: 20px !important;

            .cs-testimonial-logo-container {
                max-width: 48%;
                .logo-container {
                    img {
                        max-width: 100%;
                    }
                }
            }
            .cs-testimonial-logos-cont {
                .cs-testimonial-logo-grid {
                    column-gap: 20px !important;
                }
            }
        }
        .logos-container-parent {
            width: 48%;
            display: flex;
            align-items: flex-end;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-testimonial {
        padding: 110px 0px 60px;
    
        .title {
            font-weight: 400;
            font-size: $subContentLgs;
            line-height: 150%;
            /* or 48px */
            color: $whiteColor;
        }
        .cs-testimonial-grid {
            column-gap: 52px !important;
        }
        .cs-testimonial-logo-container {
            max-width: 48%;
            .logo-container {
                img {
                    max-width: 100%;
                }
            }
        }
        .logos-container-parent {
            width: 43%;
            display: flex;
            align-items: flex-end;
        }
    }
}