@import 'common';

.home-success {
    // padding-top: 500px;
    padding-top: 5%;
    // padding-bottom: 5%;
    padding-bottom: 223px;
    width: 85%;
    margin: 0 auto;
    color: #FFFFFF;

    .icon-animation-container {
        margin-top: 104px;
    }

    .header {
        font-size: $mainHeaderLg;
        font-weight: 700;
        line-height: 133%;
        min-height: 180px;

    }

    .desc {
        font-weight: normal !important; //450;
        font-size: $subContentLg;
        padding-left: 32%;
        line-height: 150%;

        .desc-item {
            display: inline;
            padding-right: 5px;
        }
    }

    .title {
        font-weight: normal !important; //500;
        font-size: 28px;
        margin-top: 20px;
    }

    .icon-text {
        font-size: 28px;
    }

    .main-content {
        margin-top: 5%;
    }
}

@media (max-width:640px) {
    .home-success {
        width: 90%;
        padding-bottom: 80px;
        .header {
            padding-top: 0;
            font-size: $mainHeaderSm;
            min-height: 80px;
        }

        .desc {
            font-size: 16px;
            padding-left: 0;

            .desc-item {
                display: block;
                line-height: 1.5;
                padding-bottom: 15px;
            }
        }

        .icon-text {
            font-size: 10px;
            margin-top: 10px;
            text-align: center;
        }

        .icon-animation-container {
            margin-top: 0;
            padding-top: 40px;
            justify-content: space-between;
        
            .icon-item {
                padding: 0;
                width: 45px;
                height: 45px;
                max-width: 45px;
        
                .css-12ou638 {
                    height: auto;
                }
                
                .success-svg {
                    width: 100%;
                    height: auto;
                    background-size: contain;
                }
            }
        }

    }
}

@media (min-width:641px) and (max-width:899px) {
    .home-success {
        .header {
            font-size: $mainHeaderSm;
            min-height: 80px;
        }
        .desc {
            font-size: 16px;
        }
        .icon-text {
            font-size: 16px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .home-success {
        .header {
            font-size: $mainHeaderMd;
            min-height: 110px;
        }
        .desc {
            font-size: $subContentMd;
        }
        .icon-text {
            font-size: 20px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .home-success {
        .header {
            font-size: $mainHeaderLgs;
            min-height: 140px;
        }
        .desc {
            font-size: $subContentLgs;
        }
        .icon-text {
            font-size: 24px;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .home-success {
        .header {
            font-size: $mainHeaderLgs;
            min-height: 140px;
        }
        .desc {
            font-size: $subContentLgs;
        }
        .icon-text {
            font-size: 24px;
        }

    }
}