@import 'common';

#about-us {
    margin: 0px auto;
    width: 85%;
    padding: 8% 0%;
}

.about-us-title {
    font-size: $mainHeaderLg;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 50px;
    .lb {
        display: block;
    }
}

.about-us-card-text {
    font-size: $mainHeaderLg;
    font-weight: 700;
}

.about-us-content {
    font-size: $subContentLg;
    font-weight: 500px !important;
    color: #FFFFFF;
    line-height: 150%;
    .content-item {
        display: inline;
        padding-right: 5px;
    }
}

.about-us-button {
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: normal;
    border: 2px solid #FF7900;
    background: rgba(0, 0, 0, .25);
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    &.on-touch,
    &:hover {
        background: #FF7900;
        border-color: #FFFFFF;
    }
}

.about-us-button-container {
    margin: 70px 0px 0px 0px;
}

@media (min-width:1600px) and (max-width: 1950px) {
  #about-us {
    .home-about-video {
        width: 800px !important;
        height: 450px !important;
    }
}
}

@media (min-width:1501px) and (max-width: 1600px) {
    #about-us {
        padding: 6% 0%;
    }
    .about-us-button-container {
        margin: 50px 0px 0px 0px;
    }
    .about-us-title {
        font-size: $mainHeaderLgs;
        margin-bottom: 50px;
    }

    .about-us-card {
        border-radius: 55px - (0.375*55) !important;
    }

    .about-us-card-text {
        font-size: $mainHeaderLgs;
        .home-about-video {
            width: 500px !important;
            height: 300px !important;
        }
    }
    .about-us-content {
        font-size: $subContentLgs;
    }
    .about-us-button {
        line-height: 26px - (0.375*40);
        padding: 12px 14px;
        border-radius: 6px;
        font-size: 18px;
    }
}


@media (min-width:1200px) and (max-width: 1500px) {
    #about-us {
        padding: 6% 0%;
    }
    .about-us-button-container {
        margin: 50px 0px 0px 0px;
    }
    .about-us-title {
        font-size: $mainHeaderLgs;
        margin-bottom: 50px;
    }
    .about-us-card {
        border-radius: 55px - (0.375*55) !important;
    }
    .about-us-card-text {
        font-size: $mainHeaderLgs;
        .home-about-video {
            width: 400px !important;
            height: 230px !important;
        }
    }
    .about-us-content {
        font-size: $subContentLgs;
        padding-right: 30px;
    }
    .about-us-button {
        line-height: 26px - (0.375*40);
        border-radius: 6px;
        font-size: 18px;
    }
}


@media (min-width:900px) and (max-width: 1200px) {

    .about-us-title {
        font-size: $mainHeaderMd;
        margin-bottom: 30px;
    }

    .about-us-card {
        border-radius: 55px - (0.53125*55) !important;
    }
    .about-us-card-text {
        font-size: $mainHeaderMd;
    }
    .about-us-content {
        font-size: $subContentMd;
    }
    .about-us-button-container {
        margin: 50px 0px 0px 0px;
    }
    .about-us-button {
        line-height: 18px;
        padding: 12px 14px;
        border-radius: 6px;
        font-size: 15px;
    }
    #about-us {
        .video-wrapper {
            iframe {
                width: 100%;
            }
        }
        .about-us-content {
            padding-right: 15px;
        }
    }
}

@media (min-width:641px) and (max-width: 899px) {

    .about-us-card {
        border-radius: 55px - (0.66*55) !important;
    }

    .about-us-card-text {
        font-size: $mainHeaderSm;
    }

    .about-us-content {
        font-size: 16px;
    }
}

@media (max-width: 640px) {
    #about-us {
        width: 90%;
    }
    .about-us-card {
        border-radius: 55px - (0.66*55) !important;
    }
    .about-us-card-text {
        font-size: $mainHeaderSm;
    }
    .about-us-button-container {
        margin: auto;
    }
    .about-us-content {
        font-size: 16px;
        .content-item {
            display: block;
            line-height: 1.5;
            padding-bottom: 15px;
        }
    }
}

@media (max-width:900px) {
    #about-us {
        width: 90%;
        margin: 39px auto 48px;
        .video-wrapper {
            width: 100%;
            position: relative;
            padding-top: 75%;
            background-color: transparent;
            overflow: hidden;
            box-sizing: border-box;
            margin-top: 40px;
            margin-bottom: 49px; 
            iframe {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                border: 2px solid #FFFFFF;
            }
            video {
                border-radius: 16px;
            }
        }
        .about-us-content {
            padding-right: 15px;
        }
    }
    .about-us-button {
        display: inline-flex;
        height: 44px;
        padding: 12px 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
    }
    .about-us-button-container {
        margin: 0px 0px 0px 0px;
    }
    .about-us-title {
        font-size: 24px;
        line-height: 133%;
        margin-bottom: 16px;
        .lb {
            display: inline;
        }
    }
}

@media (max-width:940px)and (orientation: landscape) {
    #about-us {
        width: 50%; 
        margin: 39px auto 48px;
        .about-us-content {
            padding-right: 15px;
        }
        .video-wrapper {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            padding-left: 15px;
            iframe {
                box-sizing: border-box;
                border-radius: 16px;
                border: 2px solid #FFFFFF;
            }
        }
    }    
}