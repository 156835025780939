@import './common.scss';

.about-banner {
    width: 85%;
    margin: 0 auto;
    // padding-top: 8%;
    // padding-bottom: 6%;
    // height: 630px;

    .about-banner-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 150%;
        // color: #FFFFFF; // Requirement changed
        color: #FF7900;
    }

    .c-title {
        color: #FF7900 !important;
    }

    .about-banner-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 60px */

        padding-top: 36px;
        color: #FFFFFF;
        width: 80%;
    }

    .about-banner-desc1 {
        margin-top: 10PX;
    }
}

@media (max-width:640px) {
    .about-banner {
        width: 90%;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 71px auto 0;

        .about-banner-title {
            font-size: $mainHeaderMobileLg;
        }

        .about-banner-desc {
            width: 90%;
            max-width: 480px;
            font-weight: 300;
            font-size: $subContentMobile;
            padding-top: 18px !important;
        }
    }
}

@media (min-width:641px) and (max-width:899px) {
    .about-banner {
        padding-top: 0%;

        .about-banner-title {
            font-size: $mainHeaderSm;
        }

        .about-banner-desc {
            font-size: $subContentSm;
            padding-top: 20px;
        }

        .down-arrow {
            margin-top: 20px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .about-banner {
        padding-top: 0%;

        .about-banner-title {
            font-size: $mainHeaderMd;
        }

        .about-banner-desc {
            font-size: $subContentMd;
            padding-top: 70px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .about-banner {
        padding-top: 0%;

        .about-banner-title {
            font-size: $mainHeaderLgs;
        }

        .about-banner-desc {
            font-size: $subContentLgs;
            padding-top: 35px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .about-banner {
        .about-banner-desc {
            padding-top: 6px;
        }
    }
}