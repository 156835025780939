.service-bg1 {
    position: relative;
    //removing dot bg for banner
    background-color: #1a203c;
    // background-image: url('../assets/About/about-banner-bg1.png');
    // background-color: rgba(0, 0, 0, 0.5);
}

.root-services {
    .home-partners {
        padding-top: 0%;
    }

    .service-name-left {
        font-weight: 500;
        font-size: 40px;
        line-height: 150%;
        color: #FF7900;
        transform: rotate(-90deg);
        position: fixed;
        top: 50%;
        left: -3%;
        z-index: 1;

    }

    #tsparticles {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .top-bg {
        width: 100%;
        top: 0;
        padding-top: 185px;
        padding-bottom: 0%;
        position: relative;
        //removing dot bg for banner
        // background: linear-gradient(90deg, rgba(22, 26, 66, 0) 0%, rgba(22, 26, 66, 0.8) 11.46%)
    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-bg1 {
        position: relative;
    }

    .root-services {
        #tsparticles {
            height: 100%;
        }

        .top-bg {
            padding-top: 120px;
        }

        .service-name-left {
            font-size: 24px;
            top: 25%;
            left: -7%;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .root-services {
        .service-name-left {
            font-size: 34px;
            left: -7%;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .root-services {
        .home-partners {
            column-gap: 75px !important;
        }

        .service-name-left {
            font-size: 36px;
            left: -5%;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .root-services {
        .home-partners {
            column-gap: 75px !important;
        }
    }
}

@media (max-width: 640px) {
    .root-services {
        .top-bg {
            padding-top: 170px;
        }
    }
}