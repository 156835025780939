@import 'common';

.service-content-card {
    width: 85%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 70px;
}

.service-content-card-item {
    background: linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100.83%);
    box-shadow: 0px 0px 15px rgba(0, 59, 232, 0.75);
    backdrop-filter: blur(4px);
    border-radius: 32px;
    padding: 50px;
    width: 100%;
    margin-bottom: 48px;
}

.service-content-card-title {
    font-weight: 700;
    font-size: $cardMainLg;
    line-height: 120%;
    margin-bottom: 15px;
    color: #FF7900;
}

.service-content-card-desc {
    font-weight: 400;
    font-size: $cardContentLg;
    line-height: 150%;
    /* or 60px */


    color: #FFFFFF;

}

.service-content-main-title {
    font-weight: 700;
    font-size: $mainHeaderLg;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.service-content-sub-title {
    font-weight: 700;
    font-size: $subHeaderLg;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.item-space {
    margin-bottom: 40px;
}

.service-content-card-end-desc {
    margin-top: 150px;
    font-weight: 700;
    font-size: $mainHeaderLg;
    line-height: 133%;
    color: #FFFFFF;
}

@media (max-width: 900px) {

    .service-content-card-item {
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 32px;
        box-shadow: 0px 0px 4px rgba(0, 59, 232, 0.2);
        background: linear-gradient(90deg, #1C2898 0%, #1E287C 100.83%);
    }

    .item-space {
        margin-bottom: 0;
        row-gap: 0 !important;
        &>div:last-child {
            .service-content-card-item {
                margin-bottom: 24px;
            }
        }
    }

    .service-content-sub-title {
        margin-bottom: 16px;
    }

}

@media (max-width: 640px) {
    .item-space {
        row-gap: 0 !important;
    }
    .service-content-card {
        width: 90%;
        padding: 60px 0 0;
    }
    .service-content-main-title {
        font-weight: 500;
        font-size: $mainHeaderMobileMd;
        line-height: 1.5;
        color: #FFFFFF;
        margin-bottom: 30px;
    }

    .service-content-card-title {
        font-weight: 500;
        font-size: $mainHeaderSm;
    }

    .service-content-sub-title {
        font-size: $sub2HeaderMd;
    }

    .service-content-card-desc {
        font-size: $subContentMobile;
    }

    .service-content-card-end-desc  {
        font-size: $mainHeaderSm;
        margin-top: 80px;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-content-card-item {
        padding: 28px;
    }
    .service-content-card-end-desc {
        margin-top: 100px;
        font-size: $mainHeaderSm;
    }
    .service-content-main-title {
        font-size: $mainHeaderSm;
        margin-bottom: 50px;
    }

    .service-content-sub-title {
        font-size: $subHeaderSm;
    }

    .service-content-card-title {
        font-size: $cardMainSm;
    }

    .service-content-card-desc {
        font-size: $cardContentSm;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .service-content-card-item {
        padding: 40px;
    }
    .service-content-card-end-desc {
        margin-top: 100px;
        font-size: $mainHeaderMd;
    }
    .service-content-main-title {
        font-size: $mainHeaderMd;
        margin-bottom: 50px;
    }

    .service-content-sub-title {
        font-size: $subHeaderMd;
    }

    .item-space {
        margin-bottom: 32px;
    }

    .service-content-card-title {
        font-size: $cardMainMd;
    }

    .service-content-card-desc {
        font-size: $cardContentMd;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .service-content-card-item {
        padding: 40px;
    }
    .service-content-card-end-desc {
        margin-top: 100px;
        font-size: $mainHeaderLgs;
    }

    .service-content-main-title {
        font-size: $mainHeaderLgs;
        margin-bottom: 50px;
    }

    .service-content-sub-title {
        font-size: $subHeaderLgs;
    }

    .item-space {
        margin-bottom: 40px;
    }

    .service-content-card-title {
        font-size: $cardMainLgs;
    }

    .service-content-card-desc {
        font-size: $cardContentLgs;
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .service-content-card-item {
        padding: 40px;
    }
    .service-content-card-end-desc {
        margin-top: 100px;
        font-size: $mainHeaderLgs;
    }

    .service-content-main-title {
        font-size: $mainHeaderLgs;
        margin-bottom: 50px;
    }

    .service-content-sub-title {
        font-size: $subHeaderLgs;
    }

    .item-space {
        margin-bottom: 40px;
    }

    .service-content-card-title {
        font-size: $cardMainLgs;
    }

    .service-content-card-desc {
        font-size: $cardContentLgs;
    }
}