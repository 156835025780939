body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Cookie banner  */

.react-cookie-law-dialog {
  top: auto !important;
  bottom: 0;
  display: flex;
  padding: 35px !important;
  background-color: #353B5F !important;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.custom-cookie-law-dialog {
  color: white !important;
  width: 50%;
  box-sizing: border-box;
}
.react-cookie-law-container {
  width: 50%;
  box-sizing: border-box;
  padding-left: 2%;
}

.custom-cookie-law-dialog-head {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.custom-cookie-law-dialog-content {
  font-size: 20px;
  line-height: 140%;
  font-weight: 500;
}

.privacy-policy {
  cursor: pointer;
  border-bottom: 2px solid #FFF;
}

.react-cookie-law-policy {
  display: none;
}

.react-cookie-law-button-wrapper {
  float: none !important;
}
.react-cookie-law-option-wrapper:first-child{
  margin-left: 0 !important;
}
.react-cookie-law-option-wrapper label {
  padding-left: 32px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  color: white !important;
}

.react-cookie-law-option-checkbox {
  top: 2px !important;
  border-radius: 5.638px;
}

.react-cookie-law-option-checkbox:checked {
  background-color: red;
  accent-color: #FF7900;
}


.react-cookie-law-msg {
  color: white !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 26px;
  margin-top: -3px;
  margin-bottom: 10px;
}

.react-cookie-law-msg .mobile-title {
  display: none;
}

.react-cookie-law-msg .desktop-title {
  display: block;
}

.react-cookie-law-description {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #FFF;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.react-cookie-law-option-checkbox::after {
  margin-right: 30px;
}

.react-cookie-law-select-pane {
  padding-bottom: 22px !important;
}

.custom-cookieview-all-preferences.show-in-mobile {
  display: none;
}

/* button */
.custom-cookieview-all-preferences-btn,
.react-cookie-law-accept-selection-btn {
  display: inline-block;
  border: 1px solid #FF7900;
  border-radius: 5px;
  padding: 10px 20px !important;
  font-size: 17px !important;
  background-color: transparent !important;
  font-weight: 500 !important;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  padding: 3px;
  min-width: 80px;
  font-size: 17px;
  color: #ffffff;
  box-sizing: border-box;
}
.custom-cookieview-all-preferences-btn:hover,
.react-cookie-law-accept-selection-btn:hover {
  border-color: #D9D9D9 !important;
  background-color: #FF7900 !important;
}

.react-cookie-law-accept-all-btn {
  background-color: #FF7900 !important;
  border-radius: 5px;
  padding: 10px 20px !important;
  border: none !important;
  text-transform: uppercase;
  font-size: 17px !important;
}

/* Hide default checkbox */
.container {
  cursor: pointer !important;
}
.container input {
  position: absolute !important;
  top: unset  !important;
  left: unset  !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #808080;
  border-radius: 6px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #FF7900;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-cookie-law-option-wrapper:first-child .container {
  pointer-events: none;
}

.react-cookie-law-option-wrapper:first-child .container input:checked ~ .checkmark {
  background-color: #808080;
}

.react-cookie-law-option-wrapper {
  float: none !important;
}

/* End button */
@media (max-width: 1024px) {
  .react-cookie-law-dialog {
    flex-direction: column;
    border-radius: 22.55px 22.55px 0px 0px;
    padding: 20px 16px 25px !important;
  }
  .custom-cookie-law-dialog {
    width: 100%;
  }
  .react-cookie-law-container {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
  }
  .custom-cookie-law-dialog-head {
    font-size: 26px;
  }
  .react-cookie-law-container .react-cookie-law-msg,
  .react-cookie-law-container .react-cookie-law-description,
  .react-cookie-law-container .react-cookie-law-select-pane {
    display: none !important;
  }
  .react-cookie-law-container.show-in-mobile .react-cookie-law-msg,
  .react-cookie-law-container.show-in-mobile .react-cookie-law-description,
  .react-cookie-law-container.show-in-mobile .react-cookie-law-select-pane {
    display: block !important;
  } 

  .react-cookie-law-accept-selection-btn
  {
    display: none !important;
  }
  .custom-cookieview-all-preferences {
    margin-top: 32px;
    margin-bottom: 22px;
  }
  .custom-cookieview-all-preferences.show-in-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-cookie-law-accept-all-btn,
  .react-cookie-law-accept-selection-btn,
  .custom-cookieview-all-preferences-btn {
    width: 100%;
    font-size: 17px !important;
  }
  .react-cookie-law-accept-all-btn,
  .react-cookie-law-accept-selection-btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 22px;
  }
  .custom-cookie-law-dialog-content {
    font-size: 16px;
    line-height: 150%;
  }
  .hide-in-mobile {
    display: none !important;
  }
  .react-cookie-law-option-wrapper:last-child {
    display: block !important;
    margin-top: 29px;
    margin-left: 0 !important;
  }

  .react-cookie-law-msg .mobile-title {
    display: block;
  }
  
  .react-cookie-law-msg .desktop-title {
    display: none;
  }
}
