@import 'common';

.home-solutions {
    width: 85%;
    margin: 0 auto;
    padding-top: 2%;
    color: #FFFFFF;
    position: relative;
    .header {
        font-size: $mainHeaderLg;
        font-weight: 700;
        line-height: 133%;
        min-height: 180px;
        
    }
    .desc {
        font-weight: normal !important;
        font-size: $subContentLg;
        padding-left: 42%;
        line-height: 150%;
        .desc-item {
            display: inline;
            padding-right: 5px;
        }
    }

    .title {
        font-size: 28px;
        width: 80%;
        margin: 0 auto;
    }
    .value {
        font-size: 50px;
        font-weight: 700;
    }
    .main-content {
        flex-wrap: nowrap;
        margin-top: 6%;
        margin-bottom: 10%;
    }
    .content {
        height: 270px;
        padding: 40px;
        padding-left: 0px;
        position: relative;
    }
    .content-inner {
        width: 285px;
        position: absolute;
        color: #878D90;
    }
    .half-circle {
        display: none;
        top: 0%;
        position: absolute;
        width: 266px;
        height: 133px;
        border-top-left-radius: 150px;
        border-top-right-radius: 150px;
        border: 10px solid #FF7900;
        border-bottom: 0;
    }
    .half-down-circle {
        display: none;
        top: 57%;
        position: absolute;
        width: 266px;
        height: 133px;
        border-top-left-radius: 150px;
        border-top-right-radius: 150px;
        border: 10px solid #FF7900;
        border-bottom: 0;
        transform: rotate(-180deg);
    }
    .border-circle {
        background-color: #FF7900;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
    }
    .left-circle {
        right: -10px;
        bottom: -5px;
    }
    .right-circle {
        bottom: -5px;
        left: -10px;
    }
}

@media (max-width:640px) {
    .home-solutions {
        width: 90%;
        padding-top: 23px;
        .header {
            font-size: $mainHeaderMobileMd;
            min-height: 70px;
            font-weight: 600;
        }
        .desc {
            padding-left: 0;
            padding-top: 20px;
            padding-bottom: 40px;
            font-size: $subContentMobile;
            
            .desc-item {
                display: block;
                line-height: 1.5;
                padding-bottom: 10px 0;
            }
        }
        .main-content {
            display: block;
            margin-top: 0;
            padding: 0 20px;
            
            .content {
                padding: 0;
                width: 140px;
                height: 140px;
                margin-left: auto;
                .half-down-circle, .half-circle {
                    width: 140px;
                    height: 68px;
                    top: auto;
                    bottom: 0;
                    display: none;
                    position: absolute;
                    border-top-left-radius: 150px;
                    border-top-right-radius: 150px;
                    border: 6px solid #FF7900;
                    border-bottom: 0;
                    transform: rotate(-180deg);
                }
                
                .content-inner {
                    top: auto;
                    bottom: 30px;
                    width: 140px;
                    height: auto;
                    text-align: center;
                    .title {
                        font-size: 14px;
                        line-height: 1.7;
                    }
                    .value {
                        font-size: 24px;
                        margin-bottom: 15px;
                    }
                }
            }

            .content:nth-child(2n+1) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

        }

        .half-down-content {
            top: -20px;
        }
        .half-content {
            top: 45px;
        }
        .border-circle {
            width: 0;
            height: 0;
        }

    }
}

@media (min-width:641px) and (max-width:899px) {
    .home-solutions {
        padding-top: 23px;
        .main-content {
            margin-bottom: 5%;
        }
        .header {
            font-size: $mainHeaderSm;
            min-height: 70px;
        }
        .desc {
            font-size: $subContentSm;
            padding-left: 40%;
        }
        .content {
            padding: 0px;
        }
        .content-inner {
            width: 110%;
        }
        .half-circle,
        .half-down-circle {
            width: 100%;
            height: 20%;
            border-top-left-radius: 150px;
            border-top-right-radius: 150px;
            border: 6px solid #FF7900;
            border-bottom: 0;
        }
        .half-down-circle {
            top: 52%;
        }
        .half-circle {
            top: 26%;
        }

        .left-circle {
            right: -6px;
            bottom: -2px;
        }

        .border-circle {
            width: 6px;
            height: 6px;
        }
        .right-circle {
            bottom: -2px;
            left: -6px;
        }
        .title {
            font-size: 12px;
        }

        .value {
            font-size: 20px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .home-solutions {
        .header {
            font-size: $mainHeaderMd;
            min-height: 130px;
        }

        .desc {
            font-size: $subContentMd;
            padding-left: 40%;
        }

        .content {
            padding: 0px;
        }
        .content-inner {
            width: 170px;
        }
        .half-circle,
        .half-down-circle {
            width: 100%;
            height: 30%;
            border-top-left-radius: 150px;
            border-top-right-radius: 150px;
            border: 6px solid #FF7900;
            border-bottom: 0;
        }
        .half-down-circle {
            top: 52%;
        }
        .half-circle {
            width: 150px;
            height: 80px;
            top: 15%;
        }
        .left-circle {
            right: -6px;
            bottom: -2px;
        }
        .border-circle {
            width: 6px;
            height: 6px;
        }
        .right-circle {
            bottom: -2px;
            left: -6px;
        }
        .title {
            font-size: 18px;
        }
        .value {
            font-size: 28px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .home-solutions {
        .header {
            font-size: $mainHeaderLgs;
            min-height: 150px;
        }

        .desc {
            font-size: $subContentLgs;
            padding-left: 38%;
        }

        .content {
            padding: 0px;
        }

        .content-inner {
            width: 200px;
        }

        .half-down-circle {
            width: 180px;
            height: 92px;
        }

        .half-circle {
            width: 180px;
            height: 92px;
        }

        .title {
            font-size: 22px;
        }

        .value {
            font-size: 36px;
        }
    }
}

@media (min-width:1500px) and (max-width: 1600px) {
    .home-solutions {
        .header {
            font-size: $mainHeaderLgs;
            min-height: 150px;
        }

        .desc {
            font-size: $subContentLgs;
            padding-left: 38%;
        }

        .content {
            padding: 0px;
        }

        .half-down-circle,
        .half-circle {
            width: 220px;
            height: 120px;
        }

        .half-circle {
            top: 5%;
        }

        .content-inner {
            width: 240px;
        }

        .title {
            font-size: 22px;
        }

        .value {
            font-size: 36px;
        }
    }
}