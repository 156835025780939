@import 'common';

.value-item-left {
    padding-left: 90px;
}

.service-page-value {
    width: 85%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 50px;
    .service-page-value-main-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 130%;
        color: #FFFFFF;
        margin-bottom: 100px;
    }

    .value-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 120%;
        width: 85%;
        color: #FFFFFF;
    }

    .value-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        width: 90%;
        padding-top: 30px;
        color: #FFFFFF;

        p {
            margin: 0px !important;
        }
    }

    .number-align {
        font-weight: 700;
        font-size: 50px;
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .value-bg-img-style {
        mix-blend-mode: lighten;
    }
    .value-bg-img-style-tran{
        transform: matrix(-1, 0, 0, 1, 0, 0);

    }

    .value-bg-img-style img {
        width: 100%;
    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-page-value {
        padding-top: 20px;
        padding-bottom: 20px;
    
        .value-title {
            font-size: $mainHeaderSm;
        }
    
        .value-desc {
            font-size: $subContentSm;
        }
    }

}

@media (min-width:900px) and (max-width: 1200px) {
    .service-page-value {
        padding-top: 50px;
        padding-bottom: 50px;
    
        .value-title {
            font-size: $mainHeaderMd;
        }
    
        .value-desc {
            font-size: $subContentMd;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {

    .service-page-value {
        padding-top: 50px;
        padding-bottom: 50px;
    
        .value-title {
            font-size: $mainHeaderLgs;
            width: 80%;
        }
    
        .value-desc {
            font-size: $subContentLgs;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {

    .service-page-value {
        padding-top: 50px;
        padding-bottom: 50px;
    
        .value-title {
            font-size: $mainHeaderLgs;
            width: 75%;
        }
    
        .value-desc {
            font-size: $subContentLgs;
        }
    }
}

@media (max-width: 640px) {
    .value-item-wrapper {
        margin-bottom: 40px;

        &:nth-child(2) {
            margin-bottom: 100px;
        }
    }
    
    .value-item {
        margin: 0 auto;
    }
    
    .value-item-left {
        padding-left: 0;
    }

    .service-page-value {
        width: 90%;
        padding-bottom: 0;
    
        .value-title {
            width: 100%;
            font-size: $mainHeaderMobileMd;
        }

        .value-desc {
            width: 100%;
            font-weight: 400;
            padding-top: 10px;
            font-size: $subContentMobile;
        }

        .value-bg-img-style {
            display: none;
        }
    }
}