@import './common.scss';
.about-video {
    width: 85%;
    margin: 0 auto;
    margin-bottom: $marginSectionBottom;

    .title {
        margin-bottom: $marginMainTitleBottom;
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 90px;
        color: #FFFFFF;
    }
}

@media (max-width:900px) {
    .about-video {
        width: 90%;
        margin: 40px auto;
        .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin-bottom: 24px;
        }

        .video-wrapper {
            position: relative;
            padding-top: 75%;
            background-color: transparent;
            overflow: hidden;
            box-sizing: border-box;
            iframe {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                border: 2px solid #FFFFFF;
            }
            video {
                border-radius: 16px;
            }
        }
    }
}
@media (min-width:900px) and (max-width: 1600px) {
    .about-video {
        .video-wrapper {
            iframe {
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
}
@media (max-width:940px)and (orientation: landscape) {
    .about-video {
        width: 80%; 
        margin: auto;
        .video-wrapper {
            text-align: center;
            iframe {
                box-sizing: border-box;
                border-radius: 16px;
                border: 2px solid #FFFFFF;
            }
        }
    }    
}

@media (min-width:900px) and (max-width: 1200px) {
    .about-video {
        .title {
            font-size: $mainHeaderMd;
        }
    }
}

@media (min-width:1200px) and (max-width: 1600px) {
    .about-video {
        .title {
            font-size: $mainHeaderLgs;
        }
        .video-wrapper {
            .about-youtube-video {
                width: 80%;
                height: 522px;
            }
        }
    }
}
@media (min-width:1500px) and (max-width: 1600px) {
    .about-video {
        .video-wrapper {
            .about-youtube-video {
                width: 72%;
                height: 530px;
            }
        }
    }
}
@media (min-width:1900px) {
    .about-video {
        .video-wrapper {
            .about-youtube-video {
                width: 72%;
                height: 650px;
            }
        }
    }
}