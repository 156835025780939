@import 'common';

.service-list {
    .services {
        margin-top: 5%;
    }
    .heading {
        font-size: $mainHeaderLg;
        font-weight: 700;
        color: $whiteColor;
        margin: 0px 150px;
        position: sticky;
        top: 80px;
        z-index: 1;
        background-image: radial-gradient(circle at center, rgba(275, 275, 275, 0.1) 2px, transparent 0), linear-gradient(to right, rgba(24, 22, 43, 1), rgb(28, 43, 79));
        background-repeat: repeat, repeat;
        background-size: 20px 20px, cover;
    }

    .service {
        width: 80%;
        margin: 0 auto;
    }

    .scroll-container {
        margin-top: 300px;
    }

    .chip-tags {
        font-size: 20px;
        padding: 15px 6px;
    }
    .stack {
        position: relative;
        gap: 2%;
    }
    #buttons-software {
        .stack {
            width: 100%;
        }
    }
}

.service {
    .service-heading {
        font-size: $subHeaderLg;
        font-weight: 700;
        color: $orangeColor;
    }

    .tags {
        color: $blackColor;
        font-size: 20px;
        font-weight: 500;
        margin-top: 37px;

        .chip-tags:not(:first-child) {
            margin-left: 25px;
        }
    }

    .description {
        color: $whiteColor;
        font-size: $subContentLg;
        font-weight: normal !important;
        margin-top: 24px;
        line-height: 150%;

        br {
            content: "";
            margin: 2em;
            display: block;
            font-size: 24%;
        }

        .desc-item {
            display: inline;
            padding-right: 5px;
        }
        .desc-item-paragraph {
            margin: 12px 0 0 0;
        }
    }

    .buttons {
        margin-top: 64px;
        position: relative;
        .curved-arrow-wrapper {
            position: absolute;
            width: 69px;
            height: 69px;
            top: 50%;
            left: 100%;
            transform: translate(50%, -100%);
            margin: 0; 
            .curved-arrow {
                width: 100%;
            }
        }
    }

    .button {
        background: $orangeColor;
        color: $whiteColor;
        border-radius: 8px;
        font-size: 24px;
        font-weight: 500;
        padding: 16px 36px;
        margin: 0;
        box-sizing: border-box;
        line-height: 26px;
        text-transform: uppercase;
    }

    button:hover {
        background: $orangeColor;
        border-radius: 8px;
        box-shadow: 0 0 2px 2px $highlightColor;
        color: $whiteColor;
        font-size: 24px;
        font-weight: 500;
    }

}

.show-for-mobile {
    display: none;
} 
.hide-for-mobile {
    display: inline-block;
}

@media (max-width:900px) {

    .line-break {
        display: block;
        margin-bottom: 12px;
    }
    .show-for-mobile {
        display: inline-block;
    }
    .hide-for-mobile {
        display: none;
    }
    .service {
        .buttons {
            width: 100%;
            margin-top: 30px;
            .curved-arrow-wrapper {
                width: 42px;
                height: 42px;
                .curved-arrow {
                    width: 100%;
                }
            }
        }
    }

    .button {
        font-size: 16px !important;
        padding: 16px 20px;
        min-width: 144px;
        height: 44px !important;
        width: auto;
    }
    button:hover {
        font-size: 10px;
        font-weight: 600;
    }
    .service-list {
        .stack {
            position: relative;
            grid-gap: 2%;
            gap: 0;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        
        .service {
            width: 100%;
            margin: 0;
            padding-bottom: 48px;
            .service-image {
                display: none;
            }
            canvas {
                width: 100% !important;
            }
            .button {
                margin-bottom: 16px;
                &:first-child, &:nth-child(3) {
                    margin-left: 0;
                    margin-right: 16px;
                }
            }
        }
    }
    #buttons-software {
        .button {
            width: 45%;
        }
    }
    .service {
        .button {
            padding: 13px 22px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14px */
            text-transform: uppercase;
        }
    }
}

@media (max-width:640px) {
    .service-list .services {
        row-gap: 0;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
    .MuiGrid-container.service-list {
        width: 90% !important;
        margin: 0 auto !important;
        margin-left: auto !important;
        padding-top: 40px;

        .heading {
            margin: 0;
            position: static;
            font-weight: 500;
            font-size: $mainHeaderMobileMd;
            background-image: none !important;
        }
        .chip-tags {
            font-size: 16px;
            padding: 15px 6px;
        }
    }

    .service {
        .service-image-remote-teams {
            height: 225px
        }

        .service-image-software {
            height: 225px
        }

        .service-image-uiux {
            height: 225px
        }

        .service-image-branding {
            height: 180px
        }

        .service-heading {
            font-size: $mainHeaderSm;
            font-weight: 500;
            color: $orangeColor;
        }
        .tags {
            color: $blackColor;
            font-size: 10px;
            font-weight: 500;
            margin-top: 20px;
        }
        .description {
            color: $whiteColor;
            font-size: $subHeaderXs;
            font-weight: 400;
            margin-top: 20px;
            line-height: 1.6;
            .desc-item {
                display: block;
                line-height: 1.5;
                margin-bottom: 12px;
            }
        }

    }
}

@media (min-width:641px) and (max-width:899px) {
    .service-list {
        .heading {
            font-size: $mainHeaderSm;
            font-weight: 500;
            margin: 0px 75px;
        }

        .service {
            margin: 20px 20px 0px 20px;
        }

        .chip-tags {
            font-size: 16px;
            padding: 15px 6px;
        }
    }

    .service {
        .service-image-remote-teams {
            height: 225px
        }

        .service-image-software {
            height: 225px
        }

        .service-image-uiux {
            height: 225px
        }

        .service-image-branding {
            height: 180px
        }

        .service-heading {
            font-size: $subHeaderSm;
            font-weight: 500;
            color: $orangeColor;
        }

        .tags {
            color: $blackColor;
            font-size: 10px;
            font-weight: 500;
            margin-top: 20px;
        }

        .description {
            color: $whiteColor;
            font-size: $subContentSm;
            font-weight: 450;
            margin-top: 30px;
        }

        button:hover {
            font-size: 11px;
            font-weight: 600;
        }

    }
}

@media (min-width:900px) and (max-width: 1024px) {
    .service-list {
        .heading {
            font-size: $mainHeaderMd;
            font-weight: 500;
            margin: 0px 75px;
        }
        .service {
            margin: 50px 75px 0px 75px;
        }
        .chip-tags {
            font-size: 18px;
            padding: 15px 6px;
        }
        .stack {
            gap: 22px;
            flex-wrap: wrap;
        }
    }

    #buttons-software {
        .button {
            width: 45%;
        }
    }

    .service {
        .service-image-remote-teams {
            height: 300px
        }

        .service-image-software {
            height: 300px
        }

        .service-image-uiux {
            height: 300px
        }

        .service-image-branding {
            height: 250px
        }

        .service-heading {
            font-size: $subHeaderMd;
            font-weight: 500;
            color: $orangeColor;
        }

        .tags {
            color: $blackColor;
            font-size: 14px;
            font-weight: 500;
            margin-top: 20px;
        }

        .description {
            color: $whiteColor;
            font-size: $subContentMd;
            font-weight: 450;
            margin-top: 30px;
        }

        .buttons {
            margin-top: 45px;
        }

        .button {
            font-size: 12px;
            font-weight: 600;
        }
        button:hover {
            font-size: 14px;
            font-weight: 600;
        }

    }
}

@media (min-width:1025px) and (max-width: 1280px) {
    .service-list {
        .heading {
            font-size: $mainHeaderMd;
            font-weight: 500;
            margin: 0px 75px;
        }

        .service {
            margin: 50px 75px 0px 75px;
        }
    }
    .service-list {
        .stack {
            position: relative;
            grid-gap: 2%;
            gap: 16px;
            flex-wrap: wrap;
        }
    }
    #buttons-software {
        .button {
            width: 45%;
        }
    }

    .service {
        .service-image-remote-teams {
            height: 350px
        }

        .service-image-software {
            height: 350px
        }

        .service-image-uiux {
            height: 300px
        }

        .service-image-branding {
            height: 280px
        }

        .service-heading {
            font-size: $subHeaderMd;
            font-weight: 500;
            color: $orangeColor;
        }

        .tags {
            color: $blackColor;
            font-size: 14px;
            font-weight: 500;
            margin-top: 20px;
        }

        .description {
            color: $whiteColor;
            font-size: $subContentMd;
            font-weight: 450;
            margin-top: 30px;
        }

        .buttons {
            margin-top: 50px;
        }

        .button {
            font-size: 16px;
            font-weight: 600;
        }

        button:hover {
            font-size: 16px;
            font-weight: 600;
        }

    }
}
@media (min-width:1200px) and (max-width: 1500px) {
    .service {
        .button {
            font-size: 12px !important;
            padding: 8px 22px !important;
        }

        button:hover {
            font-size: 12px;
            font-weight: 600;
        }
    }
    .service-list {
        .services {
            row-gap: 0px !important;
        }
    }
}
@media (min-width:1281px) and (max-width: 1600px) {
    .service-list {
        .heading {
            font-size: $mainHeaderLgs;
            font-weight: 500;
            margin: 0px 75px;
        }

        .service {
            margin: 30px 75px 0px 75px;
        }
        .services {
            row-gap: 0px !important;
        }
    }

    .service {
        .service-image-remote-teams {
            height: 350px
        }

        .service-image-software {
            height: 350px
        }

        .service-image-uiux {
            height: 300px
        }

        .service-image-branding {
            height: 280px
        }

        .service-heading {
            font-size: $subHeaderLgs;
            font-weight: 500;
            color: $orangeColor;
        }

        .tags {
            color: $blackColor;
            font-size: 14px;
            font-weight: 500;
            margin-top: 20px;
        }

        .description {
            color: $whiteColor;
            font-size: $subContentLgs;
            font-weight: 450;
            margin-top: 30px;
        }
        .button {
            font-size: 16px;
            font-weight: 600;
        }

        button:hover {
            font-size: 16px;
            font-weight: 600;
        }

    }
}

@media (min-width:1600px) and (max-width: 1920px) {


  .service {
      .button {
          font-size: 1.05vw;
          font-weight: 600;
      }

      button:hover {
          font-size: 1.05vw;
          font-weight: 600;
      }

  }
}