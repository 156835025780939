@import './common.scss';
$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

.contact-us_1 {
  background: linear-gradient(0deg, rgba(22, 26, 66, 0) 0%, rgba(22, 26, 66, 0.8) 11.46%);
  mix-blend-mode: luminosity;
  position: absolute;
  width: 100%;
  height: 100%;
}

.contact-us {
  position: relative;

  padding-bottom: 8%;


  .contact-us-container {
    position: absolute;
    width: 100%;


  }

  .contact-us-bg-top {

    padding-top: 100px;
    padding-bottom: 50px;

  }

  .contact-us-bg-bottom {

    padding-top: 40px;
    padding-bottom: 110px;
  }

  .contact-us-form-container {
    position: relative;
    top: 60px;
  }

  .lbl-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 90px;
    color: $whiteColor;
    width: 77%;
    margin: 0px 139px;
  }

  .lbl-contact-subheader {
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    color: #FFFFFF;
    width: 77%;
    padding-bottom: 35px;
    margin: 0px 139px;
  }

  .lbl-contact-main {
    font-weight: 500;
    font-size: 45px;
    line-height: 150%;
    color: $orangeColor;
    width: 77%;
    margin: 0px 139px;
  }

  .css-x2ahzc-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 1.3rem;
  }

  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    padding: 4px 0 12px;
  }

  .contact-us-form {
    background: linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.12) 100.83%);

    box-shadow: 0px 0px 10px rgba(33, 51, 220, 0.35);
    backdrop-filter: blur(4px);
    border-radius: 32px;
    padding: 40px;
    padding-right: 0px;
    width: 40%;

    .button {
      margin-top: 30px !important;
      background: $orangeColor !important;
      color: $whiteColor !important;
      border-radius: 8px !important;
      font-size: 24px !important;
      font-weight: 400 !important;
      height: 66px;
      width: 249px;
      z-index: 1;
    }

    .button:hover {
      background: $orangeColor !important;
    }

    .ipt-field-common {
      width: 100%;
      color: $whiteColor;
      height: 30px;
    }

    .custom-form-row {
      height: 80px;
    }

    .attachment-icon {
      background: $whiteColor;
      border-radius: 50%;
      width: 40px;
    }

    .lbl-attachment {
      font-size: 18px;
      line-height: 26px;

      color: $whiteColor;
      padding-left: 8px;
    }

    .MuiInputBase-input {
      color: $whiteColor !important;
    }

    #standard-basic-label {
      color: $whiteColor !important;
    }

    #standard-basic-helper-text {
      font-size: 13px;
      font-weight: 500;
    }

    .MuiInputBase-root:before,
    .MuiInputBase-root:after {
      border-bottom: 1px solid $whiteColor !important;
    }

    .o-color {
      color: $orangeColor !important
    }

    .w-color {
      color: $whiteColor !important;
    }
  }
}

.lgradient {
  background: radial-gradient(50% 50% at 50% 50%, #FF7900 0%, rgba(28, 40, 152, 0.91) 27.08%, rgba(28, 38, 128, 0) 100%);
}

.location-map-globe {
  padding-top: 120px;
}

@media (min-width: 300px) and (max-width: 424px) {
  .contact-us-form {

    #standard-basic-helper-text {
      font-size: 10px !important;
    }
  }
}

//! Todo! Enquire why there are two width ranges
//! 1. from 300 to 600
//! 2. from 340 to 640
@media (min-width:300px) and (max-width:640px) {

  .contact-us {
    position: static !important;

    .ipt-field-common {
      span {
        color: #FF7900 !important;
      }
    }

    .contact-us-container {
      width: 90%;
      margin: 17% auto 0;
    }

    .lbl-title {
      width: 100%;
      line-height: 1.3;
      color: #FF7900 !important;
      position: static !important;
      font-size: $mainHeaderMobileLg !important;
      margin: 0px;
      /* To overwrite desktop style */
    }

    .lbl-contact-subheader {
      font-weight: 300;
      color: #FFFFFF;
      padding-bottom: 30px;

      width: 90%;
      margin: 0;
      max-width: 480px;
      font-size: $sub2HeaderSm;
      padding-top: 18px !important;
      display: none;
    }

    .lbl-contact-main {
      font-size: $sub2HeaderMd !important;
      width: 90%;
      margin: 0px;
      line-height: 2;
      font-weight: 500;
      color: #fff !important;
    }

    .contact-us-bg-top {
      padding-bottom: 0px;
      padding-top: 25px !important;
    }

    .contact-us-bg-bottom {
      padding-top: 30px !important;
      padding-bottom: 30px;
    }

    .contact-us-container {
      position: static !important;
    }

    .contact-us-form-container {
      top: 30px;
      position: static !important;

      .MuiTextField-root {
        font-size: 16px !important;
      }
    }

    .css-x2ahzc-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 0.8rem;
    }

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      padding: 0px 5px 5px 0px;
    }

    .contact-us-form {
      border-radius: 32px - (0.66*32);
      background: linear-gradient(to right, rgb(37, 43, 94) 50%, rgb(35, 44, 123));

      .button {
        border-radius: 6px !important;
        font-size: $buttonTextMd !important;
        height: 44px !important;
        min-width: 140px !important;
        max-width: 170px !important;
      }

      .custom-form-row {
        height: 65px;

        .MuiIconButton-root {
          padding-left: 0 !important;
        }
      }

      .attachment-icon {
        width: 25px;
      }

      .lbl-attachment {
        font-size: 14px;
        font-weight: 400;
        padding-left: 0px;
      }
    }


  }

  .MuiGrid-grid-xs-1 {
    display: none;
  }

  .location-wrapper {
    margin-top: 0;
    display: block !important;
    white-space: nowrap !important;

    .location-card {
      width: 280px;
      vertical-align: top;
      height: 140px !important;
      display: inline-block !important;
      padding: 24px !important;
      margin-right: 20px;
      border-radius: 8px !important;
      background-color: #292D4E !important;
    }
  }

  .MuiGrid-container {
    width: 100%;
  }


}

@media (min-width:400px) and (max-width:640px) {

  .contact-us {
    .lbl-title {
      font-size: $mainHeaderXs !important;
      width: 83%;
      margin: 0px 40px;
    }

    .lbl-contact-subheader {
      font-weight: 400;
      font-size: 15px;
      line-height: 120%;
      color: #FFFFFF;
      width: 77%;
      /* margin: 0px 139px; */
      padding-bottom: 35px;
      margin: 0px 40px;
    }

    .lbl-contact-main {
      font-size: $subContentXs !important;
      width: 83%;
      margin: 0px 40px;
    }

    .contact-us-bg-top {
      padding-bottom: 0px;
      padding-top: 25px !important;
    }

    .contact-us-bg-bottom {
      padding-top: 20px !important;
      padding-bottom: 30px;
    }

    .lbl-attachment {
      font-size: 16px;
      padding-left: 0px;
    }

    .contact-us-container {
      position: relative !important;
    }

    .contact-us-form-container {
      top: 40px
    }

    .css-x2ahzc-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 0.9rem;
    }

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      padding: 0px 5px 5px 0px;
    }

    .contact-us-form {
      .button {
        margin-top: 0px !important;
        font-size: 17px !important;
        height: 30px !important;
        min-width: 90px !important;
        max-width: 130px !important;
      }

      .custom-form-row {
        height: 70px;
      }
    }

    .MuiGrid-grid-xs-1 {
      display: none;
    }
  }
}

@media (min-width:641px) and (max-width:899px) {

  .contact-us {
    .top-bg {
      padding-top: 100px;
    }

    .lbl-contact-subheader {
      font-weight: 400;
      font-size: 30px;
      line-height: 120%;
      color: #FFFFFF;
      width: 77%;
      padding-bottom: 35px;
      margin: 0px 139px;
    }

    .lbl-title {
      font-size: $mainHeaderSm;
      // font-size: 34px !important;
      line-height: 50px !important;
      margin: 0px 116px;
    }

    .lbl-contact-main {
      font-size: $subContentSm;
      // font-size: 26px !important;
      line-height: 40px;
    }

    .contact-us-bg-bottom {
      padding-top: 0px;
      padding-bottom: 85px;
    }

    .contact-us-container {
      position: relative !important;
    }

    .contact-us-form {
      .button {
        font-size: 20px !important;
        height: 50px !important;
        min-width: 170px !important;
        max-width: 190px !important;
      }
    }
  }
}

@media (min-width:900px) and (max-width: 1200px) {
  .contact-us {
    .top-bg {
      padding-top: 120px;
    }

    .lbl-title {
      font-size: $mainHeaderMd;

      line-height: 45px;
      margin: 0px 116px;
    }

    .lbl-contact-main {
      font-size: $subContentMd;

      line-height: 45px;
    }

    .contact-us-bg-bottom {
      padding-top: 180px !important;
    }

    .contact-us-form {
      .button {
        margin-top: 0px !important;
        font-size: 20px !important;
        height: 40px !important;
        min-width: 150px !important;
        max-width: 170px !important;
      }
    }
  }
}

@media (min-width:1201px) and (max-width: 1500px) {

  .contact-us {
    .contact-us-form-container {
      top: 35px;
    }

    .contact-us-bg-top {
      padding-bottom: 5px;
    }

    .lbl-title {
      font-size: $mainHeaderLgs;
      line-height: 60px;
      margin: 0px 102px;
    }

    .lbl-contact-subheader {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #FFFFFF;
      width: 77%;
      padding-bottom: 35px;
      margin: 0px 102px;
    }

    .lbl-contact-main {
      font-size: $subContentLgs;
      margin: 0px 102px;
    }

    .contact-us-bg-bottom {
      padding-top: 48px !important;
      padding-bottom: 150px;
    }

    .css-x2ahzc-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 1.1rem;
    }

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      padding: 0px 5px 5px 0px;
    }

    .css-ji56tn-MuiGrid-root>.MuiGrid-item {
      padding-left: 45px;
    }

    .contact-us-form {
      padding: 25px 25px 25px 30px;
      width: 48% !important;

      .button {
        font-size: 18px !important;
        height: 35px !important;
        min-width: 116px !important;
        max-width: 136px !important;
        margin-top: 0px !important;
      }

      .lbl-attachment {
        font-size: 16px;
      }

      .attachment-icon {
        width: 30px;
      }

      .contact-us .contact-us-form .attachment-icon {
        width: 35px;
      }

      .custom-form-row {
        height: 65px;
      }

      contact-us-form .button {
        margin-top: 18px !important;
        font-size: 20px !important;
        height: 35px !important;
        width: 115px !important;
      }

      #standard-basic-helper-text {
        font-size: 10px;
      }
    }

  }
}

@media (min-width:1501px) and (max-width: 1600px) {
  .contact-us {
    .lbl-title {
      font-size: $mainHeaderLgs;
      line-height: 60px;
      margin: 0px 120px;
    }

    .contact-us-bg-top {
      padding-bottom: 10px;
    }

    .lbl-contact-subheader {
      font-weight: 400;
      font-size: 25px;
      line-height: 120%;
      color: #FFFFFF;
      width: 77%;
      padding-bottom: 35px;
      margin: 0px 120px;
    }

    .lbl-contact-main {
      font-size: $subContentLgs;
      line-height: 150%;
      margin: 0px 120px;
      font-weight: 600;
    }

    .css-ji56tn-MuiGrid-root>.MuiGrid-item {
      padding-left: 58px;
    }

    .contact-us-bg-bottom {
      padding-top: 65px !important;
      padding-bottom: 138px;
    }

    .contact-us-form {

      width: 48% !important;

      .button {
        font-size: 20px !important;
        height: 40px !important;
        min-width: 140px !important;
        max-width: 180px !important;
        margin-top: 0px !important;
      }

      .lbl-attachment {
        font-size: 20px;
      }

      #standard-basic-helper-text {
        font-size: 10px;
      }
    }

  }
}

.best-of-countries {
  display: none;
}

@media (max-width: 900px) {
  .location-container {
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .location-container::-webkit-scrollbar {
    display: none;
  }

  .contact-us {
    .contact-us-form {
      width: 90%;
      margin: 0 auto;
      box-sizing: border-box;
      margin: 0px auto;
      padding: 24px 6%;
      box-shadow: none;
    }
  }

  .best-of-countries {
    display: block;
    margin: 0 auto 32px;
    width: 88%;
    box-sizing: border-box;

    .best-of-countries-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      color: #fff;
      margin: 0 0 16px 0;
    }

    .best-of-countries-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 150%;
      color: #fff;
      margin: 0;
    }
  }
}