@import './common.scss';

#popup {
  margin: 0px auto;
  width: 85%;
  margin-top: 5.2%;
}

.popup-main-container {
  position: relative;
}

.popup-main-heading {
  font-weight: 700;
  font-size: $mainHeaderLg;
  color: #FFFFFF;
  // for UI/UX
  padding-bottom: 50px;
}

.popup-bg {
  width: 100%;
}

.popup-newsletter {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 28%;
  width: 48%;
  height: 37%;
  background: linear-gradient(103.34deg, rgba(43, 98, 236, 0.53) 32.46%, #1845B9 145.17%);
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25), 0px 18px 20px -7px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 32px;
  z-index: 100;
}

.popup-newsletter-closed {
  display: none;
}

.popup-newsletter-main-container {
  height: 100%;
  align-content: center;
  row-gap: 10%;
}

.popup-newsletter-text-container {
  width: 85% !important;
}

.popup-newsletter-main-heading {
  font-style: normal;
  font-weight: 700;
  font-size: $cardMainLg;
  text-align: center;
  color: #FFFFFF;
}

.popup-newsletter-button {
  width: 80%;
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #000007 !important;
  background: linear-gradient(103.34deg, rgba(255, 255, 255, 0.53) 32.46%, #A6BCF4 145.17%) !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(6px) !important;
  border-radius: 32px !important;
  margin-left: 20% !important;
}

.popup-newsletter-textfield {
  height: 85% !important;
  width: 100% !important;
  padding: 0% 2% !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  color: #878D90 !important;
  background: linear-gradient(103.34deg, rgba(255, 255, 255, 0.53) 32.46%, #DDE2EF 145.17%) !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(6px) !important;
  border-radius: 32px !important;
}


.popup-notification {
  position: absolute;
  left: 12.5%;
  right: 0px;
  top: 17%;
  width: 28%;
  height: 24%;
  background: linear-gradient(103.34deg, rgba(245, 181, 231, 0.53) 32.46%, #C66969 145.17%);
  border: 2px solid rgba(74, 71, 71, 0.3);
  box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25), 0px 6px 31px -4px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 32px;
  z-index: 100;
}

.popup-notification-closed {
  display: none;
}

.popup-notification-main-container {
  height: 100%;
  align-content: center;
  row-gap: 10%;
}

.popup-notification-text-container {
  width: 85% !important;
}

.popup-notification-main-heading {
  font-style: normal;
  font-weight: 400;
  font-size: $cardContentLg;
  text-align: center;
  color: #000000;

}

.popup-notification-button {
  width: 65%;
  font-size: 20px !important;
  color: #000000 !important;
  background: #FFFFFF !important;
  border-radius: 8px !important;
  margin-left: 20% !important;
  font-style: normal !important;
  height: 110% !important;
}

.popup-notification-textfield {
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  color: #878D90 !important;
  background: linear-gradient(103.34deg, rgba(255, 255, 255, 0.53) 32.46%, #DDE2EF 145.17%) !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(6px) !important;
  border-radius: 32px !important;
}

.popup-videocard {
  position: absolute;
  right: 10%;
  top: 40%;
  width: 33%;
}

.popup-videocard-closed {
  display: none;
}

.popup-videocard-close-icon {
  position: absolute;
  right: 2%;
  color: #FFFFFF;
  z-index: 99;
  width: 50px !important;
  height: 50px !important;
}

.popup-videocard-pause-icon {
  position: absolute;
  left: 40%;
  top: 40%;
  color: #FFFFFF;
  z-index: 99;
  width: 100px !important;
  height: 100px !important;
}

.popup-content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 33%;
}

.popup-main-content {
  font-weight: 700;
  font-size: $subHeaderLg;
  text-align: center;
  color: #FF7900;
}

.popup-sub-content {
  font-style: normal;
  font-size: $subContentLg;
  text-align: center;
  color: #FFFFFF;
  padding: 64px 0px;
  margin: 0px 257px;
}

.popup-service-content {
  display: none;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 328px;
  height: 596px;
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
  margin: 0 auto 32px;

  .popup-service-header {
    width: 100%;
    height: 130px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #616161;
    background: #D0D0D0;
    padding: 17px 14px 14px 14px;
    box-sizing: border-box;

    &.hide-header {
      display: none;
    }

    .popup-service-header-news-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      margin-bottom: 16px;
    }

    .popup-service-header-news-close {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      padding: 6px;
      cursor: pointer;
      box-sizing: border-box;

      img {
        display: inline-block;
        width: 18px;
        height: 18px;
      }
    }

    .popup-service-header-news-rect {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background: #6F8CF4;
      color: #FFFFFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 133%;
      text-transform: uppercase;
      box-sizing: border-box;
      margin: 0 9px;
    }

    .popup-service-header-news-text {
      display: block;
      color: #000;
      font-family: Neue Haas Grotesk Display Pro;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 133%;
      /* 15.96px */
      box-sizing: border-box;
      margin-bottom: 3px;
    }

    .popup-service-star {
      display: flex;
      align-items: center;
      width: 88px;
      height: 12px;
      line-height: 12px;
      margin-left: -1px;

      .start-number {
        display: inline-block;
        margin-left: 6px;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .game-image-wrapper {
    position: relative;
    box-sizing: border-box;
    height: 100%;

    .game-image-details {
      position: absolute;
      left: 0;
      bottom: 2px;
      padding: 22px 16px 25px 14px;
      box-sizing: border-box;

      .game-image-details-desc {
        color: #000000;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 133%;
        margin: 0 0 12px 0;
        box-sizing: border-box;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .popup-service-view-app {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #000;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    cursor: pointer;
    box-sizing: border-box;
  }

  .donate-banner {
    width: 100%;
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    background: #88DFFA;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 22px 20px 24px 20px;
    box-sizing: border-box;

    &.hide-donate-banner {
      display: none;
    }

    .donate-banner-close {
      position: absolute;
      top: 11px;
      right: 0;
      width: 44px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
      }
    }

    .donate-banner-desc {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 133%;
      margin-bottom: 8px;
      box-sizing: border-box;
      width: 80%;
    }

    .donate-banner-btn {
      display: inline-flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      border-radius: 8px;
      background: #000;
      cursor: pointer;
      box-sizing: border-box;
      text-transform: capitalize;
    }
  }
}

@media (min-width:1200px) and (max-width: 1500px) {

  .popup-main-heading {
    font-size: $subHeaderLgs;
  }

  .popup-newsletter {
    border-radius: 32px - (0.375*32);
  }

  .popup-newsletter-main-heading {
    font-size: $cardMainLgs;
  }

  .popup-newsletter-button {
    font-size: (24px - (0.375*24)) !important;
    border-radius: (32px - (0.375*32)) !important;
  }

  .popup-newsletter-textfield {
    font-size: (24px - (0.375*24)) !important;
    border-radius: (32px - (0.375*32)) !important;
  }


  .popup-notification {
    border-radius: 32px - (0.375*32);
  }

  .popup-notification-main-heading {
    font-size: $cardContentLgs;
  }

  .popup-notification-button {
    font-size: (20px - (0.375*20)) !important;
  }

  .popup-notification-textfield {
    font-size: (24px - (0.375*24)) !important;
    border-radius: (32px - (0.375*32)) !important;
  }

  .popup-videocard {
    width: 33%;
  }

  .popup-videocard-close-icon {
    width: (50px - (0.375*50)) !important;
    height: (50px - (0.375*50)) !important;
  }

  .popup-videocard-pause-icon {
    width: (100px - (0.375*100)) !important;
    height: (100px - (0.375*100)) !important;
  }

  .popup-main-content {
    font-size: $subHeaderLgs;
  }

  .popup-sub-content {
    font-size: $subContentLgs;
    padding: (64px - (0.375*64)) 0px;
    margin: 0px (257px - (0.375*257));
  }
}

@media (min-width:900px) and (max-width: 1200px) {

  .popup-main-heading {
    font-size: $mainHeaderMd;
  }

  .popup-newsletter {
    border-radius: 32px - (0.53125*32);
  }

  .popup-newsletter-main-heading {
    font-size: $cardMainMd;
  }

  .popup-newsletter-button {
    font-size: (24px - (0.53125*24)) !important;
    border-radius: (32px - (0.53125*32)) !important;
  }

  .popup-newsletter-textfield {
    font-size: (24px - (0.53125*24)) !important;
    border-radius: (32px - (0.53125*32)) !important;
  }


  .popup-notification {
    border-radius: 32px - (0.53125*32);
  }

  .popup-notification-main-heading {
    font-size: $cardContentMd;
  }

  .popup-notification-button {
    font-size: (20px - (0.53125*20)) !important;
  }

  .popup-notification-textfield {
    font-size: (24px - (0.53125*24)) !important;
    border-radius: (32px - (0.53125*32)) !important;
  }

  .popup-videocard {
    width: 33%;
  }

  .popup-videocard-close-icon {
    width: (50px - (0.53125*50)) !important;
    height: (50px - (0.53125*50)) !important;
  }

  .popup-videocard-pause-icon {
    width: (100px - (0.53125*100)) !important;
    height: (100px - (0.53125*100)) !important;
  }

  .popup-main-content {
    font-size: $subHeaderMd;
  }

  .popup-sub-content {
    font-size: $subContentMd;
    padding: (64px - (0.53125*64)) 0px;
    margin: 0px (257px - (0.53125*257));
  }
}

@media (min-width:641px) and (max-width: 899px) {

  .popup-main-heading {
    font-size: $mainHeaderSm;
  }

  .popup-newsletter {
    border-radius: 32px - (0.66*32);
  }

  .popup-newsletter-main-heading {
    font-size: $cardMainSm;
  }

  .popup-newsletter-button {
    font-size: (24px - (0.66*24)) !important;
    border-radius: (32px - (0.66*32)) !important;
  }

  .popup-newsletter-textfield {
    font-size: (24px - (0.66*24)) !important;
    border-radius: (32px - (0.66*32)) !important;
  }


  .popup-notification {
    border-radius: 32px - (0.66*32);
  }

  .popup-notification-main-heading {
    font-size: $cardContentSm;
  }

  .popup-notification-button {
    font-size: (20px - (0.66*20)) !important;
  }

  .popup-notification-textfield {
    font-size: (24px - (0.66*24)) !important;
    border-radius: (32px - (0.66*32)) !important;
  }

  .popup-videocard {
    width: 33%;
  }

  .popup-videocard-close-icon {
    width: (50px - (0.66*50)) !important;
    height: (50px - (0.66*50)) !important;
  }

  .popup-videocard-pause-icon {
    width: (100px - (0.66*100)) !important;
    height: (100px - (0.66*100)) !important;
  }

  .popup-main-content {
    font-size: $subHeaderSm;
  }

  .popup-sub-content {
    font-size: $subContentSm;
    padding: (64px - (0.66*64)) 0px;
    margin: 0px (257px - (0.66*257));
  }
}

@media (min-width:1501px) and (max-width: 1800px) {
  .popup-main-heading {
    font-size: $mainHeaderLgs;
  }

  .popup-notification-button {
    font-size: (20px - (0.275*20)) !important;
  }
}

@media (max-width: 640px) {
  #popup {
    width: 90%;
    margin: 40px auto;
  }

  .popup-main-heading {
    font-weight: 600;
    line-height: 1.6;
    font-size: $mainHeaderMobileMd;
    padding-bottom: 32px;
  }
}

@media (max-width: 900px) {
  .popup-service-content {
    display: block;
    border-radius: 8px;
  }
}