@import 'common';

#service-faq {
    margin: 0px auto;
    width: 85%;
    margin-top: 100px;
}
.root-services {
    #service-faq { 
        margin-top: 200px;
        margin-bottom: 190px;
    }
}

.root-cs {
    #service-faq {
        margin-top: 70px;
    }
}

.service-faq-heading {
    font-style: normal;
    font-weight: 700;
    font-size: $mainHeaderLg;
    color: #FFFFFF;
}

.service-faq-accordion-container {
    margin-top: 60px;
}
.service-faq-accordion {
    background-color: transparent !important;
    padding-bottom: 20px;
}

.service-faq-accordion-icon {
    font-size: 48px !important;
    color: #FF7900;
    z-index: 2;
    margin-top: 10px;
}
.service-faq-accordion-summary {
    padding-left: 0px !important;
}

.service-faq-question {
    font-style: normal;
    font-weight: 700;
    font-size: $subHeaderLg;
    color: #FF7900;
    display: flex;
    flex-direction: row;
    width: 98%;
}

.service-faq-question:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #FFFFFF;
    margin: 0px 0px 20px 26px;
}

.service-faq-answer {
    font-style: normal;
    font-weight: 500;
    font-size: $subContentLg;
    color: #FFFFFF;
}

@media (min-width:1501px) and (max-width: 1600px) {

    #service-faq {
        margin-top: 220px - (0.375*220);
    }
    .service-faq-heading {
        font-size: $mainHeaderLgs;
    }
    .service-faq-accordion-container {
        margin-top: 60px - (0.375*100);
    }
    .service-faq-question::after {
        margin: 0px 0px 16px 26px;
    }
    .service-faq-accordion-icon {
        font-size: 40px !important;
        margin-top: 4px;
    }
    .service-faq-question {
        font-size: $subHeaderLgs;
    }
    .service-faq-answer {
        font-size: $subContentLgs;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {

    #service-faq {
        margin-top: 220px - (0.375*220);
    }
    .service-faq-heading {
        font-size: $subHeaderLgs;
    }
    .service-faq-accordion-container {
        margin-top: 60px - (0.375*100);
    }
    .service-faq-question {
        font-size: $sub2HeaderLgs;
    }
    .service-faq-question::after {
        margin: 0px 0px 12px 26px;
    }
    .service-faq-accordion-icon {
        font-size: 40px !important;
        margin-top: 8px;
    }
    .service-faq-answer {
        font-size: $subContentLgs;
    }
}

@media (min-width:900px) and (max-width: 1200px) {

    #service-faq {
        margin-top: 220px - (0.53125*220);
    }
    .service-faq-heading {
        font-size: $mainHeaderMd;
    }
    .service-faq-accordion-container {
        margin-top: 60px - (0.53125*100);
    }
    .service-faq-question {
        font-size: $subHeaderMd;
    }
    .service-faq-answer {
        font-size: $subContentMd;
    }
}

@media (min-width:641px) and (max-width: 899px) {

    #service-faq {
        margin-top: 220px - (0.66*220);
    }
    .service-faq-heading {
        font-size: $mainHeaderSm;
    }
    .service-faq-accordion-container {
        margin-top: 60px - (0.66*100);
    }
    .service-faq-question {
        font-size: $subHeaderSm;
    }
    .service-faq-answer {
        font-size: $subContentSm;
    }
}

@media (max-width:640px) {
    .root-services {
        #service-faq { 
            width:90%;
            margin-top: 60px;
            margin-bottom: 40px;
        }
    }

    .root-cs {
        #service-faq {
            margin-top: 0;
        }
    }
    
    .service-faq-heading {
        font-size: $mainHeaderMobileMd;
    }

    .service-faq-accordion-container {
        font-size: 30px;
        margin-top: 20px;
    }
    .service-faq-question {
        font-weight: 500;
        font-size: $mainHeaderSm;
        color: #d2d2d2;
    }
    
    .service-faq-question:after {
        content: "";
        border: none
    }

    .service-faq-answer {
        padding: 0 !important;
        font-weight: 300;
        line-height: 1.8;
        font-size: $subContentMobile;
    }

    .service-faq-accordion {
        padding: 20px;
        border-radius: 8px !important;
        margin-bottom: 20px;
        background-color: #353B5F !important;
    }

    .service-faq-accordion-icon {
        font-size: 24px !important;
        color: #d2d2d2;
    }

}