@import 'common';

.about-bg1 {
    //removing dot bg for banner
    background-color: #1a203c;
    // background-image: url('../assets/About/about-banner-bg1.png');
    position: relative;
}

.about-bg2 {
    // background-image: url('../assets/About/mixkit-3d-animation-of-lines-and-luminous-points-rotating-31510-medium\ 2.png');
    // mix-blend-mode: hard-light;
}


.root-about {
    #tsparticles {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .top-bg {
        width: 100%;
        top: 0;
        padding-top: 185px;
        padding-bottom: 0%;
        position: relative;
        /* background: linear-gradient(90deg, rgba(22, 26, 66, 0) 0%, rgba(22, 26, 66, 0.8) 11.46%) */
    }
}

.best-of-countries {
  display: none;
}

@media (max-width:641px) {
    .root-about {
        .top-bg {
            padding-top: 80px;
        }
    }
}
@media (min-width:641px) and (max-width:899px) {
    .root-about {
        .top-bg {
            padding-top: $bannerTopSm;
        }
    }
}

@media (max-width: 900px) {
  .location-container {
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .location-container::-webkit-scrollbar {
    display: none;
  }

  .contact-us {
    .contact-us-form {
      width: 90%;
      margin: 0 auto;
      box-sizing: border-box;
      margin: 0px auto;
      padding: 24px 6%;
      box-shadow: none;
    }
  }

  .best-of-countries {
    display: block;
    margin: 0 auto 32px;
    width: 88%;
    box-sizing: border-box;

    .best-of-countries-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      color: #fff;
      margin: 0 0 16px 0;
    }

    .best-of-countries-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 150%;
      color: #fff;
      margin: 0;
    }
  }
}