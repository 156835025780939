$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

// Main Header
$mainHeaderLg: 54px;
$mainHeaderLgs: 36px;
$mainHeaderMd: 28px;
$mainHeaderSm: 20px;
$mainHeaderXs: 18px;

$mainHeaderMobileLg: 32px;
$mainHeaderMobileMd: 24px;

// Sub Header
$subHeaderLg: 43px;
$subHeaderLgs: 32px;
$subHeaderMd: 22px;
$subHeaderSm: 18px;
$subHeaderXs: 16px;

//Sub Header 2
$sub2HeaderLg: 35px;
$sub2HeaderLgs: 28px;
$sub2HeaderMd: 20px;
$sub2HeaderSm: 16px;
$sub2HeaderXs: 14px;

// Sub content
$subContentLg: 32px;
$subContentLgs2: 30px;
$subContentLgs1: 28px;
$subContentLgs: 26px;
$subContentLgss: 24px;
$subContentMd: 18px;
$subContentSm: 14px;
$subContentXs: 12px;

$subContentMobile: 16px;

// Card title
$cardMainLg: 40px;
$cardMainLgs: 30px;
$cardMainMd: 20px;
$cardMainSm: 16px;
$cardMainXs: 14px;

// Card desc
$cardContentLg: 28px;
$cardContentLgs: 18px;
$cardContentMd: 14px;
$cardContentSm: 12px;
$cardContentXs: 10px;


//Button Font
$buttonTextLg: 24px;
$buttonTextLgs: 20px;
$buttonTextMd: 16px;
$buttonTextSm: 12px;
$buttonTextXs: 10px;

$font-35: 35px;
$font-24: 35px;
$font-28:28px;
$font-18:18px;

$topLg: 185px;

$marginMainTitleBottom: 4.412%;
$marginSectionBottom: 9.3%;

$bannerTopSm: 90px;