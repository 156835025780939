@import '../css/common';

.cs-client {
    .content-container {
        padding-top: 8%;
    }

    .client-content {
        font-size: $subContentLg;
        color: $whiteColor;
        line-height: 150%;
    }

    .author-main {
        text-align: right;
        padding-top: 10%;
        .author-name {
            font-weight: 700;
            font-size: $subContentLgs1;
            color: $whiteColor;
        }

        .author-details {
            font-size: $subContentLgs1;
            color: $whiteColor;
            line-height: 150%;
        }
    }
}


@media (max-width: 640px) {
    .cs-client {
        .client-logo-wrapper {
            width: 180px !important;
            margin: 0 auto;

            .client-logo {
                max-width: 100%;
            }
        }

        .client-content {
            line-height: 1.5;
            font-size: $subContentMobile;
        }

        .author-main  {
            .author-name {
                font-size: 16px;
                font-weight: 600;
            }

            .author-details {
                font-size: 16px;
            }
        }
    }
}

@media (min-width:1201px) and (max-width: 1500px) {
    .cs-client {
        padding-top: 70px;
        .content-container {
            padding-top: 8%;
            padding-left: 24px;
        }

        .client-content {
            font-size: $subContentLgss;
            color: $whiteColor;
        }

        .author-main {
            text-align: right;
            padding-top: 10%;

            .author-name {
                font-weight: 700;
                font-size: $subContentLgss;
                color: $whiteColor;
            }

            .author-details {
                font-size: $subContentLgss;
                color: $whiteColor;
                line-height: 150%;
            }
        }
        .client-logo-container {
            img {
                width: 100%;
            }
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .cs-client {
        padding-top: 70px;
        .content-container {
            padding-top: 7%;
        }
        .client-logo-container {
            img {
                width: 90%;
            }
        }
        .client-content {
            font-size: $subContentLgs2;
        }
    }
}
@media (min-width:1900px) {
    .cs-client {
        padding-top: 70px;
    }
}
@media (max-width:900px) {

    .cs-client {
        .content-container {
            .client-quotes-main {
                width: 40px;
                height: 35px;
                left: 0;
                top: 12px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}