@import 'common';

#vision {
    margin-top: 100px;
    padding-bottom: 2.5%;
    position: relative;
}
.vision-title{
    font-size: $mainHeaderLg;
    font-weight: 700;
    color: #FFFFFF;
    margin: auto;
    width: 85%;
}
.vision-card{
    background: linear-gradient(90deg, #1D203B 0%, rgba(29, 32, 59, 0) 100.83%);
    border-radius: 32px;
    margin: 0px auto;
    margin-top: 72px;
    margin-bottom: 100px;
    width: 80%;
    padding: 56px 56px 56px 56px;
}
.vision-text-container {
    padding-right: 50px;
}
.vision-image-container {
    z-index: 1;
    padding: 20px 0px;
}
.vision-heading{
    font-size: $mainHeaderLg;
    font-weight: 600;
    color: #FF7900;
}
.vision-subheading{
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 0.2em;
}
.vision-content{
    font-size: $subContentLg;
    color: #FFFFFF;
    font-style: normal;
}
.vision-image{
    width: 100%;
}
.vision-button {
    background:#FF7900 !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    height: 66px !important;
    width: 249px !important;
}
.vision-button:hover {
    box-shadow: 0 0 2px 2px #BE9663 !important;
}
.bg-gradiant-vision {
    background: radial-gradient(25% 36% at 88% 51%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
}

@media (min-width:1501px) and (max-width: 1600px) {
    #vision {
        margin-top: 100px - (0.375*100);
    }
    .vision-title{
        font-size: $mainHeaderLgs;
    }
    .vision-card{
        border-radius: 32px - (0.375*32);
        margin-top: 100px - (0.375*100);
        margin-bottom: 100px - (0.375*100);
        padding: 56px - (0.375*56);
    }
    .vision-text-container {
        padding-right: 50px - (0.375*50);
    }
    .vision-image-container {
        padding-left: 200px - (0.375*200);
    }
    .vision-heading{
        font-size: $mainHeaderLgs;
    }
    .vision-subheading{
        font-size: 18px;
    }
    .vision-content{
        font-size: $subContentLgs;
    }
    .vision-button {
        border-radius: 8px - (0.375*8) !important;
        font-size: 24px - (0.375*24) !important;
        height: 66px - (0.375*66) !important;
        width: 249px - (0.375*249) !important;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    #vision {
        margin-top: 100px - (0.375*100);
    }
    .vision-title{
        font-size: $mainHeaderLgs;
    }
    .vision-card{
        border-radius: 32px - (0.375*32);
        margin-top: 100px - (0.375*100);
        margin-bottom: 100px - (0.375*100);
        padding: 56px - (0.375*56);
    }
    .vision-text-container {
        padding-right: 50px - (0.375*50);
    }

    .vision-image-container {
        padding-left: 200px - (0.375*200);
    }
    
    .vision-heading{
        font-size: $mainHeaderLgs;
    }
    .vision-subheading{
        font-size: 18px;
    }
    .vision-content{
        font-size: $subContentLgs;
    }
    .vision-button {
        border-radius: 8px - (0.375*8) !important;
        font-size: 24px - (0.375*24) !important;
        height: 66px - (0.375*66) !important;
        width: 249px - (0.375*249) !important;
    }
}
@media (min-width:900px) and (max-width: 1200px) {
    #vision {
        margin-top: 100px - (0.53125*100);
    }
    .vision-title{
        font-size: $mainHeaderMd;
    }
    .vision-card{
        border-radius: 32px - (0.53125*32);
        margin-top: 100px - (0.53125*100);
        margin-bottom: 100px - (0.53125*100);
        padding: 56px - (0.53125*56);
    }
    
    .vision-text-container {
        padding-right: 50px - (0.53125*50);
    }
    .vision-image-container {
        padding-left: 200px - (0.53125*200);
    }
    .vision-heading{
        font-size: $mainHeaderMd;
    }
    .vision-subheading{
        font-size: 16px;
    }
    .vision-content{
        font-size: $subContentMd;
    }
    
    
    .vision-button {
        border-radius: 8px - (0.53125*8) !important;
        font-size: 24px - (0.53125*24) !important;
        height: 66px - (0.53125*66) !important;
        width: 249px - (0.53125*249) !important;
    }
}
@media (min-width:641px) and (max-width: 899px) {
    #vision {
        margin-top: 100px - (0.66*100);
    }
    .vision-title{
        font-size: $mainHeaderSm;
    }
    .vision-card{
        border-radius: 32px - (0.66*32);
        margin-top: 100px - (0.66*100);
        margin-bottom: 100px - (0.66*100);
        padding: 56px - (0.66*56);
    }
    .vision-text-container {
        padding-right: 50px - (0.66*50);
    }
    .vision-image-container {
        padding-left: 200px - (0.66*200);
    }
    .vision-heading{
        font-size: $mainHeaderSm;
    }
    .vision-subheading{
        font-size: 14px;
    }
    .vision-content{
        font-size: $subContentSm;
    }
    .vision-button {
        border-radius: 8px - (0.66*8) !important;
        font-size: 24px - (0.66*24) !important;
        height: 66px - (0.66*66) !important;
        width: 249px - (0.66*249) !important;
    }
}

@media (max-width: 900px) {
    .bg-gradiant-vision {
        background: none;
    }
}
@media (max-width: 640px) {
    #vision {
        width: 90%;
        margin: 40px auto 0;
    }
    .vision-title{
        width: 100%;
        font-weight: 500;
        font-size: $mainHeaderMobileMd;
    }
    .vision-card{
        width: 90%;
        border-radius: 32px - (0.66*32);
        margin-top: 100px - (0.66*100);
        margin-bottom: 0;
        padding: 56px - (0.66*56);
        background: #252A5E;
    }
    .vision-text-container {
        order: 2;
        padding-right: 0;
    }
    
    .vision-image-container {
        padding: 0;
    }
    .vision-heading{
        padding: 16px 0;
        font-weight: 500;
        font-size: $mainHeaderSm;
        color: #fff;
    }
    .vision-subheading{
        font-size: 10px;
        padding-top: 10px;
    }
    .vision-content{
        line-height: 1.5;
        font-size: $subContentSm;
    }
    .vision-button {
        margin-top: 25px !important;
        font-size: 16px !important;
        padding: 16px 20px;
        min-width: 130px;
        height: 44px !important;
        width: auto;
    }
}