.blog-bg1 {
    position: relative;
    background-image: url('../assets/About/about-banner-bg1.png');
}

.root-blog {
    #tsparticles {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .top-bg {
        width: 100%;
        top: 0;
        padding-top: 185px;
        padding-bottom: 0%;
        position: relative;
    }
}

#story-list {
    width: 85%;
    margin: 0 auto;
    margin-top: 200px;
    margin-bottom: 350px;
    row-gap: 280px;
}

@media (min-width:1501px) and (max-width: 1600px) {
    #story-list {
        margin-top: 200px - (0.375*200);
        margin-bottom: 350px - (0.375*350);
        row-gap: 280px - (0.375*280);
    }

    .story-image {
        max-width: 100%;
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    #story-list {
        margin-top: 200px - (0.375*200);
        margin-bottom: 350px - (0.375*350);
        row-gap: 280px - (0.375*280);
    }
    .story-image {
        max-width: 100%;
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    #story-list {
        margin-top: 200px - (0.53125*200);
        margin-bottom: 350px - (0.53125*350);
        row-gap: 280px - (0.53125*280);
    }
}

@media (min-width:641px) and (max-width: 899px) {
    #story-list {
        margin-top: 200px - (0.66*200);
        margin-bottom: 350px - (0.66*350);
        row-gap: 280px - (0.66*280);
    }
}

@media (max-width:640px) {
    #story-list {
        width: 100%;
        row-gap: 0;
        padding-top: 0;
        margin: 45px auto;
    }
}