@import './common.scss';

.career-bg1 {
    background-color: #1a203c;
    position: relative;
}

.root-career {
    #tsparticles {
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .top-bg {
        width: 100%;
        top: 0;
        padding-top: 185px;
        padding-bottom: 0%;
        position: relative;
    }
}

.root-career-content {
    width: 85%;
    margin: 0 auto;
}

.open-wallet {
    animation: .2s ease bounceTopConnectorOut forwards;
}

.money-svg:hover .open-wallet {
    animation: .2s ease bounceTopConnectorIn forwards;
}

@keyframes bounceTopConnectorIn {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    50% {}

    100% {
        opacity: 1;
        transform: translateY(-1px);
    }
}

@keyframes bounceTopConnectorOut {
    0% {
        opacity: 1;
        transform: translateY(-1px);
    }

    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.friends-svg {}

.friends {
    opacity: 1;
}

.hand {
    opacity: 0;
}

.friends-close {
    opacity: 0;
}

.friends-svg:hover .friends-close {
    animation: 1.5s ease friends-close-svg-transition forwards;
}

.friends-svg:hover .hand {
    animation: 1s ease friends-hand-transition forwards;
}

.friends-svg:hover .friends {
    animation: 2s ease friends-svg-transition forwards;
}

@keyframes friends-hand-transition {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }

    50%,
    100% {
        opacity: 1;
        transform: translate(0px, 5px);
    }
}

@keyframes friends-close-svg-transition {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes friends-svg-transition {
    0% {
        opacity: 1;
    }

    50%,
    100% {
        opacity: 0;
    }
}

.cat-svg {}

.open-heart {}

.cat-svg .open-heart {
    animation: .2s ease bounceTopHeartOut forwards;
}

.cat-svg:hover .open-heart {
    animation: .2s ease bounceTopHeart forwards;
}

.cat-svg:hover .cat-path {
    stroke: #FF7900;
}


@keyframes bounceTopHeart {
    0% {
        transform: translateY(12px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes bounceTopHeartOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(12px);
    }
}

@media (max-width: 640px) {
    .root-career-content {
        width: 90%;

        .work-us-main {
            margin-top: 40px;

            .work-us-title {
                font-size: $mainHeaderMobileMd;
                font-weight: 600;
            }

            .work-us-list {


                .work-us-list-item {
                    padding-top: 30px;

                    flex-wrap: nowrap;
                    align-items: flex-start;

                    .img-wrapper {
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                        margin-right: 20px;

                        svg {
                            width: 40px;
                            height: 40px;
                            max-width:100%;
                        }
                    }

                    .item-title {
                        font-size: $mainHeaderSm;
                        font-weight: 600;
                        padding-bottom: 10px;
                    }

                    .item-desc {
                        font-size: $subContentMobile;
                    }
                }
            }
        }
    }
}