@import './common';

$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;

.contact {
    margin: 0px auto;
    width: 85%;
    margin-top: 214px;
}
.mobile-title
{
  display: none;
}
.desktop-title
{
  display: block;
}
.margin-bottom {
    margin-bottom: 100px;
}

.contact-card {
    position: relative;
    border-radius: 1px;
    height: 674px;
    width: 100%
}

.contact-card::before {
    display: inline;
    content: '';
    filter: blur(250px);
    position: absolute;
    height: 130%;
    width: 100%;
    background-image: linear-gradient(0deg, #19193C, #19193C), linear-gradient(97.13deg, rgba(8, 25, 164, 0.12) -12.87%, rgba(12, 13, 15, 0.25) 117.57%);
    z-index: -1;
    margin-top: -5%;
}

.contact-heading {
    font-size: $mainHeaderLg;
    font-weight: 600;
    color: $whiteColor;
    margin-top: 66px;
    .line-break {
        margin-bottom: 7px;
    }
}

.contact-content {
    font-size: $subContentLg;
    font-weight: 450;
    color: #FFFFFF;
    margin-top: 26px;
    line-height: 150%;
}

.contact-button {
    background: $orangeColor !important;
    color: $whiteColor !important;
    border-radius: 8px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    height: 66px !important;
    min-width: 249px !important;
    max-width: 450px !important;
    margin-top: 40px !important;
    .contact-button-mobile {
        display: none;
    }
    .contact-button-desktop {
        display: inline-flex;
    }
}

.contact-button:hover {
    background: $orangeColor;
    border-radius: 8px;
    box-shadow: 0px 0px 300px 30px #142ba4, 0 0 2px 2px $highlightColor !important;
    color: $whiteColor;
    font-size: 24px;
    font-weight: 600;
}

.contact-image {
    width: 100%;
    z-index: 1;
    position: relative;
    height: 105%;
    margin-top: -2.5%;
}

.contact-image-modified {
    z-index: 1;
    position: relative;
    height: 105%;
    margin-top: -2.5%;
}

.bg-gradiant {
    background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
}

.bg-gradiant-modified {
    background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    right: -20%;
}

.contact-inner-content {
    margin-top: 106px !important;
}

.contact-phone-container-mobile {
    display: none;
}
.contact-image-modified-mobile {
    box-sizing: border-box;
}

@media (min-width:1501px) and (max-width: 1600px) {
    .contact {
        margin-top: 214px - (0.375*214);
    }

    .margin-bottom {
        margin-bottom: 100px- (0.375*100);
    }

    .contact-card {
        height: 674px - (0.375*674);
    }

    .contact-heading {
        font-size: $mainHeaderLgs;
        margin-top: 66px - (0.375*66);
    }

    .contact-content {
        font-size: $subContentLgs;
        margin-top: 64px - (0.375*64);
    }

    .contact-button {
        border-radius: (8px - (0.375*8)) !important;
        font-size: (24px - (0.375*24)) !important;
        height: (66px - (0.375*66)) !important;
        width: auto !important;
        margin-top: 35px !important;
    }

    .contact-button:hover {
        border-radius: 8px;
        box-shadow: 0px 0px (300px - (0.375*300)) 30px #142ba4, 0 0 2px 2px $highlightColor !important;
        font-size: 24px;
    }

    .root-cs {
        .contact-inner-content {
            margin-top: 70px !important;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {
    .contact {
        margin-top: 214px - (0.375*214);
    }

    .margin-bottom {
        margin-bottom: 100px- (0.375*100);
    }

    .contact-card {
        height: 674px - (0.375*674);
    }

    .contact-heading {
        font-size: $subHeaderLgs;
        margin-top: 66px - (0.375*66);
    }

    .contact-content {
        font-size: $subContentLgss;
        margin-top: 64px - (0.375*64);
    }

    .contact-button {
        border-radius: (8px - (0.375*8)) !important;
        font-size: (24px - (0.375*24)) !important;
        height: (66px - (0.375*66)) !important;
        width: auto !important;
        margin-top: 35px !important;
    }

    .contact-button:hover {
        border-radius: 8px;
        box-shadow: 0px 0px (300px - (0.375*300)) 30px #142ba4, 0 0 2px 2px $highlightColor !important;
        font-size: 24px;
    }

    .root-cs {
        .contact-inner-content {
            margin-top: 50px !important;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .contact {
        margin-top: 214px - (0.53125*214);
    }

    .margin-bottom {
        margin-bottom: 100px- (0.53125*100);
    }

    .contact-card {
        height: 674px - (0.53125*674);
    }

    .contact-heading {
        font-size: $mainHeaderMd;
        margin-top: 66px - (0.53125*66);
    }

    .contact-content {
        font-size: $subContentMd;
        margin-top: 64px - (0.53125*64);
    }

    .contact-button {
        border-radius: (8px - (0.53125*8)) !important;
        font-size: (24px - (0.53125*24)) !important;
        height: (66px - (0.53125*66)) !important;
        width: (249px - (0.53125*249)) !important;
        margin-top: (106px - (0.53125*106)) !important;
        //box-shadow: 0px 0px (150px - (0.53125*150)) 20px #142ba4 !important;
    }

    .contact-button:hover {
        border-radius: 8px;
        box-shadow: 0px 0px (300px - (0.53125*300)) 30px #142ba4, 0 0 2px 2px $highlightColor !important;
        font-size: 24px;
    }
}

@media (min-width:641px) and (max-width: 899px) {
    .contact {
        margin-top: 214px - (0.66*214);
    }

    .margin-bottom {
        margin-bottom: 100px- (0.66*100);
    }

    .contact-card {
        height: 674px - (0.66*674);
    }

    .contact-heading {
        font-size: $mainHeaderSm;
        margin-top: 66px - (0.66*66);
    }

    .contact-content {
        font-size: $subContentSm;
        margin-top: 64px - (0.66*64);
    }

    .contact-button {
        border-radius: (8px - (0.66*8)) !important;
        font-size: (24px - (0.66*24)) !important;
        height: (66px - (0.66*66)) !important;
        margin-top: (106px - (0.66*106)) !important;
    }

    .contact-button:hover {
        border-radius: 8px;
        box-shadow: 0px 0px (300px - (0.66*300)) 30px #142ba4, 0 0 2px 2px $highlightColor !important;
        font-size: 24px;
    }
}

@media (max-width: 900px) {

    .contact {
        width: 90%;
        margin-top: 40px;
        .contact-phone-container {
            display: none;
        }
        .contact-phone-container-mobile {
            position: relative;
            display: block;
            margin: 16px auto 0 auto;
            text-align: center; 
            .consultation-choice {
                display: flex;
                justify-content: center;
                .consultation-choice-btn {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 50%;
                    overflow: hidden;
                    cursor: pointer;
                    box-sizing: border-box;
                    margin-top: 24px;
                    img {
                        width: 100%;
                    }
                    &:first-child {
                        margin-right: 25px;
                    }
                    &:last-child {
                        margin-left: 25px;
                    }
                }
            }
        }
        .contact-button-container {
            display: flex;
            justify-content: center;
        }
    }

    .contact-card {
        height: auto;
        .contact-inner-container {
            justify-content: center;
        }
    }

    .contact-inner-content {
        margin-top: 0 !important;
    }

    .margin-bottom {
        margin-bottom: 100px- (0.66*100);
    }
    .contact-heading {
        font-size: 24px;
        margin-top: 66px - (0.66*66);
    }

    .contact-content {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .contact-button {
        margin-top: 25px !important;
        font-size: 16px !important;
        padding: 16px 20px;
        min-width: 130px;
        height: 44px !important;
        width: auto;
        .contact-button-mobile {
            display: inline-flex;
        }
        .contact-button-desktop {
            display: none;
        }
    }

    .contact-button:hover {
        border-radius: 8px;
        box-shadow: 0px 0px (300px - (0.66*300)) 30px #142ba4, 0 0 2px 2px $highlightColor !important;
        font-size: 24px;
    }
}
@media (max-width: 640px) {
  .mobile-title
  {
    display: block;
  }
  .desktop-title
  {
    display: none;
  }
}