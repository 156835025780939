@import 'common';

.service-page-list-items {
    width: 85%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 100px;

    .service-list-item-title {
        font-weight: 600;
        font-size: $subHeaderLg;
        line-height: 125%;
        /* identical to box height, or 72px */

        color: #FF7900;
    }

    .service-list-item-desc {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        /* or 60px */
        // margin-left: 10%;
        padding-top: 20px;
        padding-bottom: 40px;
        color: #FFFFFF;

        p {
            margin: 0px !important;
        }

    }

    .service-page-list-main-text {
        margin-bottom: 80px;
    }

    .service-page-list-main-title {
        font-weight: 700;
        font-size: $mainHeaderLg;
        line-height: 130%;
        color: #FFFFFF;
    }

    .service-page-list-sub-title {
        font-weight: 400;
        font-size: $subContentLg;
        line-height: 150%;
        /* identical to box height, or 48px */


        color: #FFFFFF;

        margin-top: 40px;
    }

    .service-list-item-icon {
        // width: 65%;
    }
}

@media (max-width:640px) {
    .service-page-list-items {
        width: 90%;
        margin: 0 auto;
        padding: 50px 0;

        .service-page-list-main-text {
            margin-bottom: 30px;

            .service-page-list-sub-title {
                margin-top: 15px;
                font-size: $subContentMobile;
            }
        }

        .service-page-list-main-title {
            font-weight: 500;
            line-height: 1.5;
            font-size: $mainHeaderMobileMd;
        }

        .service-list-item-icon {
            width: 50px;
            height: 50px;
            margin-top: 5px;
            margin-right: 20px;
        }

        .service-list-item-content {
            .service-list-item-title {
                font-size: 24px;
                font-weight: 600;
                line-height: 1.6;
                margin-bottom: 0;
                color: #FF7900 !important;
            }

            .service-list-item-desc {
                font-size: $subContentMobile;
                line-height: 1.6;
                padding-top: 10px;

                p {
                    margin: 0;
                }
            }
        }
    }

    .service-card {
        flex-wrap: nowrap !important;
    }

}

@media (min-width:641px) and (max-width:899px) {

    .service-page-list-items {
        padding-top: 0px;

        .service-list-item-title {
            font-size: $subHeaderSm;
        }

        .service-list-item-desc {
            font-size: $subContentSm;

        }

        .service-page-list-main-text {
            margin-bottom: 60px;
        }

        .service-page-list-main-title {
            font-size: $mainHeaderSm;
            margin-bottom: 50px;

        }

        .service-page-list-sub-title {
            font-size: $subContentSm;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {

    .service-page-list-items {
        padding-top: 50px;

        .service-list-item-title {
            font-size: $subHeaderMd;
        }

        .service-list-item-desc {
            font-size: $subContentMd;

        }

        .service-page-list-main-title {
            font-size: $mainHeaderMd;
            margin-bottom: 50px;

        }

        .service-list-item-content {
            padding-left: 4%
        }

        .service-page-list-sub-title {
            font-size: $subContentMd;
        }
    }
}

@media (min-width:1200px) and (max-width: 1500px) {

    .service-page-list-items {

        .service-list-item-icon {
            width: 65%;
        }

        .service-list-item-title {
            font-size: $sub2HeaderLgs;
        }

        .service-list-item-desc {
            font-size: $subContentLgss;

        }

        .service-page-list-main-title {
            font-size: $subHeaderLgs;
        }

        .service-page-list-sub-title {
            font-size: $subContentLgs;
        }
    }

    .service-page-list-main-text {
        margin-bottom: 60px !important;
    }
}

@media (min-width:1501px) and (max-width: 1600px) {

    .service-page-list-items {

        .service-list-item-icon {
            width: 65%;
        }

        .service-list-item-title {
            font-size: $subHeaderLgs;
        }

        .service-list-item-desc {
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: $subContentLgs;

        }

        .service-page-list-main-title {
            font-size: $mainHeaderLgs;
        }

        .service-page-list-sub-title {
            font-size: $subContentLgs;
        }
    }

    .service-page-list-main-text {
        margin-bottom: 60px !important;
    }
}

.root-cs {
    .service-page-list-items {
        .service-list-item-title {
            color: $whiteColor;
        }

        .service-page-list-main-title {
            // color: $orangeColor;
            color: $whiteColor;
        }
    }
}