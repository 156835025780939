@import './common.scss';

$orangeColor : #FF7900;
$whiteColor: #FFFFFF;
$highlightColor: #be9663;
$blackColor: #000000;

.about-connect-updated {
    // padding: 20% 0;
    padding: 10% 0% 13%;

}
.root-blog {
    .about-connect-updated {
        padding: 10% 0% 2%;
    }
}
.root-about {
    .about-connect-updated {
        padding: 10% 0% 2%;
    }
}
.root-work {
    .about-connect-updated {
        padding: 10% 0% 2%;
    }
}

.root-services {
    .about-connect-updated {
        padding: 0% 0% 13%;
    }
    .service-final-cta {
        .about-connect-updated {
            padding: 3% 0% 3%;
        }
    }
}

.root-contact {
    .about-connect-updated {
        padding: 0%;
        padding-top: 195px;

    }
}

.about-connect-updated-inner-container {
    position: relative;
}

.about-connect-updated-inner-container::before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #19193C, #19193C), linear-gradient(97.13deg, rgba(8, 25, 164, 0.12) -12.87%, rgba(12, 13, 15, 0.25) 117.57%);
    filter: blur(150px);
    position: absolute;
}

.career-contact {
    .about-connect-updated {
        min-height: auto
    }

    .about-connect-updated-center {

        width: 37%;
        margin-left: 7%;
    }
}

.about-connect-updated-center {

    width: 85%;
    margin: 0 auto;
}

.about-connect-updated-title {
    font-weight: 700;
    font-size: $mainHeaderLg;
    line-height: 90px;
    /* or 129% */


    color: #FFFFFF;
}

.about-connect-updated-main {
    font-size: $subContentLg;
    line-height: 150%;
    width: 65%;
    color: #FFFFFF;
    p {
        margin: 0;
    }

}

.about-connect-updated {
    position: relative;
    .button {
        margin-top: 60px !important;
        background: $orangeColor !important;
        color: $whiteColor !important;
        border-radius: 8px !important;
        //font-weight: 600 !important;
        height: 58px;
        min-width: 236px;
        max-width: 285px;
        z-index: 1;
        // position: absolute;
        font-size: 24px;
    }

    .button:hover {
        background: $orangeColor !important;
    }
}

.button-radiant {
    background: radial-gradient(50% 50% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    height: 400px;
    width: 400px;
    position: absolute;
    top: -110px;
    left: -60px;
    opacity: 0.5;
}

.about-connect-updated-title-bg {
    // background-image: url('../assets//About/connect-title-bg.png');
    position: relative;
    // padding-top: 70px;
    padding-bottom: 24px;

}

.about-connect-updated-main-bg {
    position: relative;
    padding-bottom: 80px;
    .connect-button-wrapper {
        position: relative;
    }
}

.about-connect-updated-bg-main-container {
    width: 100%;
    height: 110%;
    position: absolute;
    overflow-x: hidden;
    top: -15%;
    margin-top: 100px;
}

.about-connect-updated-bg-radiant {
    background: radial-gradient(50% 70% at 50% 50%, rgba(28, 40, 152, 0.81) 0%, rgba(28, 40, 152, 0.65) 27.08%, rgba(28, 38, 128, 0) 100%);
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    left: 70%;
}

.contact-img-container {
    position: absolute;
    right: 2%;
    top: -3%;
}

.cta-title-align {
    width: 60%;
}

@media (max-width:640px) {
    .root-contact {
        .about-connect-updated {
            padding-top: 45px;
        }
    }

    .root-work {
        .about-connect-updated {
            padding-top: 15px;
            padding-bottom: 45px;
        }
    }

    .root-about {
        .about-connect-updated {
            padding-top: 15px;
            padding-bottom: 60px;
        }
    }

    .about-connect-updated-center {
        width: 90%;
    }
    
    .about-connect-updated-title {
        line-height: 1.6;
        font-weight: 600;
        width: 90%;
        font-size: $mainHeaderMobileMd;
    }

    .about-connect-updated-title-bg {
        padding-bottom: 30px;
    }

    .about-connect-updated-main {
        font-size: $subContentMobile;
        line-height: 1.8;
        width: 100%;
        margin-bottom: 30px;

        br {
            display: none;
        }
    }

    .about-connect-updated-bg-main-container {
        margin-top: 0;
    }

    .contact-img-container {
        position: absolute;
        right: 0%;
        top: 0%;
        width: 40%;
    }

    .about-connect-updated {
        .button {
            font-size: 16px !important;
            height: 44px !important;
            min-width: 170px !important;
            max-width: 230px !important;
            margin: 0 auto !important;
            font-weight: 600 !important;
            white-space: nowrap;
        }

        .contact-image {
            width: 100%;
            z-index: 1;
            position: relative;
            right: 4%;
        }
    }

    .button-radiant {
        height: 200px;
        width: 200px;
        top: -40px;
        left: -20px;
    }

    .about-connect-updated-main-bg {
        padding-bottom: 0 !important;
    }
}

@media (min-width:641px) and (max-width:899px) {

    .about-connect-updated {
        padding: 13% 0% 0% 0%
    }

    .about-connect-updated-title {
        font-size: $mainHeaderSm;
        line-height: 120%;
    }

    .about-connect-updated-title-bg {
        padding-bottom: 20px;
    }

    .about-connect-updated-main {
        font-size: $subContentSm;
        line-height: 150%;
    }

    .contact-img-container {
        position: absolute;
        right: 0%;
        top: 0%;
        width: 40%;
    }

    .about-connect-updated {
        .button {
            font-size: small !important;
            height: 36px;
            width: 120px;
            margin-top: 35px !important;
        }

        .contact-image {
            width: 100%;
            z-index: 1;
            position: relative;
            right: 4%;
        }
    }

    .button-radiant {
        height: 250px;
        width: 250px;
        top: -60px;
        left: -20px;
    }
}


@media (max-width:900px) {
    .about-connect-updated-main-bg {
        .connect-button-wrapper {
            margin-bottom: 65px;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .about-connect-updated-title {
        font-size: $mainHeaderMd;
        line-height: 120%;
    }

    .about-connect-updated-main {
        font-size: $subContentMd;
        line-height: 150%;
    }

    .about-connect-updated {
        .button {
            font-size: medium !important;
            height: 42px;
            width: 150px;
            margin-top: 45px !important;
        }

        .contact-img-container {
            position: absolute;
            right: 0%;
            top: -3%;
            width: 45%;
        }

        .about-connect-updated-bg-radiant {
            width: 85%;
        }

        .cotact-image {
            width: 85%;
            right: -8%;
        }
    }

    .button-radiant {
        height: 300px;
        width: 300px;
        top: -50px;
        left: -40px;
    }

}

@media (min-width:1200px) and (max-width: 1500px) {
    .about-connect-updated-title {
        font-size: $mainHeaderLgs;
        line-height: 120%;
    }

    .about-connect-updated-main {
        font-size: $subContentLgs;
        line-height: 150%;
    }

    .contact-img-container {
        width: 35%;
    }

    .about-connect-updated {
        .button {
            font-size: medium !important;
            height: 50px;
            width: 200px;
        }

        .cotact-image {
            width: 80%;
            right: -15%;
        }
    }
}

@media (min-width:1501px) and (max-width: 1600px) {
    .about-connect-updated-title {
        font-size: $mainHeaderLgs;
        line-height: 120%;
    }

    .about-connect-updated-main {
        font-size: $subContentLgs;
        line-height: 150%;
    }

    .contact-img-container {
        width: 35%;
    }

    .about-connect-updated {
        .button {
            font-size: medium !important;
            height: 50px;
            width: 200px;
        }

        .cotact-image {
            width: 80%;
            right: -15%;
        }
    }
}